import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
// Material UI
import {
    Box, CircularProgress, FormControl,
    Grid, List, MenuItem, Select, Typography, makeStyles
} from '@material-ui/core';
// Endpoints
import {
    getNodesTreeContractValidate,
} from '../../services/hierarchy';
import {
    getRateConfig, getProviders,
    getContractSubRateByIdLoc,

} from '../../services/billing';
//Servicios
import {
    getRates,

} from '../../services/customRates';
// Componentes
import TopNav from '../common/TopNav';
import FormSubRatesBilling from './FormSubRatesBilling';
import AlertComponent from '../Elements/AlertComponent'
import LoadingComponentBlur from '../common/LoadingComponentBlur';
//Date
import Container from '../Elements/Container';
import RatesNode from './RatesNode';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";

let scrollsSettings = {
    overflow: "scroll",
    height: "90vh",
    overflowX: "hidden",
}
let webkitScrollbar = {
    background: "white",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#beb1b18f",
    color: "red",
    width: "10px"
}
let webkitscrollbarTrack = {
    background: "#dfdfe957",
}
const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        display: "flex",
    },
    leftContainer: {
        padding: theme.spacing(2),
        //son los estilos de las barras scroll en una variable.
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        "@media (max-width: 700px)": {
            padding: theme.spacing(0, 0, 0, 1),
        }
    },
    rightContainer: {
        padding: theme.spacing(2),
        //son los estilos de las barras scroll en una variable.
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        "@media (max-width: 700px)": {
            padding: theme.spacing(0, 1, 0, 1),
        }
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: theme.spacing(2),
    },
    title: {
        "@media (max-width: 700px)": {
            textAlign: "center"
        }
    },
    tableContainer: {
        justifyContent: "center"
    },
    tableItem: {
        padding: theme.spacing(1),
        textAlign: 'center',

    },
    wordCut: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    buttonBack: {
        position: "relative",
        bottom: "16px",
        fontSize: "20px",
        backgroundColor: "#f3f3f3",
        width: "30px",
        borderRadius: "1em",
        textAlign: "center",
        fontWeight: "bold",
        marginTop: "25px"
    },
}));

function RatesSettings() {

    const classes = useStyles();
    const { isMobile } = useDeviceDetect(961);
    const [selectedNode, setSelectedNode] = useState({});
    const initialNewContract = {
        id: "",
        newContractName: "",
        from_date: new Date(),
        to_date: new Date(),
        location: selectedNode.id,
        provider: "",
        providerId: "",
        type: "",
        typeName: "",
        hired_demand: ""
    }
    const [nodes, setNodes] = useState([]);
    const [newContract, setNewContract] = useState(initialNewContract);
    const [providerItems, setProviderItems] = useState([]);
    const [showRightBlock, setShowRightBlock] = useState(true)
    const [showLeftBlock, setShowLeftBlock] = useState(true)
    //estados loading. 
    const [loading, setLoading] = useState(true)
    const [loadingRightContainer, setLoadingRightContainer] = useState(false)
    //estado q maneja la opcion del selector de Distribuidora
    const [option, setOption] = useState("Default")
    //estado inicial de el contrato
    const [stateContract, setStateContract] = useState(null)
    //estado inicial de el Tarifa del contrato
    const [stateBestRate, setStateBestRate] = useState({
        id: "",
        provider: false
    })
    //estado Inicial de el contrato
    const [stateInitialContract, setStateInitialContract] = useState(null)
    //estado para avisar si estoy en un contrato privado o default.  defaul:false
    const [subHasPrivateContract, setSubHasPrivateContract] = useState(false)
    const [bestRate, setBestRate] = useState({
        id: "",
        provider: false
    })
    const [contractLocation, setContractLocation] = useState({})
    //Son las opciones para generar una tarifa nueva y sus respectivos id
    const [optionRates, setOptionRates] = useState({})
    //Error message
    const errorMessage = {
        dontHaveDefaultContract: "No posee un contrato configurado, debe seleccionar una Localización para configurar su contrato."
    }
    //Estados alert
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: ""
    })
    //Estado de el loading blur.
    const [loadingBlur, setloadingBlur] = useState(false)
    useEffect(() => {
        fetchNodesTreeData();
        fetchEnergyProvider();
        //Trae todos los tipos de tarifa q existen para buscar TarifaCustom.
        getRatesFunction()
    }, []);

    useEffect(() => {
        if (isMobile) {
            // console.log("isMobile")
            setShowRightBlock(false)
        }
        else {
            // console.log("desktop");
            setShowRightBlock(true)
            setShowLeftBlock(true)
        }
    }, [isMobile])

    const nodeList = () => {
        return nodes.map(node => <RatesNode node={node} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} key={node.id} />);
    };

    //Trae todos los tipos de tarifa q existen para buscar TarifaCustom.
    const getRatesFunction = async () => {
        try {
            let res = await getRates()
            let DefaultSubTarifacion = res.data.find(e => e.name === 'DefaultSubTarifacion')
            let SubRate = res.data.find(e => e.name === 'SubRate')
            setOptionRates({ DefaultSubTarifacion, SubRate })
            // setOptionRates(...DefaultSubTarifacion)
        }
        catch (error) {
            console.error(error);
        }
    }

    //Busco si tiene un DefaultSubTarifacion la localizacion.
    const findContractSubRate = async (node) => {
        try {
            //Busco si tiene contrato
            let contract = await getContractSubRateByIdLoc(node.id)
            if (contract.data.contract) {
                //si contrato no es default, en resumen si la sublocalizacion tiene contrato personalizado default:false.
                if (contract.data.contract.default === false) {
                    setSubHasPrivateContract(true)
                    setOption("Personalizada")
                    //Busco el contrato default por q el q tengo es el privado de la localizacion
                    let contractLocationAux = await getContractSubRateByIdLoc(node.parent)
                    let BestRateLocation = await getRateConfig(contractLocationAux.data.contract.rate)

                    setContractLocation({
                        BestRateLocation: BestRateLocation.data,
                        contractLocationAux: contractLocationAux.data.contract
                    })
                }
                let rateFromContract = await getRateConfig(contract.data.contract.rate)
                setBestRate(rateFromContract.data)
                setStateBestRate(rateFromContract.data)
            }
            else {
                setBestRate({
                    id: "",
                    provider: false
                })
            }
            setStateInitialContract({ ...contract.data.contract })
            setStateContract(contract.data.contract)
            setLoadingRightContainer(false)
        }
        catch (error) {
            setSelectedNode({})
            setClose(true)
            setMsg({
                msg: errorMessage.dontHaveDefaultContract,
                type: "error"
            })
            setLoadingRightContainer(false)
        }
    }

    //Funcion q trae el arbol de nodos
    const fetchNodesTreeData = async () => {
        try {
            const res = await getNodesTreeContractValidate();
            setNodes(res.data);
            setLoading(false)
        }
        catch (error) {
            console.error(error);
        }
    };

    //Funcion q trae todos los provedores
    const fetchEnergyProvider = async () => {
        try {
            // const res = await getProviders();
            await getProviders();
            setProviderItems([{ name: "Default" }, { name: "Personalizada" },]);
            // setProviderItems([...res.data, { name: "Default" }, { name: "Personalizada" },]);
        }
        catch (error) {
            console.error(error);
        }
    }

    //funcion seleccionar nodo
    const handleSelectedNode = async (node) => {
        setLoadingRightContainer(true)
        setSelectedNode(node)
        findContractSubRate(node)
        setSubHasPrivateContract(false)
        setOption("Default")
        if (isMobile) {
            setShowLeftBlock(false)
            setShowRightBlock(true)
        }
    };

    //boton back "<" mobile para volver al bloque izquierdo de la app.
    const BackPageMobile = () => {
        setShowLeftBlock(true)
        setShowRightBlock(false)
    }

    //retorna html linea azul q divide bloques de franjas.
    const blueBar = () => {
        return (
            <div style={{
                backgroundColor: "#2586bc",
                height: "1px ",
                width: " 100 %",
                marginTop: "10px"
            }}></div>
        )
    }

    const handleCancel = () => {
        setSelectedNode({});
        setNewContract(initialNewContract)
        if (isMobile) {
            setShowRightBlock(false)
            setShowLeftBlock(true)
        }
    };

    const handleChange = async (e) => {
        if (e.target.value === "Default") {
            if (subHasPrivateContract) {
                setStateContract(contractLocation.contractLocationAux)
                setBestRate(contractLocation.BestRateLocation)

            }
            else {
                setStateContract(stateInitialContract)
            }
        }
        if (e.target.value === "Personalizada") {
            if (subHasPrivateContract) {
                setStateContract(stateInitialContract)
                setBestRate(stateBestRate)
            }
            else {
                setStateContract(null)
            }
        }
        setOption(e.target.value)
    }

    return (
        <>
            < TopNav
                titulo='Subfacturación'
                subtitulo='| (Sublocalizaciónes)'
            >
                <Box display='flex' justifyContent='flex-end'>

                </Box>
            </TopNav >

            {/* Container loading */}
            <LoadingComponentBlur
                loadingState={loadingBlur}
            />

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container>

                {/* LOADING   */}
                {
                    loading ?

                        <Box display='flex' justifyContent='center' mt={2}>
                            <CircularProgress />
                        </Box>
                        :
                        <Grid className={classes.root} container>
                            {
                                showLeftBlock &&

                                <Grid item xs={12} md={6} className={classes.leftContainer}>
                                    <List >
                                        {/* //si el usuario no tiene nodos */}
                                        {
                                            nodes.length === 0 &&
                                            <h1>
                                                Usted no posee ninguna localizacion configurada en su cuenta.<br></br>
                                                Por favor, configure al menos una localización para poder continuar.
                                                <NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
                                                    [link]
                                                </NavLink>
                                            </h1>
                                        }
                                        <div  >{nodeList()}</div>
                                    </List>
                                </Grid>
                            }
                            {
                                showRightBlock &&

                                <Grid item xs={12} md={6} className={classes.rightContainer}>
                                    {
                                        isMobile &&
                                        <div className={classes.buttonBack} onClick={BackPageMobile}>{'<'}</div>
                                    }

                                    {/* Si tengo un valor de provider lo muestro */}
                                    {
                                        selectedNode.id && !loadingRightContainer ?
                                            (
                                                <>
                                                    <Box mb={2}>
                                                        <Typography variant='h6' className={classes.title}>
                                                            Contrato y tarifa de subfacturacion
                                                        </Typography>
                                                    </Box>

                                                    {blueBar()}

                                                    {
                                                        selectedNode?.level_nombre === "localización" &&
                                                        <Box mt={3} mb={3} style={{ textAlign: "center", fontSize: "18px" }}>
                                                            <label >
                                                                Configure las opciones por defecto que se aplicarán sobre las sublocalizaciónes para el cálculo de subfacturas
                                                            </label>
                                                        </Box>
                                                    }

                                                    {
                                                        selectedNode?.level_nombre === "sublocalización" &&
                                                        <Box mt={3} mb={3} style={{ textAlign: "center", fontSize: "18px" }}>
                                                            <label >
                                                                Esta configurando un contrato unicamente para esta Sublocalización
                                                            </label>
                                                        </Box>
                                                    }

                                                    {blueBar()}

                                                    <Grid container className={classes.tableContainer}>

                                                        <Grid item xs={3} className={classes.label}>
                                                            Empresa Distribuidora
                                                        </Grid>

                                                        {/* SI ES UNA LOCALIZACION */}
                                                        {
                                                            selectedNode?.level_nombre === "localización" &&

                                                            <Grid item xs={9} className={classes.tableItem}>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        labelId="empresa-distribuidora"
                                                                        id="empresa-distribuidora"
                                                                        disabled={true}
                                                                        defaultValue={1}
                                                                    >
                                                                        <MenuItem value={1}>{selectedNode?.nombre}</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        }

                                                        {/* SI ES UNA SUB LOCALIZACION */}
                                                        {
                                                            selectedNode?.level_nombre === "sublocalización" &&

                                                            <Grid item xs={9} className={classes.tableItem}>
                                                                <FormControl fullWidth>
                                                                    <Select labelId='provider'
                                                                        // disabled={blockProvider}
                                                                        name='providerNewContract'
                                                                        defaultValue={"Default"}
                                                                        value={option}
                                                                        onChange={e => handleChange(e)}
                                                                    >
                                                                        {providerItems.map((providerItem, index) => (

                                                                            <MenuItem key={index} value={providerItem.name}>
                                                                                {providerItem.name}
                                                                            </MenuItem>

                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                    </Grid>

                                                    <Grid container className={classes.tableContainer}>

                                                        <Grid item xs={3} className={classes.label}>
                                                            Tarifa
                                                        </Grid>

                                                        {/* SI ES UNA LOCALIZACION */}
                                                        {
                                                            selectedNode?.level_nombre === "localización" &&

                                                            <Grid item xs={9} className={classes.tableItem}>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        labelId="tarifa-personalizada"
                                                                        id="tarifa-personalizada"
                                                                        disabled={true}
                                                                        defaultValue={1}
                                                                    >
                                                                        <MenuItem value={1}>Personalizada</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        }

                                                        {/* SI ES UNA SUB LOCALIZACION */}
                                                        {
                                                            selectedNode?.level_nombre === "sublocalización" &&

                                                            <Grid item xs={9} className={classes.tableItem}>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        labelId="tarifa-personalizada"
                                                                        id="tarifa-personalizada"
                                                                        disabled={true}
                                                                        defaultValue={1}
                                                                    >
                                                                        <MenuItem value={1}> {option} </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        }

                                                    </Grid>

                                                    {/* FORMULARIO DE CREACION DE TARIFAS */}
                                                    <FormSubRatesBilling
                                                        setloadingBlur={setloadingBlur}
                                                        handleSelectedNode={handleSelectedNode}
                                                        setBestRate={setBestRate}
                                                        subHasPrivateContract={subHasPrivateContract}
                                                        optionRates={optionRates}
                                                        option={option}
                                                        stateContract={stateContract}
                                                        handleCancel={handleCancel}
                                                        bestRate={bestRate}
                                                        selectedNode={selectedNode}
                                                        setClose={setClose}
                                                        setMsg={setMsg}
                                                        newContract={newContract}
                                                        stateInitialContract={stateInitialContract}
                                                        stateBestRate={stateBestRate}
                                                    />
                                                </>
                                            )
                                            :
                                            <>
                                                {
                                                    !selectedNode.id &&

                                                    <h2 style={{ textAlign: "center" }}  >
                                                        Seleccione una localización para comenzar
                                                    </h2>
                                                }
                                            </>
                                    }
                                    {
                                        selectedNode.id && loadingRightContainer &&

                                        <Box display='flex' justifyContent='center' mt={5}>
                                            <CircularProgress />
                                        </Box>
                                    }
                                </Grid>
                            }
                        </Grid>
                }
            </Container>
        </>
    );
}
export default RatesSettings;