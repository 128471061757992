import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import TopNav from "../../common/TopNav";
import Filter from '../SummaryLocation/Filter'
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import AlertComponent from "../../Elements/AlertComponent";
import { Alert } from "@mui/material";
import { useState } from "react";
import ContainerDiferenceBar from "../ComponentsDashboards/ContainerDiferenceBar";
import clsx from 'clsx';
import Container from '../../Elements/Container';
import ContainerCards from "../ComponentsDashboards/ContainerCards";
import ContainerEnergyFlowChart from "../ComponentsDashboards/ContainerEnergyFlowChart";

const useStyles = makeStyles(theme => ({
    contanerMain: {
        padding: theme.spacing(0.5),
     
    },
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    containerRow: {
        height: "33%",
        display: "flex",
        '@media (max-width: 1200px)': {
            flexWrap: 'wrap'
        }
    },
    boxes: {
        border: "1px solid #cbc1c157",
        boxShadow: '3px 3px 3px #cbc1c157',
        margin: theme.spacing(0.4),
    },
    smallBoxes: {
        width: "25%",
        '@media (max-width: 1200px)': {
            width: "49%",
        },
        '@media (max-width: 700px)': {
            width: "100%",
        },
        overflow: 'hidden'
    },
    mediumBoxes: {
        width: "25%",
        height: '450px',
        '@media (max-width: 1200px)': {
            width: "49%",
        },
        '@media (max-width: 700px)': {
            width: "100%",
        },
        overflow: 'hidden'
    },
    longBoxes: {
        width: "50%",
        '@media (max-width: 1200px)': {
            width: "100%",
            height: '300px'
        }
    },
}));
export default function Solar() {
    const classes = useStyles();
    const [msg] = useState({
        msg: "",
        type: "error"
    })
    const [location, setLocation] = useState({});
    const [filterPanel, setFilterPanel] = useState(true);
    const [close, setClose] = useState(false);
    const [showMain, setShowMain] = useState(false);
    const sizeEnergyFlow = {
        "w": 4,
        "h": 4,
    }

    const togglePanel = () => {
        setFilterPanel(!filterPanel);
    };

    const handleSetLocation = (location) => {
        setLocation(location);
        setShowMain(true)
    };

    return (
        <>
            <TopNav titulo='Dashboards' subtitulo={`| Solar localización `}>
                <Grid container justifyContent='flex-end' alignItems='center'>
                    <Button
                        className={classes.btn}
                        type="button"
                        onClick={togglePanel}
                        size="small"
                        startIcon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                    >
                        {location?.nombre || `Seleccionar Localización`}
                    </Button>
                </Grid>
            </TopNav>

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container >
                {!showMain &&
                    <Box >
                        <Alert className={classes.alert} severity='info'>
                            Bienvenido al Dashboard Solar. En esta sección podrá consultar la información de sus Localizaciones para seleccionar una, haga click en
                            el botón <span className={classes.alertIcon}><LabelImportantIcon style={{ fontSize: '18px' }} /></span>
                            , elija la localización y haga click en Aceptar.
                        </Alert>
                    </Box>
                }

                {/* Filtro */}
                <Filter
                    isOpen={filterPanel}
                    setIsOpen={setFilterPanel}
                    setLocation={(location) => handleSetLocation(location)}
                    location={location}
                    dashboard={'Solar'}
                />

                {showMain &&
                    <Box className={classes.contanerMain}>

                        <>
                            {/* TOP */}
                            <Box className={classes.containerRow}>
                                <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                    <ContainerCards
                                        config_key="DASHBOARDS_INDICATOR-ENERGIA_ACTIVA_GENERADA_TOTAL-COMPARISON-HOY-DAILY"
                                        id={location.id}
                                        title="Generado hoy"
                                        size={false}
                                        marginTitle={1}
                                        variable={"Energía Generada"}
                                    />
                                </Box>
                                <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                    <ContainerCards
                                        config_key="DASHBOARDS_INDICATOR-ENERGIA_ACTIVA_GENERADA_TOTAL-COMPARISON-ESTE_MES-MONTHLY"
                                        id={location.id}
                                        title="Generación mensual"
                                        size={false}
                                        marginTitle={1}
                                        variable={"Energía Generada"}
                                    />
                                </Box>
                                <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                    <ContainerCards
                                        config_key="DASHBOARDS_INDICATOR-ENERGIA_ACTIVA_CONSUMIDA_TOTAL-VALIDATE_HISTORY-HOY-DAILY"
                                        id={location.id}
                                        title="Consumido hoy"
                                        size={false}
                                        marginTitle={1}
                                        variable={"Energía activa consumida"}
                                    />
                                </Box>
                                <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                    <ContainerCards
                                        config_key="DASHBOARDS_INDICATOR-ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL-VALIDATE_HISTORY-HOY-DAILY"
                                        id={location.id}
                                        title="Autoconsumido hoy"
                                        size={false}
                                        marginTitle={1}
                                        variable={"Energía activa autoconsumida"}
                                    />
                                </Box>
                            </Box>
                            {/* MID */}
                            <Box className={classes.containerRow}>

                                <Box className={clsx(classes.boxes, classes.mediumBoxes)} style={{ position: 'relative', height: '450px' }}>
                                    <ContainerEnergyFlowChart
                                        config_key={"DASHBOARDS_SOLAR_FLOW-POTENCIA_ACTIVA_SOLAR_TOTAL-INSTANT_LAST_DATA-ULTIMAS_2_SEMANAS-5_MINUTES"}
                                        id={location.id}
                                        title={'Flujo de energia'}
                                        height={'90%'}
                                        marginTitle={1}
                                        positions={sizeEnergyFlow}
                                    />
                                </Box>

                                <Box className={clsx(classes.boxes, classes.mediumBoxes)} style={{ position: 'relative', height: '450px' }}>
                                    <ContainerCards
                                        config_key="DASHBOARDS_INDICATOR-CONSUMO_POR_METRO_CUADRADO_SUB-COMPARISON-HOY-DAILY"
                                        id={location.id}
                                        size={false}
                                        marginTitle={1}
                                        title={location?.nombre}
                                        variable={"Consumo x m²"}
                                    />
                                </Box>

                                <Box className={clsx(classes.boxes, classes.mediumBoxes)} style={{ position: 'relative', height: '450px' }}>
                                    <ContainerCards
                                        config_key="DASHBOARDS_INDICATOR-TONELADA_EQUIVALENTE_DE_PETROLEO_SUB-COMPARISON-HOY-DAILY"
                                        id={location.id}
                                        size={false}
                                        title={location?.nombre}
                                        marginTitle={1}
                                        variable={"Tonelada petróleo"}
                                    />
                                </Box>

                                <Box className={clsx(classes.boxes, classes.mediumBoxes)} style={{ position: 'relative', height: '450px' }}>
                                    <ContainerCards
                                        config_key="DASHBOARDS_INDICATOR-EMISION_DE_CO2_SUB-COMPARISON-HOY-DAILY"
                                        id={location.id}
                                        title={location?.nombre}
                                        size={false}
                                        marginTitle={1}
                                        variable={"Emisión de CO²"}
                                    />
                                </Box>
                            </Box>

                            {/* BOTTOM */}
                            <Box className={classes.containerRow}>
                                <Box className={clsx(classes.boxes, classes.longBoxes)}>
                                    <ContainerDiferenceBar
                                        config_key={"DASHBOARDS_COMPARATOR-ENERGIA_ACTIVA_EXPORTADA_TOTAL-ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL-MONTHLY"}
                                        title={"Generación"}
                                        id={location?.id}
                                        color={"#4fd54b-#3eb1ff"}
                                    />
                                </Box>
                                <Box className={clsx(classes.boxes, classes.longBoxes)}>
                                    <ContainerDiferenceBar
                                        config_key={"DASHBOARDS_COMPARATOR-ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL-ENERGIA_ACTIVA_CONSUMIDA_TOTAL-MONTHLY"}
                                        title={"Consumo"}
                                        id={location?.id}
                                        color={"#3eb1ff-#ff9f37"}
                                    />
                                </Box>
                            </Box >
                        </>

                    </Box >}

            </Container>
        </>
    )
}