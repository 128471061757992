import { createContext, useState } from "react";
const DiagramContext = createContext()

export const StateDiagramProvider = ({ children }) => {
  const [stateDiagram, setStateDiagram] = useState(
    {
      alertObject: {
        alert: {
          name: '',
        },
        tree: {},
      },
      selectedBlock: [],
      disabledNodes: [],
      errorBlockNodes:[]
    }
  )

  return (
    <DiagramContext.Provider value={{ stateDiagram, setStateDiagram }}>
      {children}
    </DiagramContext.Provider>
  )
}
export default DiagramContext;