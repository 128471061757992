import instance from './testing.instance';

export const getDiagramAlert = () => instance.get(`/alerts/`);

export const getIndividualDiagramAlert = (id) => instance.get(`/alerts/${id}/`);

export const postSaveDiagramAlert = (body) => instance.post(`/alerts/`, body);

export const putUpdateDiagramAlert = (id, body) => instance.put(`/alerts/${id}/`, body);

export const deleteDiagramAlert = (id) => instance.delete(`/alerts/${id}`);

export const alertRecords = (id, page = 1) => instance.get(`/alerts/${id}/history/?page=${page}`)