import { Alert } from "@material-ui/lab";
import ShowDiferenceBar from "./ShowDiferenceBar";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getHistoryResponseV2 } from "../../../services/meters";
import { helperComponent } from "../../DynamicDashboard/helpers";

const useStyles = makeStyles((theme) => ({
    containerDisabled: {
        pointerEvents: 'none',
        opacity: 0.5,
        filter: 'grayscale(100%) blur(3px)',
    },
}));

export default function ContainerDiferenceBar({ title, subtitle, unity = 'kWh', color, config_key, id }) {
    const classes = useStyles();
    const [variable1, setVariable1] = useState(null);
    const [variable2, setVariable2] = useState(null);
    const [color1, setColor1] = useState(null);
    const [color2, setColor2] = useState(null);
    const [loading, setLoading] = useState(false);
    const [msg] = useState({
        msg: 'No se dispone de datos suficientes para hacer la comparación',
        type: 'error'
    })
    const ENERGIA_ACTIVA_IMPORTADA_TOTAL = "ENERGIA_ACTIVA_IMPORTADA_TOTAL"
    const ENERGIA_ACTIVA_EXPORTADA_TOTAL = "ENERGIA_ACTIVA_EXPORTADA_TOTAL"
    const ENERGIA_ACTIVA_GENERADA_TOTAL = "ENERGIA_ACTIVA_GENERADA_TOTAL"
    const ENERGIA_ACTIVA_CONSUMIDA_TOTAL = "ENERGIA_ACTIVA_CONSUMIDA_TOTAL"
    const ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL = "ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL"

    useEffect(() => {
        setVariable1(null);
        setVariable2(null);
        const fragments = config_key?.split('-');
        const fragmentsColors = color?.split('-');
        const firstKey = fragments[1];
        const secondKey = fragments[2];
        setColor1(fragmentsColors[0]);
        setColor2(fragmentsColors[1]);
        let var1 = helperComponent('DASHBOARDS_COMPARATOR')?.find(e => e.key === firstKey)
        let var2 = helperComponent('DASHBOARDS_COMPARATOR')?.find(e => e.key === secondKey)
        getData(var1, var2);
        // eslint-disable-next-line
    }, [config_key, color, id])

    const getData = async (var1, var2) => {
        try {
            setLoading(true);
            let res = await getHistoryResponseV2(id, 'este_mes', 'monthly');
            let energy = res.data[0].data;
            let dataVar1 = {
                value: setValues(var1, energy),
                label: var1.name
            }
            let dataVar2 = {
                value: setValues(var2, energy),
                label: var2.name
            }
            setVariable1(dataVar1);
            setVariable2(dataVar2);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const setValues = (variable, energy) => {
        switch (variable?.key) {
            case ENERGIA_ACTIVA_IMPORTADA_TOTAL:
                return energy?.kwh_i[0].valor;
            case ENERGIA_ACTIVA_EXPORTADA_TOTAL:
                return energy?.kwh_e[0].valor;
            case ENERGIA_ACTIVA_GENERADA_TOTAL:
                return energy?.kwh_g[0].valor;
            case ENERGIA_ACTIVA_CONSUMIDA_TOTAL:
                return energy?.kwh_c[0].valor;
            case ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL:
                return energy?.kwh_ac[0].valor;
            default:
                return '';
        }
    }

    return (
        <>
            {!loading ?
                <>  
                    {(variable1 && variable2 && (variable1?.value > 0 || variable2?.value > 0)) ? <ShowDiferenceBar
                        title={title}
                        subTitle={subtitle}
                        unity={unity}
                        value1={variable1?.value}
                        value2={variable2?.value}
                        labelValue1={variable1?.label}
                        labelValue2={variable2?.label}
                        backgroundColorValue1={color1}
                        backgroundColorValue2={color2}
                    />
                        :
                        <>
                            <Alert severity={msg.type}>{msg.msg}</Alert>
                            <span className={classes.containerDisabled} >
                                <ShowDiferenceBar
                                    title={title}
                                    subTitle={subtitle}
                                    value1={100}
                                    value2={100}
                                    labelValue1={'Energia 1'}
                                    labelValue2={'Energia 2'}
                                    height="80%"
                                />
                            </span>
                        </>
                    }
                </>
                :
                <Box display="flex" justifyContent="center" mt={2} height={150}>
                    <CircularProgress />
                </Box>
            }
        </>
    )
}