import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
//icons material
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
//Material ui
import {
    Box, IconButton, makeStyles, Tooltip
} from '@material-ui/core';
import { listComponent } from "./helpers";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles(theme => ({
    boxes: {
        border: "1px solid #cbc1c157",
        boxShadow: '3px 3px 3px #cbc1c157',
        height: "100%",
    },
    buttonClose: {
        display: 'flex'
    },
    title: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.2),
        margin: theme.spacing(1.5, 0, 1, 3),
        fontWeight: '600',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    animationCut: {
        backgroundColor: `${"#ebebeb"}`,
        borderStyle: "dashed",
        borderColor: "#7e7979",
        borderWidth: "2px",
    },
    emptyCOnfig: {
        fontSize: theme.spacing(2),
        color: "#3c2d43",
        height: '70%',
        alignItems: 'center',
        display: "flex",
        justifyContent: "center"
    }
}));

export default function ShowcaseLayout({
    stateData, setStateData, edit, setEdit, setClose,
    setMsg, togglePanel, setComponentState, refresh, editFunctionImg, setStateDataComponentsImageZoom
}) {
    const classes = useStyles();
    //Textos de los tooltip.
    const TOOLTIP_TEXT = {
        DELETE: "Elimina su componente.",
        EDIT: "Permite editar su componente.",
    }
    const [positionsAux, setPositionsAux] = useState([])

    useEffect(() => {
        setComponentState(stateData.positions)
        setPositionsAux(stateData.positions)
        // eslint-disable-next-line 
    }, [])

    //Toma el estado de las posiciones de las cajas y las guarda en el estado de la data.
    const onLayoutChange = (layout) => {
        setComponentState(layout)
        setPositionsAux(layout)
    }

    //Elimina una caja.
    const deleteBox = (idx) => {
        if (stateData.positions.length > 1) {
            let componentsAux = [...stateData.components]
            let positionsAuxFuncition = positionsAux
            componentsAux.splice(idx, 1)
            positionsAuxFuncition.splice(idx, 1)
            positionsAuxFuncition.map((elem, index) => {
                elem.i = index.toString()
                return elem
            })
            componentsAux.map((elem, index) => {
                elem.indexBox = index
                return elem
            })
            setStateData({
                ...stateData,
                components: componentsAux,
                positions: positionsAuxFuncition
            })
        }
        else {
            setClose(true)
            setMsg({
                msg: "No puede tener menos de un componente",
                type: "error"
            })
        }
    }

    //Crea los componentes q se van a dibujar en el dashboard.
    const CreateDom = () => {
        let res = stateData?.components?.map((element, index) => {
            let ind = index.toString()
            return (
                <div className={edit ? classes.animationCut : classes.boxes} key={ind}>
                    {/* si la caja SI tiene contenido  */}
                    <>
                        <Box display="flex" justifyContent="space-between">
                            <Box className={classes.title}>
                                {element?.title ? element?.title : " -"}
                            </Box>

                            {edit &&
                                <div className={classes.buttonClose}>
                                    <Tooltip
                                        title={TOOLTIP_TEXT.EDIT}
                                        arrow
                                        placement="right"
                                    >
                                        <IconButton
                                            aria-label="open_edit_box"
                                            onClick={() => togglePanel(index)}
                                        >
                                            <DriveFileRenameOutlineIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={TOOLTIP_TEXT.DELETE}
                                        arrow
                                        placement="right"
                                    >
                                        <IconButton
                                            color="primary"
                                            aria-label="delete"
                                            onClick={() => deleteBox(index)}
                                        >
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                        </Box>
    
                        <Box height='90%'>
                            {listComponent(
                                element.id, element.name, index, element.nameDevice,
                                element.variable, element?.config_key, element?.content,
                                element?.color, element?.min, element?.max
                                , positionsAux[element.indexBox], refresh, element.nodes,
                                element?.file_base64 ? element?.file_base64 : element?.file_url,
                                edit, setEdit, editFunctionImg, positionsAux,
                                element?.image_zoom, setStateData, stateData, setStateDataComponentsImageZoom 
                            )}

                            {!element.id && !element.name &&
                                <Box className={classes.emptyCOnfig}>
                                    <p>
                                        + Agregar componente.
                                    </p>
                                </Box>
                            }
                        </Box>
                    </>
                </div >
            )
        })
        return res
    }

    return (
        <div >
            <ResponsiveReactGridLayout
                onLayoutChange={onLayoutChange}
                layouts={{ lg: stateData?.positions ? stateData?.positions : [] }}
                breakpoints={{ lg: 1265, md: 996, }}
                cols={{ lg: 12, md: 1, }}
            >
                {CreateDom()}
            </ResponsiveReactGridLayout>
        </div >
    );
}

//Ejemplo:
// breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
// cols={{ lg: 12, md: 1, sm: 2, xs: 2, xxs: 2 }}