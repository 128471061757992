import { Box, FormControl, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import { useEffect } from "react";
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    '& .MuiInputLabel-root': {
        marginBottom: '8px', // Ajusta el valor según sea necesario
    },
}));

export default function FilterDifferenceBar({ stateComponent, setStateComponent, variables, setShowGranularitySelectVar, setColors, colors, setOptionVariableNode }) {
    const classes = useStyles();
    useEffect(() => {
        setShowGranularitySelectVar(true)
        const flag = 'DASHBOARDS_COMPARATOR';
        const variable1 = variables?.find(e => e.name === stateComponent?.variable1);
        const variable2 = variables?.find(e => e.name === stateComponent?.variable2);
        
        setOptionVariableNode(variable1?.node)

        if (variable1 && variable2) {
            const stringConfig = `${flag}-${variable1?.key}-${variable2?.key}-${stateComponent.periodo}`;
            setStateComponent(prevState => ({
                ...prevState,
                config_key: stringConfig,
            }));
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateComponent?.variable1, stateComponent?.variable2, stateComponent?.periodo]);


    const handleSelectedVariable = (variableName) => (e) => {
        const value = e.target.value;
        setStateComponent(prevState => ({
            ...prevState,
            [variableName]: value,
            periodo: 'MONTHLY',
        }));
    };

    const handleColorChange = debounce((colorIndex, value) => {
        if (colorIndex === 0){
            setColors({
                ...colors,
                color1:value
            })
        } else{
            setColors({
                ...colors,
                color2:value
            })
        }
    });

    return (
        <Box mt={1}>
            <Box className={classes.devicesLabel}>
                Variables a comparar
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'} width={'48%'}>
                    <Box width={'70%'}>
                        <FormControl fullWidth>
                            <Select
                                disabled={false}
                                onChange={handleSelectedVariable('variable1')}
                                defaultValue={''}
                            >
                                {variables?.map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem.name}
                                    >
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box width={'30%'}>
                        <TextField
                            style={{ width: '40%', marginLeft: '15px' }}
                            type="color"
                            value={colors?.color1}
                            name="color1"
                            onChange={(e) => handleColorChange(0, e.target.value)}
                        />
                    </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'48%'}>
                    <Box width={'70%'}>
                        <FormControl fullWidth>
                            <Select
                                disabled={false}
                                onChange={handleSelectedVariable('variable2')}
                                defaultValue={''}
                            >
                                {variables?.map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem.name}
                                    >
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box width={'30%'}>
                        <TextField
                            style={{ width: '40%', marginLeft: '15px' }}
                            type="color"
                            value={colors?.color2}
                            name="color2"
                            onChange={(e) => handleColorChange(1, e.target.value)}
                        />
                    </Box>
                </Box>

            </Box>
        </Box>
    );
}

