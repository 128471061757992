
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import {
    List, ListItem, ListItemText, Collapse, IconButton, Box,
} from '@material-ui/core';
//Icons
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    groupTitle: {
        backgroundColor: '#c5c5c578',
        marginTop: '3px',
        marginBottom: '3px',
        color: theme.palette.primary.main,

    },
    nodeHeader: {
        display: 'flex',
        '& span': {
            fontSize: "larger",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
        '& p': {
            marginLeft: '5px',
            fontSize: "larger"
        },
    },
    buttonExpand: {
        marginRight: '22px'
    }
}));

const ComponentChildren = ({ element, handleClose, disabledVarianteInstant, disabledVarianteHistoric, groupTitleKey, setGroupTitleKey, selectedValue }) => {
    const [open, setOpen] = useState(element?.key === groupTitleKey);
    const classes = useStyles();

    const handleArrows = () => {
        if (element.key === groupTitleKey) {
            setOpen(!open);
        }
        else {
            setOpen(true);
            setGroupTitleKey(element.key)
        }
    };

    const disabledVar = () => {
        if (!disabledVarianteHistoric) {
            return disabledVarianteHistoric
        }
        if (disabledVarianteInstant) {
            return disabledVarianteInstant.some(item => item.node.virtual)
        }
        return true
    }

    return (

        <Box
            width={'100%'}
            className={classes.root}
        >
            <Box display='flex' className={classes.groupTitle}>
                <ListItem >
                    <ListItemText primary={element.name} className={classes.nodeHeader} />
                </ListItem>

                {/* ICON OPEN CLOSE */}
                <Box width={30} className={classes.buttonExpand}>
                    {(groupTitleKey === element?.key && open ? (
                        <IconButton edge='end' aria-label='contraer' onClick={handleArrows}>
                            <ExpandLess />
                        </IconButton>

                    ) : (

                        <IconButton edge='end' aria-label='expandir' onClick={handleArrows}>
                            <ExpandMore />
                        </IconButton>
                    ))}
                </Box>

            </Box>

            <Collapse in={groupTitleKey === element?.key && open} timeout='auto' unmountOnExit>
                <List dense component='div' disablePadding>
                    <Box >
                        {element.children.map((elementAux) => {
                            return (
                                <ListItem
                                    selected={elementAux.name === selectedValue[0]}
                                    key={elementAux.key}
                                    button
                                    onClick={() => handleClose(elementAux)}
                                    disabled={elementAux.node === "FISICO" && disabledVar()}
                                >
                                    <ListItemText
                                        className={classes.nodeHeader}
                                        primary={`${elementAux?.tag ? elementAux?.tag : elementAux?.name} ${elementAux?.base_unit.length > 1 ? `(${elementAux.base_unit})` : (elementAux?.show_unit.length === 1 ? `(${elementAux.show_unit})` : '')}`}
                                    />
                                </ListItem>
                            )
                        })}
                    </Box>
                </List>
            </Collapse>

        </Box >
    )
}
export default ComponentChildren