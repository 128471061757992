import { useContext, useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
//Hook personalizado.
import DiagramContext from '../Store/DiagramContext';
//Helper
import { getSettingBlock } from '../helpers';
//Components.
import ModalSettings from '../ModalSettings';
//React flow
import { useEdges } from 'reactflow';

let styleAux = {
    width: `70px`,
    height: `70px`,
}

const useStyles = makeStyles(theme => ({
    root: {
        ...styleAux,
        backgroundColor: '#f5f5f5',
        border: `1px solid ${`black`}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    disabledStyle: {
        ...styleAux,
        pointerEvents: 'none',
        opacity: 0.5,
        backgroundColor: '#f0f0f0',
        color: '#a0a0a0',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function MUX4
    ({ id, isConnectable, type, selected }) {
    const classes = useStyles();
    const edges = useEdges();
    const { stateDiagram } = useContext(DiagramContext)
    const [openModal, setOpenModal] = useState(false)
    const [clickTimeout, setClickTimeout] = useState(null);
    const [disabledNode, setDisabledNode] = useState(false);
    const [inputSettings, setInputSettingse] = useState({
        inputs_min: 1,
        inputs_keys: []
    });
    let flagNumberTopInputs = 15

    useEffect(() => {
        let res = getSettingBlock(type)
        setInputSettingse(res)
        // console.log(res)
        // eslint-disable-next-line
    }, [])

    //Deshabilita el nodo segun el nodo el cual se esta conectando.
    useEffect(() => {
        let res = stateDiagram?.disabledNodes?.includes(type)
        setDisabledNode(res)
        // eslint-disable-next-line
    }, [stateDiagram?.disabledNodes])

    const handleOpenModal = () => {
        setOpenModal(!openModal)
    }
    const handleClick = () => {
        if (clickTimeout) {
            clearTimeout(clickTimeout);
            setClickTimeout(null);
        } else {
            const timeout = setTimeout(() => {
                setClickTimeout(null);
            }, 250); // 250 ms es el tiempo que espera para determinar si es un solo clic
            setClickTimeout(timeout);
        }
    };

    const checkTargetConnect = (idAux, elementAux) => {
        let flag = true
        for (let i = 0; i < edges.length; i++) {
            const element = edges[i];
            if (element.target === idAux) {
                if (element.targetHandle === elementAux.key) {
                    flag = false
                }
            }
        }
        return flag
    }

    return (
        <Box>

            <Box fontSize={6} display={'flex'} justifyContent={'center'}>
                {type}
            </Box>

            <Box
                className={disabledNode ? classes.disabledStyle : classes.root}
                key={id}
                style={{
                    border: selected ? '1px dashed #2586bc' : `1px solid black`,

                }}
                onClick={handleClick}
                onDoubleClick={handleOpenModal}
            >

                {openModal &&
                    <ModalSettings
                        handleOpenModal={handleOpenModal}
                        openModal={openModal}
                        id={id}
                        type={type}
                    />
                }

                <Box display={'flex'}>
                    <img src={`/images/diagram/${type.toLowerCase()}.svg`} alt="" width="35" height="35" />
                </Box>
                <Tooltip
                    title={'Salida'}
                    arrow
                    placement='right'
                >
                    <Handle
                        type="source"
                        position={Position.Right}
                        isConnectable={isConnectable}
                        id="1"
                        style={{ width: 8, height: 8 }}
                    />
                </Tooltip>

                {inputSettings?.inputs_keys?.map((element, index) => {
                    let flagAux = flagNumberTopInputs + 4
                    flagNumberTopInputs += 10
                    return (
                        <Box key={element.key}>
                            <Tooltip
                                title={element?.name}
                                arrow
                                placement='right'
                            >
                                <Handle
                                    // onConnect={(params) => console.log('handle onConnect', params)}
                                    // isValidConnection={(params) => console.log('handle onConnect', params)}
                                    key={index}
                                    type="target"
                                    position={element.key === 's1' || element.key === 's2' ? Position.Bottom : Position.Left}
                                    id={element.key}
                                    isConnectable={disabledNode ? false : isConnectable}
                                    style={{ left: element.key === 's1' ? 20 : (element.key === 's2' ? 10 : ''), top: element.key === 's1' || element.key === 's2' ? 75 : flagAux, width: 7, height: 7, border: `1px solid ${`black`}`, backgroundColor: checkTargetConnect(id, element) ? 'white' : '#b7b4b4' }}
                                />
                            </Tooltip>
                            <Box style={{
                                position: 'absolute',
                                top: element.key === 's1' || element.key === 's2' ? 63 : flagAux - 6,
                                left: element.key === 's1' ? 17 : (element.key === 's2' ? 7 : ''),
                                right: '53px',
                                fontSize: '8px',
                                width: '10px'
                            }}>
                                {element.key}
                            </Box>
                        </Box>
                    )

                })}

            </Box>

        </Box>

    );
}
export default MUX4
