import { Box, Button, Dialog, IconButton, makeStyles } from "@material-ui/core";
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from "react";

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden'
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};
const useStyles = makeStyles(theme => ({
    container: {
        overflow: 'hidden',
        border: '1px solid black',
    },
    mainTitle: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.2),
        fontWeight: "600",
        margin: theme.spacing(0, 0, 0, 3),
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    title: {
        fontSize: 20,
        color: theme.palette.primary.dark,
        margin: theme.spacing(0.5, 0, 0, 3)
    },
    containerItems: {
        paddingBottom: 5,
        paddingTop: 5,
        overflowY: 'scroll',
        borderTop: 'solid 2px #47a6db',
        borderBottom: 'solid 2px #47a6db',
        marginTop: 10,
        height: '80%',
        '@media (max-width: 900px)': {
            minWidth: 0,
        },
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
    },
    item: {
        padding: 15,
        borderBottom: 'solid 1px #47a6db',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    titleItem: {
        marginRight: 5
    },
    dialog: {
        '& .MuiDialog-container': {
            '@media (max-width: 1100px)': {
                margin: '0px 0px 0 0',
            }
        },
        '& .MuiDialog-paper': {
            margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
            color: '#423d3dde',
            maxHeight: '100vh',
            '@media (max-width: 900px)': {
                width: '100%',
                height: '100%',
            }
        }
    },
    description: {
        fontSize: 16,
        lineHeight: '33px',
        '@media (max-width: 900px)': {
            fontSize: 14,
        }
    },
    iconCloseButton: {
        marginLeft: theme.spacing(2),
        height: "40px"
    },
    circleList: {
        width: 5,
        height: 5,
        borderRadius: 300,
        marginRight: 10
    },
    detailsListItem: {
        padding: theme.spacing(1.2),
        display: 'flex',
        alignItems: 'center'
    },
    detailsListItemTitle: {
        color: theme.palette.primary.dark,
    },
    detailsListItemContent: {
        margin: theme.spacing(0, 0, 0, 3),
        textOverflow: "ellipsis",
    },
}));

function EventsCard({ events, title, subtitle, size, positions }) {
    const classes = useStyles();
    const [openDialog, setOpendialog] = useState(false);
    const [eventSelected, setEventSelected] = useState(false);

    const handleOpenDialog = () => {
        setOpendialog(!openDialog)
    }

    const handleEventSelected = (event) => {
        setEventSelected(event)
    }

    const calculateHeight = () => {
        if (positions?.h === 2) {
            return '70%'
        } else if (positions?.h === 3) {
            return '85%'
        } else {
            return '90%'
        }
    }

    const calculateFontSizeForTitleItem = () => {
        if (positions?.w === 3) {
            return '15px'
        } else {
            return '16px'
        }
    }

    const calculateFontSizeForMeterNameItem = () => {
        if (positions?.w === 3) {
            return '12px'
        } else {
            return '14px'
        }
    }

    const calculateFontSizeForSeeMoreItem = () => {
        if (positions?.w === 3) {
            return '14px'
        } else {
            return '16px'
        }
    }

    return (
        <>
            <Box style={{ height: '90%' }} classes={classes.container}>
                <Box className={classes.mainTitle} style={{ fontSize: "14px" }} >
                    {title} <span style={{ fontWeight: '400' }}>{subtitle}</span>
                </Box>
                <Box className={classes.title}>
                    Eventos
                </Box>

                <Box className={classes.containerItems} style={{ height: calculateHeight() }}>
                    {events.map((item, index) => (
                        <Box className={classes.item} key={item.id || index}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box style={{ backgroundColor: '#2586bc' }} className={classes.circleList}></Box>
                                <Box>
                                    <Box className={classes.titleItem} style={{ fontSize: calculateFontSizeForTitleItem() }}>
                                        {item?.event?.title}
                                    </Box>
                                    <Box style={{ fontSize: calculateFontSizeForMeterNameItem() }}>
                                        ({item?.event?.meter_name})
                                    </Box>
                                </Box>
                            </Box>
                            <Button onClick={() => {
                                handleOpenDialog();
                                handleEventSelected(item?.event)
                            }}
                                style={{ color: '#47a6db', marginLeft: '10px', textTransform: 'none', fontSize: calculateFontSizeForSeeMoreItem() }}>
                                Ver más
                            </Button>
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Dialog detalle de evento seleccionado*/}
            <Dialog
                className={classes.dialog}
                open={openDialog}
                onClose={handleOpenDialog}
                aria-labelledby='form-dialog-title'
                maxWidth='sm'
                fullWidth
            >
                <Box style={{ borderBottom: `solid 1px ${'#47a6db'}`, backgroundColor: 'white' }}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    pt={1}
                    pb={1}
                >
                    <IconButton
                        className={classes.iconCloseButton}
                        onClick={() => handleOpenDialog()}
                    >
                        <CancelIcon />
                    </IconButton>
                </Box>

                {/* Titulo */}
                <Box className={classes.title} style={{ borderBottom: `solid 1px ${'#47a6db'}`, width: '100%', textAlign: 'center' }}>
                    {eventSelected?.title}
                </Box>

                {/* Detalles */}
                <Box style={{ width: '100%', paddingBottom: 15, paddingTop: 15 }} >
                    <Box>
                        <Box className={classes.detailsListItem}>
                            <Box style={{ backgroundColor: '#2586bc' }} className={classes.circleList}>
                            </Box>
                            <Box className={classes.detailsListItemTitle} >
                                Nombre de medidor
                            </Box>
                        </Box>
                        <Box style={{ borderBottom: `solid 1px ${'#a19292de'}`, paddingBottom: '3px' }} className={classes.detailsListItemContent}  >
                            {eventSelected.meter_name}
                        </Box>
                    </Box>
                    <Box>
                        <Box className={classes.detailsListItem}>
                            <Box style={{ backgroundColor: '#2586bc' }} className={classes.circleList}>
                            </Box>
                            <Box className={classes.detailsListItemTitle} >
                                Descripción
                            </Box>
                        </Box>
                        <Box style={{ borderBottom: `solid 1px ${'#a19292de'}`, paddingBottom: '3px' }} className={classes.detailsListItemContent}  >
                            {eventSelected.description}
                        </Box>
                    </Box>
                    <Box>
                        <Box className={classes.detailsListItem}>
                            <Box style={{ backgroundColor: '#2586bc' }} className={classes.circleList}>
                            </Box>
                            <Box className={classes.detailsListItemTitle} >
                                Duración
                            </Box>
                        </Box>
                        <Box style={{ borderBottom: `solid 1px ${'#a19292de'}`, paddingBottom: '3px' }} className={classes.detailsListItemContent}  >
                            {eventSelected.duration_str}
                        </Box>
                    </Box>
                    <Box>
                        <Box className={classes.detailsListItem}>
                            <Box style={{ backgroundColor: '#2586bc' }} className={classes.circleList}>
                            </Box>
                            <Box className={classes.detailsListItemTitle} >
                                Inicio
                            </Box>
                        </Box>
                        <Box style={{ borderBottom: `solid 1px ${'#a19292de'}`, paddingBottom: '3px' }} className={classes.detailsListItemContent}  >
                            {eventSelected.from_formated}
                        </Box>
                    </Box>
                </Box>
            </Dialog >
        </>

    )
}
export default EventsCard;

