import React, { useEffect, useState } from 'react';
import {
    Box, Button, IconButton, Stepper, Step, Tooltip, MenuItem,
    makeStyles, Dialog, TextField, StepLabel, Chip, Select, FormControl, FormControlLabel, Radio, InputAdornment,
    RadioGroup, Tabs, Tab
} from '@material-ui/core';
//icons
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import DoneIcon from '@material-ui/icons/Done';
//Style
import clsx from 'clsx';
//Helpers
import { blueBar } from '../../helpers/common'
import { helperComponent, OPTIONS } from './helpers';
//Components
import Filter from '../Dashboards/SummaryLocation/Filter'
import MultiSelectorNodeTag from './MultiSelectorNodeTag';
import ContainerUpdateImage from '../Dashboards/ComponentsDashboards/ContainerUpdateImage';
import FilterDifferenceBar from './FilterDifferenceBar';

const useStyles = makeStyles(theme => ({
    containerMain: {
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
        overflowY: 'auto',
        maxHeight: '80vh',
    },
    btnSpacing: {
        margin: theme.spacing(0, 1),
    },
    errorLabel: {
        color: 'red',
        fontSize: theme.spacing(2),
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    addBtn: {
        margin: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    filterChip: {
        margin: theme.spacing(0.5),
        '@media (max-width: 700px)': {
            width: '250px',
        },
    },
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: 'none',
                mozAppearance: 'none',
                appearance: ' none',
                margin: 0
            },
        },
    },
    tabsTitle: {
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        textAlign: 'center',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    radio: {
        height: '150px',
        marginLeft: theme.spacing(1),
        '& .MuiTypography-body1': {
            fontSize: '0.75rem',
        },
        '& > *': {
            flexBasis: '33%',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },
        '@media (max-width: 700px)': {
            height: 'auto',
        },
    },
    containerLimit: {
        margin: theme.spacing(1, 0, 0, 0),
        display: 'flex',
        gap: theme.spacing(2)
    }
}));

export default function FilterComponent({
    setStateData, stateData, filterPanelComponent,
    togglePanel
}) {
    const classes = useStyles();
    //Steeps options.
    const [activeStep, setActiveStep] = useState(0);
    const [filterPanel, setFilterPanel] = useState(false);
    //Datos de el device seleccionado
    const [location, setLocation] = useState({});
    //Estado de el multiselector de nodos.
    const [stateMultiSelector, setStateMultiSelector] = useState({});
    const [flagOpenMultiSelector, setFlagOpenMultiSelector] = useState(0)
    const blueLine = blueBar()
    const [arrayGranularityValue, setArrayGranularityValue] = useState([]);
    const [showGranularitySelectVar, setShowGranularitySelectVar] = useState(false)
    const [granularityIndex, setGranularityIndex] = useState(0)
    const [showUnityGaugueLimit, setShowUnityGaugueLimit] = useState('')
    const [optionVariableNode, setOptionVariableNode] = useState('')
    //Estado del componente.
    const initialState = {
        name: '',
        id: '',
        nodes: [],
        nameDevice: '',
        indexBox: '',
        min: 0,
        max: 0,
        image_zoom: ''
    }
    const [stateComponent, setStateComponent] = useState(initialState);
    const steps = [
        'Elija un titulo  (opcional)',
        'Elija un componente y variable.',
        'Elija un dispositivo',
        'Haga click en Guardar'
    ];
    //Estado para seleccionar colores en selector comparador
    const [colors, setColors] = useState({
        color1: '#00b8d7',
        color2: '#ef4b6b'
    });

    //este es el objeto de componentes transformado en lista para el input de seleccion.
    let listComponentNames = Object.entries(OPTIONS).map(([clave, valor]) => valor)

    //Estado selector simple.
    useEffect(() => {
        setStateComponent({
            ...stateComponent,
            nameDevice: location?.nombre,
            color: location?.color,
            id: location?.id,
        })
        if (location?.id) {
            setActiveStep(3)
        }
        // eslint-disable-next-line 
    }, [location])

    //Estado multiselector.
    useEffect(() => {
        if (stateMultiSelector?.selectedNodes?.length > 0) {
            let nodes = []
            stateMultiSelector?.selectedNodes.forEach(element => {
                let obj = {
                    nombre: element.nombre,
                    id: element?.id,
                    tag: element?.tag
                }
                nodes.push(obj)
            });

            setStateComponent({
                ...stateComponent,
                nameDevice: '',
                nodes: nodes,
                id: null,
            })
        }
        // eslint-disable-next-line
    }, [stateMultiSelector])

    useEffect(() => {
        let indexAux = filterPanelComponent.indexBox
        let newComponent = stateData?.components.find((elem, i) => i === indexAux)
        //Si el componente existe cargo los datos en el filtro.
        if (newComponent?.nodes || newComponent?.id) {
            //Variables de datos del catalogo
            let KEY_CATALOGUE = newComponent.config_key?.split('-')[0]
            let KEY_VAR = newComponent.config_key?.split('-')[1]
            let VAR_SELECT = helperComponent(KEY_CATALOGUE)?.find(e => e.key === KEY_VAR)
            //Guardo la propiedad node q me habilita un nodo segun lo permitido desde el back
            //opciones FISICO  o VIRTUA_FISICO
            setOptionVariableNode(VAR_SELECT?.node)
            //Unidad de la variable para colocar en el input de el limit min max.
            setShowUnityGaugueLimit(VAR_SELECT?.base_unit)
            //Estado del componente general
            setStateComponent(newComponent)
            returnArrayGranularity(newComponent?.variable, newComponent?.name)
            setActiveStep(3)
        }
        else {
            setStateComponent({
                ...initialState,
                indexBox: filterPanelComponent.indexBox
            })
            setActiveStep(0)
        }
        // eslint-disable-next-line 
    }, [filterPanelComponent])

    // Abre o cierra modal q contiene selector de dispositivos.
    const showDevicesList = () => {
        setFilterPanel(!filterPanel)
    }

    //Funcion q arma el config_key.
    const createConfigKey = (valueProp, name) => {
        let flag = ''
        if (stateComponent?.name === OPTIONS.lineInstantGraph) {
            flag = 'DASHBOARDS_INSTANT'
        }
        if (stateComponent?.name === OPTIONS.barHistoricGraph) {
            flag = 'DASHBOARDS_HISTORY'
        }
        if (stateComponent?.name === OPTIONS.numberIndicator) {
            flag = 'DASHBOARDS_INDICATOR'
        }
        if (stateComponent?.name === OPTIONS.gauge) {
            flag = 'DASHBOARDS_GAUGE'
        }
        if (stateComponent?.name === OPTIONS.flowEnergy) {
            flag = 'DASHBOARDS_SOLAR_FLOW'
        }

        let stringConfig = ''
        if (name === 'select-variable') {
            let res = helperComponent(flag).find(e => e.label === valueProp);
            if (res?.periods.length > 0) {
                stringConfig = `${flag}-${res?.key}-${res?.endpoint?.key}-${res?.periods[0]?.values[0]?.key}-${res?.periods[0]?.key}`
            }
            else {
                stringConfig = `${flag}-${res?.key}-${res?.endpoint?.key}`
            }
            //Guardo la propiedad node q me habilita un nodo segun lo permitido desde el back
            //opciones FISICO  o VIRTUA_FISICO
            setOptionVariableNode(res?.node)
            //Agrego la unidad con respecto a la variable seleccionada en la opcion de limit max min.
            let base_unit = res?.base_unit
            setShowUnityGaugueLimit(base_unit)
            setGranularityIndex(0)
        }
        if (name === 'granularity') {
            let res = helperComponent(flag).find(e => e.label === stateComponent?.variable)
            let findElement = res.periods.find(e => e.key === valueProp.key)
            stringConfig = `${flag}-${res.key}-${res?.endpoint?.key}-${findElement.values[0].key}-${valueProp.key}`
        }
        if (name === 'period') {
            if (stateComponent.config_key) {
                let value = stateComponent.config_key.split('-')
                value[3] = valueProp
                stringConfig = value.join('-')
            }
        }
        return stringConfig
    }

    //Handle selector de componente y selector de variable.
    const handleChangeSelectDevice = (e) => {
        if (e.target.name === 'select-component') {
            setStateComponent({
                ...stateComponent,
                name: e.target.value,
                variable: undefined,
                nodes: []
            })
            setShowGranularitySelectVar(false)
        }
        if (e.target.name === 'select-variable') {
            returnArrayGranularity(e.target.value, stateComponent?.name)
            setStateComponent({
                ...stateComponent,
                variable: e.target.value,
                config_key: createConfigKey(e.target.value, e.target.name),
                nodes: []
            })
        }
        setActiveStep(2)
        //Limpio location siempre q elijo un componente o una variable.
        setLocation({})
    }

    //Handle granularidad.
    const handleGranularity = (event, tabNumber) => {
        setGranularityIndex(tabNumber)
        setStateComponent({
            ...stateComponent,
            granularity: arrayGranularityValue[tabNumber].label,
            config_key: createConfigKey(arrayGranularityValue[tabNumber], 'granularity',)
        })
    }

    //Handle periodo.
    const handlePeriod = (e) => {
        setStateComponent({
            ...stateComponent,
            config_key: createConfigKey(e.target.value, 'period',)
        })
    }

    //Handle limit.
    const handleLimit = (e) => {
        if (e.target.name === 'min') {
            setStateComponent({
                ...stateComponent,
                min: parseFloat(e.target.value)
            })
        }
        if (e.target.name === 'max') {
            setStateComponent({
                ...stateComponent,
                max: parseFloat(e.target.value)
            })
        }
    }

    //Controlador de estado del titulo.
    const handleChangeTitle = (e) => {
        let titleAux = e.target.value
        setStateComponent({
            ...stateComponent,
            title: titleAux
        })
    }

    //Controlador de estado del titulo.
    const handleChangeImg = (e) => {
        let imgAux = e
        setStateComponent({
            ...stateComponent,
            file_base64: imgAux,
            config_key: "DASHBOARDS_IMAGE"
        })
    }

    //Evento guardar Configuracion Box.
    const save = () => {
        let newData = stateData.components.map((elem, index) => {
            if (index === stateComponent?.indexBox) {
                let componentAux = {
                    ...stateComponent,
                    color: `${colors.color1}-${colors.color2}`
                }
                return componentAux
            }
            else {
                return elem
            }
        })

        setStateData({
            ...stateData,
            components: newData,
        })
        togglePanel()
        setLocation([])
        setStateMultiSelector({})
    }

    //Evento cancelar.
    const handleCancel = () => {
        togglePanel()
        setLocation([])
        setActiveStep(0)
        setStateMultiSelector({})
    }

    //Retorna la validacion de si es alguno de estos componentes muestra el selector de variables.
    const showVariableSelect = (value) => {
        return value === OPTIONS.barHistoricGraph || value === OPTIONS.lineInstantGraph
            || value === OPTIONS.numberIndicator || value === OPTIONS.gauge || value === OPTIONS.flowEnergy
    }

    //Funcion q muestra el selector de granularidad segun las opciones permitidas en el archivo helpers.js.
    const showGranularitySelect = (name) => {
        let res = false
        if (stateComponent?.name === OPTIONS.lineInstantGraph) {
            res = true
        }
        if (stateComponent?.name === OPTIONS.barHistoricGraph && name) {
            res = true
        }
        if (stateComponent?.name === OPTIONS.numberIndicator) {
            res = true
        }
        if (stateComponent?.name === OPTIONS.listEvents) {
            res = true
        }
        return res
    }

    //Retorna el array para el selector de opciones de variables segun el componente seleccionado.
    const returnVarArray = (value) => {
        const componentNameMap = {
            [OPTIONS.barHistoricGraph]: 'DASHBOARDS_HISTORY',
            [OPTIONS.lineInstantGraph]: 'DASHBOARDS_INSTANT',
            [OPTIONS.numberIndicator]: 'DASHBOARDS_INDICATOR',
            [OPTIONS.gauge]: 'DASHBOARDS_GAUGE',
            [OPTIONS.flowEnergy]: 'DASHBOARDS_SOLAR_FLOW',
            [OPTIONS.ShowDiferenceBar]: 'DASHBOARDS_COMPARATOR',
        };
        return componentNameMap[value] ? helperComponent(componentNameMap[value]) : [];
    };

    //Funcion q retorna los valores de granularidad segun la opcion seleccionada.
    const returnArrayGranularity = (name, stateComponentName) => {
        const componentNameMap = {
            [OPTIONS.lineInstantGraph]: 'DASHBOARDS_INSTANT',
            [OPTIONS.barHistoricGraph]: 'DASHBOARDS_HISTORY',
            [OPTIONS.numberIndicator]: 'DASHBOARDS_INDICATOR',
        };
        const flag = componentNameMap[stateComponentName];
        if (name && flag) {
            const res = helperComponent(flag).find(e => e.label === name);
            if (res) {
                setArrayGranularityValue(res?.periods);
            }
        }
        setShowGranularitySelectVar(true);
    };

    //Deshabilita el boton guardar segun el caso.
    const disabledButtonSave = () => {
        let res = true

        if (stateComponent.nodes?.length > 0 && stateComponent.name && stateComponent.variable) {
            res = false
            if (stateComponent.name === OPTIONS.lineInstantGraph || stateComponent.name === OPTIONS.barHistoricGraph) {
                return false
            }
        }
        else if (stateComponent.name === OPTIONS.barGraphTags) {
            return false
        }
        else if (stateComponent.name === OPTIONS.gauge) {
            if (stateComponent.config_key && stateComponent.variable && stateComponent?.nameDevice) {
                return false
            }
        }
        else if (stateComponent.name === OPTIONS.numberIndicator) {
            if (stateComponent.variable) {
                return false
            }
        }
        else if (stateComponent.name === OPTIONS.imageContainer) {

            return false

        }

        if (stateComponent.name === OPTIONS.listEvents) {
            return false
        }
        if (stateComponent.name === OPTIONS.flowEnergy) {
            return false
        }
        if (stateComponent.name === OPTIONS.ShowDiferenceBar) {
            return false
        }
        return res
    }

    //Funcion q me devuelve el index de el valor para configurar la granularidad seleccionada.
    const returnIndexGranularity = (value) => {
        for (let i = 0; i < arrayGranularityValue.length; i++) {
            if (arrayGranularityValue[i].key === value) {
                return i;
            }
        }
    }

    //Funcion q me devuelve el index de la ubicacion de el periodo seleccionado.
    const returnRadioOptionsIndex = (value) => {
        let valueAux = value.split('-')
        var valorBuscado = valueAux[valueAux.length - 1]
        var indice = arrayGranularityValue.findIndex(function (objeto) {
            return objeto.key === valorBuscado;
        });
        return indice
    }

    //Funcion q abre el filtro multiselector comunicando a componente MultiSelectorNode.js en q modo abre.
    const openMultiSelectorDynamic = (param) => {
        setFlagOpenMultiSelector(param)
        showDevicesList(true)
    }

    //Elimina chip.
    const handleDeleteNodeSelection = (indx) => {
        let nodes = stateComponent.nodes.filter((elem, ind) => indx !== ind)
        setStateComponent({
            ...stateComponent,
            nodes: nodes,
        })
    }

    const disableDevicesOptions = () => {
        if (stateComponent.name === OPTIONS.ShowDiferenceBar) {
            return false;
        } else {
            return !((stateComponent.name.length > 0) && (stateComponent?.variable))
        }
    }

    return (
        <Dialog
            open={filterPanelComponent?.state}
            onClose={togglePanel}
            aria-labelledby='modal_name'
            maxWidth='lg'
            fullWidth
            disableScrollLock
        >

            {
                stateComponent?.name === OPTIONS.lineInstantGraph || stateComponent?.name === OPTIONS.barHistoricGraph
                    /* Multiselector para instantaneos y historicos. */
                    ?
                    <MultiSelectorNodeTag
                        isOpen={filterPanel}
                        setActiveStep={setActiveStep}
                        setIsOpen={showDevicesList}
                        setStateMultiSelector={setStateMultiSelector}
                        stateMultiSelector={stateMultiSelector}
                        flagOpenMultiSelector={flagOpenMultiSelector}
                        stateComponent={stateComponent}
                    />
                    /* Selector simple de nodos. */
                    :
                    <Filter
                        optionVariableNode={optionVariableNode}
                        dashboard={true}
                        isOpen={filterPanel}
                        setIsOpen={showDevicesList}
                        setLocation={setLocation}
                        location={location}
                        setStateData={setStateData}
                    />
            }

            <Box className={classes.containerMain}>
                <h3 style={{ fontSize: '18px' }}>Opciones</h3>

                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {/* STEP 1 */}
                <Box display='flex' alignItems='flex-start' >
                    <Tooltip
                        title='Elija el titulo q desea para su componente'
                        arrow
                        placement='right'
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 0 })} >
                            {1}
                        </Box>
                    </Tooltip>

                    <Box className={classes.devicesLabel}>
                        <Box className={classes.devicesLabel}>
                            Selecciona un titulo para su componente
                        </Box>
                        <TextField
                            className={classes.input}
                            autoFocus
                            type='text'
                            name='title'
                            value={stateComponent?.title}
                            onChange={handleChangeTitle}
                            color='primary'
                        />
                    </Box>
                </Box>

                {blueLine}

                {/* STEP 2 */}
                {/* Selector de Componente */}
                <Box display='flex' alignItems='flex-start' flexWrap='wrap'>
                    <Tooltip
                        title='Elija el componente y las opciones q desee graficar'
                        arrow
                        placement='right'
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 2 })}  >
                            {activeStep >= 2 ? <DoneIcon style={{ padding: '2px' }} /> : 2}
                        </Box>
                    </Tooltip>
                    <Box width={'55%'}>
                        <Box className={classes.devicesLabel}>
                            Seleccione un componente
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                name='select-component'
                                value={stateComponent?.name}
                                onChange={handleChangeSelectDevice}
                            >
                                {listComponentNames.map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem}
                                    >
                                        {elem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <>
                            {/* Selectores para comparador */}
                            {stateComponent?.name === OPTIONS.ShowDiferenceBar &&
                                <FilterDifferenceBar
                                    stateComponent={stateComponent}
                                    variables={returnVarArray(stateComponent?.name)}
                                    setStateComponent={setStateComponent}
                                    createConfigKey={createConfigKey}
                                    setOptionVariableNode={setOptionVariableNode}
                                    setColors={setColors}
                                    colors={colors}
                                    setShowGranularitySelectVar={setShowGranularitySelectVar}
                                />
                            }
                            {/* Selector de variables */}
                            {showVariableSelect(stateComponent?.name) &&
                                <Box mt={1} >
                                    <Box className={classes.devicesLabel}>
                                        Variable a graficar
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name='select-variable'
                                            value={stateComponent?.variable ? stateComponent?.variable : ''}
                                            onChange={handleChangeSelectDevice}
                                            disabled={false}
                                        >
                                            {returnVarArray(stateComponent?.name).map((elem, indx) => (
                                                < MenuItem
                                                    key={indx}
                                                    value={elem.label}
                                                >
                                                    {elem.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            }

                            {/* Opcion (Inputs) de limite max y min componente gauge. */}                            {
                                stateComponent?.name === OPTIONS.gauge &&
                                <Box mt={1} >
                                    <Box className={classes.devicesLabel}>
                                        Configure el límite máximo y mínimo
                                    </Box>
                                    <Box className={classes.containerLimit} >
                                        <Box maxWidth='40%' >
                                            <TextField
                                                fullWidth
                                                type='number'
                                                name='min'
                                                label='min'
                                                size='small'
                                                variant='outlined'
                                                defaultValue='0'
                                                value={stateComponent?.min}
                                                // disabled={disabledInputFunction()}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position='end'>{showUnityGaugueLimit}</InputAdornment>,
                                                }}
                                                onChange={handleLimit}
                                                color='primary'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                className={clsx(classes.input)}
                                            />
                                        </Box>

                                        <Box maxWidth='40%'>
                                            <TextField
                                                fullWidth
                                                type='number'
                                                name='max'
                                                label='max'
                                                size='small'
                                                variant='outlined'
                                                defaultValue='0'
                                                value={stateComponent?.max}
                                                onChange={handleLimit}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position='end'>{showUnityGaugueLimit}</InputAdornment>,
                                                }}
                                                color='primary'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                className={clsx(classes.input)}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            }

                            {/* Opcion de granularidad */}
                            {showGranularitySelect(stateComponent?.variable) && showGranularitySelectVar &&
                                <Box mt={2}>
                                    <Box display='flex' alignItems='center' flexWrap='wrap'>
                                        <Box className={classes.devicesLabel}>
                                            Granularidad y periodo
                                        </Box>
                                    </Box>

                                    <Box display='flex' justifyContent='center'>
                                        <span className={classes.tabsTitle}>Granularidad</span>
                                    </Box>
                                    <Tabs
                                        value={stateComponent?.config_key?.split('-')[4] ? returnIndexGranularity(stateComponent?.config_key?.split('-')[4]) : granularityIndex}
                                        onChange={handleGranularity}
                                        indicatorColor='primary'
                                        textColor='primary'
                                        centered
                                    >
                                        {
                                            arrayGranularityValue.map(period => <Tab key={period.key} label={period.label} />)
                                        }
                                    </Tabs>
                                    <Box>
                                        <FormControl component='fieldset' fullWidth>
                                            <RadioGroup
                                                aria-label='chosen_range'
                                                name='chosen_range'
                                                value={stateComponent?.config_key?.split('-')[3] ? stateComponent?.config_key.split('-')[3] : arrayGranularityValue[granularityIndex]?.values[0].key}
                                                onChange={handlePeriod}
                                                className={classes.radio}
                                            >
                                                {
                                                    arrayGranularityValue[returnRadioOptionsIndex(stateComponent?.config_key)]?.values?.map(option => (
                                                        <FormControlLabel
                                                            key={option.key}
                                                            value={option.key}
                                                            control={<Radio color='primary' />}
                                                            label={option.name}
                                                        />
                                                    ))
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                            }
                        </>
                    </Box>
                </Box>

                {blueLine}

                {/* STEP 3 */}
                {/* Selector de dispositivos */}
                {stateComponent?.name !== OPTIONS.imageContainer &&
                    <Box display='flex' alignItems='center' flexWrap='wrap' mt={2}>
                        <Tooltip
                            title='Elija el/los dispositivos que desea graficar'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 3 })}>
                                {activeStep >= 3 ? <DoneIcon style={{ padding: '2px' }} /> : 3}
                            </Box>
                        </Tooltip>
                        <Box className={classes.devicesLabel}>
                            Selecciona un dispositivo
                        </Box>

                        <IconButton
                            aria-label='agregar dispositivos'
                            className={classes.addBtn}
                            disabled={disableDevicesOptions()}
                            onClick={() => {
                                openMultiSelectorDynamic('main')

                            }}  >
                            <AddIcon fontSize='small' />
                        </IconButton>

                        {
                            stateComponent?.nodes?.length > 0 ?
                                stateComponent?.nodes?.map((elem, indx) => {
                                    return (
                                        <div key={indx}>
                                            <Chip
                                                key={indx}
                                                icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                                                label={elem?.tag?.id ? `${elem.nombre} + ${elem?.tag?.nombre}` : elem.nombre}
                                                clickable
                                                color='primary'
                                                className={classes.filterChip}
                                                onClick={() => {
                                                    openMultiSelectorDynamic(indx)
                                                }}
                                                onDelete={() => handleDeleteNodeSelection(indx)}

                                            />
                                        </div>

                                    )
                                })
                                :
                                <>
                                    {stateComponent.id &&
                                        <Chip
                                            icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                                            label={stateComponent?.nameDevice}
                                            clickable
                                            color='primary'
                                            className={classes.filterChip}
                                            onClick={() => showDevicesList(true)}
                                        />
                                    }
                                </>
                        }
                    </Box>
                }

                {/* Opcion selector de imagen */}
                {stateComponent?.name === OPTIONS.imageContainer &&
                    <Box display='flex' justifyContent={'center'} >
                        <Box width={'90%'} mt={2} >
                            <ContainerUpdateImage
                                handleChangeImg={handleChangeImg}
                                stateComponent={stateComponent}
                            />
                        </Box>
                    </Box>
                }

                {/* Botones */}
                <Box display='flex' justifyContent='center' m={2}>
                    <Button
                        disabled={disabledButtonSave()}
                        onClick={save}
                        variant='contained' color='primary' className={classes.btnSpacing}>
                        Guardar
                    </Button>
                    <Button
                        onClick={handleCancel}
                        variant='contained' className={classes.btnSpacing}>
                        Cancelar
                    </Button>
                </Box>

            </Box>
        </Dialog >
    )
}
