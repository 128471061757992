
// Material UI
import {
    Box, CircularProgress, Dialog,
} from '@material-ui/core';

const LoadingComponentBlur = ({ loadingState }) => {
    return (
        <Dialog
            open={loadingState}
            PaperProps={{
                style: {
                    backgroundColor: '#00000000',
                    boxShadow: 'none',
                    height: "715px"
                },
            }}
            aria-labelledby="modal_inerl"
            disableScrollLock
            fullWidth
            maxWidth='sm'
        >
            <Box display="flex" justifyContent={"center"} alignItems={"center"} width={"100%"} height={"100%"}>
                <CircularProgress
                    style={{ color: '#24c9f4' }}
                />
            </Box>
        </Dialog>
    )
}

export default LoadingComponentBlur