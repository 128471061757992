
import { useEffect, useState, } from 'react';

import {
    Box, Stepper, Step, Tooltip, MenuItem,
    makeStyles, StepLabel, Select, FormControl,
    TextField,
} from '@material-ui/core';
//Style
import clsx from 'clsx';
//Icons
import DoneIcon from '@material-ui/icons/Done';
//Helpers.
import { blueBar } from '../../../helpers/common'
import { getSettingBlock } from '../helpers';
//Textos 
import { returnTextsBlock, MBS_OPTIONS_TR, MBS_OPTIONS_TD, MBS_OPTIONS_ENDIANNES } from './helperDescriptionText';

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    root: {
        ...scrollsSettings,
        height: '600px',
        '@media (max-width: 920px)': {
            height: '70%',
        },
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
        },
        '& .MuiStepper-root': {
            padding: '1px',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: theme.spacing(0.5),
        },
    },
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3.1),
        width: theme.spacing(3.1),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    LabelDisabledGranularityOptions: {
        color: 'black',
        fontSize: '0.75rem',
    },
    addBtn: {
        margin: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    filterChip: {
        margin: theme.spacing(0.5),
        '@media (max-width: 700px)': {
            width: '250px',
        },
    },
    btn: {
        margin: theme.spacing(3, 1),
        '@media (max-width: 700px)': {
            fontSize: '13px',
            padding: '1%',
        },
    },
    tabsTitle: {
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        textAlign: 'center',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    radio: {
        height: '150px',
        marginLeft: theme.spacing(1),
        '& .MuiTypography-body1': {
            fontSize: '0.75rem',
        },
        '& > *': {
            flexBasis: '33%',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },
        '@media (max-width: 700px)': {
            height: 'auto',
        },
    },
    formWeekSelected: {
        display: 'flex'
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    },
}));

const MbsFilterOptions = ({ varState, setVarState, typeBlock, userConfiguration }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    //Estado de el filtro.
    const [stateForm, setStateForm] = useState({})
    //Steeps options.
    const steps = [
        '',
        '',
        '',
        '',
        '',
        '',
    ]

    useEffect(() => {
        if (varState?.a) {
            setStateForm(varState?.a)
        }
    }, [varState?.a])

    useEffect(() => {
        setVarState({
            ...varState,
            a: stateForm
        })
        // eslint-disable-next-line
    }, [stateForm])

    const handleChange = (e) => {
        let { value, name } = e.target;
        setStateForm({
            ...stateForm,
            [name]: value
        })
        handleactiveStep({
            ...stateForm,
            [name]: value
        })
    }

    const handleactiveStep = (stateAux) => {
        if (stateAux?.r) {
            setActiveStep(1)
        }
        if (stateAux?.a) {
            setActiveStep(2)
        }
        if (stateAux?.d) {
            setActiveStep(3)
        }
        if (stateAux?.e) {
            setActiveStep(4)
        }
        if (stateAux?.g) {
            setActiveStep(5)
        }
        if (stateAux?.o) {
            setActiveStep(6)
        }
    }

    return (
        <Box>
            {/* STEPPERS
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, indx) => (
                    <Step key={indx}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper> */}

            {/* {blueLine} */}


            {/* TITULO */}
            <Box m={1} >
                <h3 style={{ fontSize: '18px' }}>{userConfiguration ? `${getSettingBlock(typeBlock)?.name}` : `${getSettingBlock(typeBlock)?.name}`}</h3>
            </Box >

            {blueLine}


            <Box mb={2} >
                <Box className={classes.title}>
                    Descripcion:
                </Box>
                <Box className={classes.content} ml={1}>
                    {returnTextsBlock(typeBlock).description}
                </Box>
            </Box>

            {blueLine}

            {/* STEP 1 */}
            <Box display={'flex'} >
                <Tooltip
                    title='Nuevo tooltip'
                    arrow
                    placement='right'
                >
                    <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: stateForm?.r })}  >
                        {stateForm?.r ? <DoneIcon style={{ padding: '2px' }} /> : 1}
                    </Box>
                </Tooltip>
                <Box width={'81%'} >
                    <Box className={classes.devicesLabel}>
                        Tipo de registro
                    </Box>
                    <FormControl fullWidth>
                        <Select
                            name='r'
                            value={stateForm?.r ? stateForm?.r : ''}
                            onChange={handleChange}
                        // disabled={disableOperatorSelect}
                        >
                            {MBS_OPTIONS_TR?.map((elem, indx) => (
                                <MenuItem
                                    key={elem.value}
                                    value={elem.key}
                                >
                                    {elem.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {blueLine}


            {/* STEP 2 */}
            <Box display={'flex'} >
                <Tooltip
                    title='Nuevo tooltip'
                    arrow
                    placement='right'
                >
                    <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: stateForm?.a })}  >
                        {stateForm?.a ? <DoneIcon style={{ padding: '2px' }} /> : 2}
                    </Box>
                </Tooltip>
                <Box width={'81%'} >
                    <Box className={classes.devicesLabel}>
                        Address
                    </Box>
                    <Box >
                        <Box className={classes.input} >
                            <TextField
                                fullWidth
                                type={"number"}
                                name='a'
                                size='small'
                                variant='outlined'
                                value={stateForm?.a ? stateForm?.a : ''}
                                onChange={handleChange}
                                color='primary'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 3 */}
            <Box display={'flex'} >
                <Tooltip
                    title='Nuevo tooltip'
                    arrow
                    placement='right'
                >
                    <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: stateForm?.d })}  >
                        {stateForm?.d ? <DoneIcon style={{ padding: '2px' }} /> : 3}
                    </Box>
                </Tooltip>
                <Box width={'81%'} >
                    <Box className={classes.devicesLabel}>
                        Tipo de dato
                    </Box>
                    <FormControl fullWidth>
                        <Select
                            name='d'
                            value={stateForm?.d ? stateForm?.d : ''}
                            onChange={handleChange}
                        // disabled={disableOperatorSelect}
                        >
                            {MBS_OPTIONS_TD?.map((elem, indx) => (
                                <MenuItem
                                    key={elem.value}
                                    value={elem.key}
                                >
                                    {elem.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 4 */}
            <Box display={'flex'} >
                <Tooltip
                    title='Nuevo tooltip'
                    arrow
                    placement='right'
                >
                    <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: stateForm?.e })}  >
                        {stateForm?.e ? <DoneIcon style={{ padding: '2px' }} /> : 4}
                    </Box>
                </Tooltip>
                <Box width={'81%'} >
                    <Box className={classes.devicesLabel}>
                        Endianness
                    </Box>
                    <FormControl fullWidth>
                        <Select
                            name='e'
                            value={stateForm?.e ? stateForm?.e : ''}
                            onChange={handleChange}
                        // disabled={disableOperatorSelect}
                        >
                            {MBS_OPTIONS_ENDIANNES?.map((elem, indx) => (
                                <MenuItem
                                    key={elem.value}
                                    value={elem.key}
                                >
                                    {elem.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 5 */}
            <Box display={'flex'} >
                <Tooltip
                    title='Nuevo tooltip'
                    arrow
                    placement='right'
                >
                    <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: true })}  >
                        {false ? <DoneIcon style={{ padding: '2px' }} /> : 5}
                    </Box>
                </Tooltip>
                <Box width={'81%'} >
                    <Box className={classes.devicesLabel}>
                        Ganancia
                    </Box>
                    <Box >
                        <Box className={classes.input} >
                            <TextField
                                fullWidth
                                type={"number"}
                                name='g'
                                // label={'Agregue un valor'}
                                size='small'
                                variant='outlined'
                                value={stateForm?.g ? stateForm?.g : ''}
                                onChange={handleChange}
                                color='primary'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 6 */}
            <Box display={'flex'} >
                <Tooltip
                    title='Nuevo tooltip'
                    arrow
                    placement='right'
                >
                    <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: true })}  >
                        {false ? <DoneIcon style={{ padding: '2px' }} /> : 6}
                    </Box>
                </Tooltip>
                <Box width={'81%'} >
                    <Box className={classes.devicesLabel}>
                        Offset
                    </Box>
                    <Box >
                        <Box className={classes.input} >
                            <TextField
                                fullWidth
                                type={"number"}
                                name='o'
                                // label={'Agregue un valor'}
                                size='small'
                                variant='outlined'
                                value={stateForm?.o ? stateForm?.o : ''}
                                onChange={handleChange}
                                color='primary'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}
export default MbsFilterOptions





