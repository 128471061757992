import { useEffect, useState } from 'react';
import DynamicGauge from './DynamicGauge'
import {
    Box, makeStyles, CircularProgress
} from '@material-ui/core';
import { Alert } from '@mui/material';
//Servicios
import { helperComponent } from '../../DynamicDashboard/helpers';
import { getInstantV2, getHistoryV2 } from '../../../services/meters';
//helpers
import { chekUrlPublic } from '../../DynamicDashboard/helpers';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '90%',
        overflow:'hidden'
    },
    containerDisabled: {
        pointerEvents: 'none',
        opacity: 0.5,
        filter: 'grayscale(100%) blur(6px)',
    },
    alert: {
        width: '100%',
        position: 'absolute',
    }
}));

export default function ContainerDynamicGauge({ id, index, title, color, config_key, variable, min, max, positions, content, refresh }) {
    const classes = useStyles();
    const [lastData, setLastData] = useState(0);
    const [timestamp, setTimestamp] = useState('');
    const [loading, setLoading] = useState(true);
    const [unityConfig, setUnityConfig] = useState({});
    //Estados alert.
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: '',
        type: 'error'
    })

    useEffect(() => {
        let intervalId = {}
        const getData = async () => {
            setClose(false)
            setLoading(true)
            try {
                const res = await fetchData(config_key);
                let value = res
                setLastData(value)
            }
            catch (error) {
                setClose(true)
                let msg = 'Ocurrio un error inesperado.'
                if (error?.response?.data?.msg) {
                    msg = error?.response?.data?.msg
                }
                setMsg({
                    msg: msg,
                    type: 'error'
                })
            }
            finally {
                if (content && chekUrlPublic()) {
                    const numeroRandom = Math.floor(Math.random() * 4) + 1;
                    setTimeout(() => {
                        setLoading(false)
                    }, numeroRandom * 1000)
                }
                else {
                    setLoading(false)
                }
            }
        }
        getData()

        //Componente gauge se actauliza automaticamente independientemente de el dashboard cada 5 minutos.
        intervalId = setInterval(() => getData(), 300000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line 
    }, [config_key, id, refresh])

    //Funcion encargada de crear la url segund el configKey
    const fetchData = async (config_key) => {
        //me fijo si es url instantaneos o historicos
        let key = config_key.split('-')[2]
        let objDinamicData = 0
        let obj = returnVariable()
        let range = config_key.split('-')[3]

        if (key === 'VALIDATE_INSTANT') {
            //funcion q aplica la unidad al gauge dinamica desde el catalogo
            if (content && chekUrlPublic()) {
                objDinamicData = findDataInstant(content.data)
            }
            else {
                let granularity = config_key.split('-')[4].split('_')[0]
                let url = `filter=${id}&range=${range}&granularity=${granularity}`;
                url += `&fields=${obj.fields}`;
                url += `&response_format=v2`;
                let res = await getInstantV2(url);
                objDinamicData = findDataInstant(res.data[0].data)
            }
        }
        if (key === 'VALIDATE_HISTORY') {
            let fase = config_key?.split('-')[1]?.split('_')[3]?.toLowerCase()
            let showPhase = ''

            if (fase === 'r' || fase === 's' || fase === 't') {
                showPhase = `&showPhase=true`
            }

            if (content && chekUrlPublic()) {
                let claves = Object.keys(content.data)
                let key = claves[0]
                let element = content.data[key][0]
                let value = element?.valor
                let timestamp = element?.timestamp
                setTimestamp(timestamp)
                objDinamicData = value.toFixed(2)
            }
            else {
                let period = config_key.split('-')[4]
                let url = `filter=${id}&range=${range}&period=${period}`;
                url += `&fields=${obj.fields}`;
                url += `${showPhase}&response_format=v2`;
                let res = await getHistoryV2(url);
                let claves = Object.keys(res.data[0].data)
                let key = claves[0]
                let element = res.data[0].data[key][0]
                let value = element?.valor
                let timestamp = element?.timestamp
                setTimestamp(timestamp)
                objDinamicData = value.toFixed(2)
            }
        }
        return objDinamicData
    }

    //funcion para dar las opciones segun la variable seleccionada.
    const returnVariable = () => {
        let resObj = {
            variable: '',
            fields: '',
        }
        Object.entries(helperComponent('DASHBOARDS_GAUGE')).forEach(([clave, valor]) => {
            let kEY_CONFIG = config_key.split('-')[1]
            let KEY_CATALOGUE = valor.value.split('-')[0]
            if (kEY_CONFIG === KEY_CATALOGUE) {
                setUnityConfig(
                    {
                        show_unit: valor.show_unit,
                        base_unit: valor?.base_unit
                    })
                resObj.variable = valor.value?.split('-')[0]
                resObj.fields = valor.value?.split('-')[1]
            }
        })
        return resObj
    }

    //Funcion encargada de buscar los ultimos datos para recrear la animacion de el gauge.
    const findDataInstant = (data) => {
        let claves = Object.keys(data)
        let arry = data[claves[0]]
        let lastData = arry[arry.length - 1]
        setTimestamp(lastData?.timestamp)
        return lastData.valor
    }

    return (
        <Box className={classes.root} >
            {/* Alert error */}
            {close &&
                <>
                    <Alert className={classes.alert} severity={msg.type}>{msg.msg}</Alert>
                    <span className={classes.containerDisabled}>
                        <DynamicGauge
                            id={id?.toString() + index}
                            title={title}
                            color={color}
                            min={min}
                            max={max}
                            value={lastData}
                            timestamp={timestamp}
                            descripcion={variable}
                            unityConfig={unityConfig}
                            positions={positions}
                        />
                    </span>
                </>

            }

            {loading ?
                <Box height={'100%'} display='flex' justifyContent='center' alignItems={'center'} >
                    <CircularProgress />
                </Box>
                :
                <>
                    {!close &&
                        < DynamicGauge
                            id={id?.toString() + index}
                            title={title}
                            color={color}
                            min={min}
                            max={max}
                            value={lastData}
                            timestamp={timestamp}
                            descripcion={variable}
                            unityConfig={unityConfig}
                            positions={positions}
                        />}
                </>
            }
        </Box>
    )
}

