import { Box, CircularProgress, Dialog, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import CancelIcon from '@mui/icons-material/Cancel';
import { Pagination, Stack } from "@mui/material";

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiDialog-container': {
            '@media (max-width: 1100px)': {
                margin: '0px 0px 0 0',
            }
        },
        '& .MuiDialog-paper': {
            margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
            color: '#423d3dde',
            maxHeight: '100vh',
            '@media (max-width: 900px)': {
                width: '100%',
                height: '100%',
            }
        }
    },
    title: {
        padding: theme.spacing(1, 0, 1, 0),
        color: '#5d5959ed',
        fontSize: 15,
        marginRight: 5,
        '@media (max-width: 900px)': {
            fontSize: 12,
        }
    },
    table: {
        marginTop: 10,
        padding: 5,
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(0.25),
        },
    },
    header: {
        background: theme.palette.primary.main,
    },
    headerCell: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    noResultsBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px', // Ajusta esta altura según sea necesario
        textAlign: 'center',
    },
}));

export function RecordsDialog({ recordsModalOpen, handleCloseRecordsModal, selectedAlertRecords, records, handleChangePaginator, loading, totalPages }) {
    const classes = useStyles();
    const labelsTableHeader = ["Fecha de creación", "Notificado", "Estado", "Observaciones", "Resultado"];

    const returnStatus = (status) => {
        switch (status) {
            case 'OK':
                return "Procesada Correctamente"
            case 'ERROR':
                return "Error al procesar la alerta"
            case 'PENDING':
                return "Procesamiento pendiente"
            case 'WARNING':
                return "Advertencia"
            case 'UPDATE_TREE':
            return "Actualización de criterios"            
            default:
                return "-"
        }
    }

    const returnResultOfRecord = (result) => {
        if(result === true){
            return "Positivo"
        }else if(result === false){
            return "Negativo"
        }else{
            return '-'
        }
    }

    return (
        <Dialog
            className={classes.dialog}
            open={recordsModalOpen}
            onClose={handleCloseRecordsModal}
            aria-labelledby='form-dialog-title'
            maxWidth='xl'
            fullWidth
        >
            <Box>
                <Box style={{ borderBottom: `solid 1px ${'#47a6db'}`, backgroundColor: 'white' }}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    pt={1}
                    pb={1}
                >
                    <IconButton
                        className={classes.iconCLoseButton}
                        onClick={handleCloseRecordsModal}
                    >
                        <CancelIcon />
                    </IconButton>
                </Box>

                <Box className={classes.title} style={{ borderBottom: `solid 1px ${'#47a6db'}`, width: '100%', textAlign: 'center' }}>
                    Historial de procesamiento de "{selectedAlertRecords?.name}"
                </Box>

                {loading && (
                    <Box display="flex" justifyContent="center" marginTop={10} marginBottom={10}>
                        <CircularProgress />
                    </Box>
                )}

                {!loading && <>
                    {records?.results?.length > 0 ? <TableContainer component={Paper}>
                        <Table
                            size="small"
                            aria-label="Historial de alerta"
                            className={classes.table}
                        >
                            <TableHead>
                                <TableRow className={classes.header}>
                                    {labelsTableHeader?.map(label => {
                                        return (
                                            <TableCell key={label} align="center" className={classes.headerCell}>
                                                {label}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {records?.results?.map(record => {
                                    return (
                                        <TableRow key={record.id} hover>
                                            <TableCell align="center">
                                                {record.created_format}
                                            </TableCell>
                                            <TableCell align="center">
                                                {record.notified ? 'Sí' : 'No'}
                                            </TableCell>
                                            <TableCell align="center">
                                                {returnStatus(record.status)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {record.observations ?? '-'}
                                            </TableCell>
                                            <TableCell align="center">
                                                {returnResultOfRecord(record.result)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> :
                        <Box className={classes.noResultsBox}>
                            No hay resultados.
                        </Box>}
                </>}

                <Box style={{ borderTop: `solid 1px ${'#47a6db'}`, backgroundColor: 'white' }} display={'flex'} justifyContent={'center'} pt={1} pb={1}>
                    <Stack>
                        <Pagination page={records?.page} onChange={handleChangePaginator} count={totalPages} />
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    )
}