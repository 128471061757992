import React, { useEffect, useState } from 'react';
import { format, startOfDay } from 'date-fns';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import TuneIcon from '@material-ui/icons/Tune';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import Chart from './Chart';
import Container from '../../Elements/Container';
import ErrorAlert from '../../common/ErrorAlert';
import TopNav from '../../common/TopNav';
import EventsCard from './EventsCard';
import Filter from './Filter';
import { getEvents } from '../../../services/meters';

const PAGEsIZEbACK = 500;

const useStyles = makeStyles(theme => ({
  btn: {
    margin: theme.spacing(2, 1),
    '& .MuiButton-label': {
      textTransform: 'none',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  devicesText: {
    marginLeft: theme.spacing(2),
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '200px',
    },
  },
  tuneIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  pagination: {
    margin: 'auto',
  },
}));
function Events() {
  const classes = useStyles();

  // Variables de estado
  const [filterPanel, setFilterPanel] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    dispositivos: [],
    showAlarms: true,
    showConnectivityOutages: true,
    quickDateOption: 'HOY',
    showElectricOutages: true,
    dateFrom: new Date(),
    dateTo: new Date(),
  });
  const [loading, setLoading] = useState(false)
  const [eventsData, setEventsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [severity, setSeverity] = useState('error');
  
  const fetchEvents = async url => {
    try {
      setLoading(true);
      const res = await getEvents(url);
      setEventsData(res.data);
      setErrorMessage('');
      setLoading(false)
    }
    catch (error) {
      if (error.response) {
        // Considero que un 404 implica que no hay eventos por lo tanto éxito
        if (error.response.status === 404) {
          setEventsData({ page: [] })
          // No es considerado un error no tener evento
          setSeverity('success');
        }
        else {
          setSeverity('error');
        }
        setErrorMessage(`${error.response.data.msg}.`);
      }
      else {
        setSeverity('error');
        setErrorMessage(`Ocurrió un error inesperado`);
      }
    }
    setLoading(false);
  };

  const handleSubmit = (e, filter) => {
    e.preventDefault();
    if (filter !== filterOptions) {
      // Borro los datos solo en caso de que haya habido cambios, así muestro el <CircularProgress/>
      setEventsData([]);
      //Oculto el mensaje de error en caso de estar mostrando uno
      setErrorMessage('');
    }
    //Cierro el panel de Filtros
    setFilterPanel(false);
    setFilterOptions(filter);
  };

  useEffect(() => {
    const { dispositivos, dateFrom, dateTo, showAlarms, showConnectivityOutages, showElectricOutages } = filterOptions;

    // Si tengo un dispositivo seleccionado
    if (dispositivos.length > 0) {
      //Armo el query string
      let url = ''
      dispositivos.forEach((node, index) => {
        if (index > 0) {
          url += '&';
        }
        url += `filter=${node.id}`;
      })
      url += `&alarmas=${showAlarms}&conectividad=${showConnectivityOutages}&cortes=${showElectricOutages}`;
      url += `&from=${dateFrom.getDate()}/${dateFrom.getMonth() + 1}/${dateFrom.getFullYear()}`;
      url += `&to=${dateTo.getDate()}/${dateTo.getMonth() + 1}/${dateTo.getFullYear()}`;
      url += `&page=1`;
      url += `&per_page=${PAGEsIZEbACK}`;
      fetchEvents(url);
    }
  }, [filterOptions]);

  // Funciones
  const togglePanel = () => {
    setFilterPanel(!filterPanel);
  };

  const handleClosePanel = () => {
    setFilterPanel(false);
  };

  //Se deshabilita momentaneamente.
  // const handlePageChange = (event, value) => {
  //   // Si cambio de página vuelvo a solicitar datos al back
  //   if (value !== eventsData.page_number) {
  //     //TODO Optimizar código repetido.
  //     // Borro los datos así muestro el <CircularProgress/>
  //     setEventsData([]);
  //     const { dispositivos, dateFrom, dateTo, showAlarms, showConnectivityOutages, showElectricOutages } = filterOptions;

  //     //Armo el query string
  //     let url = ''

  //     dispositivos.forEach((set, index) => {
  //       if (index > 0) {
  //         url += '&';
  //       }
  //       url += `filter=${set.node.id}`;
  //     })

  //     url += `&alarmas=${showAlarms}&conectividad=${showConnectivityOutages}&cortes=${showElectricOutages}`;

  //     url += `&from=${dateFrom.getDate()}/${dateFrom.getMonth() + 1}/${dateFrom.getFullYear()}`;
  //     url += `&to=${dateTo.getDate()}/${dateTo.getMonth() + 1}/${dateTo.getFullYear()}`;
  //     url += `&page=${value}`;
  //     url += `&per_page=${PAGEsIZEbACK}`;
  //     // console.log('url', url);
  //     fetchEvents(url);
  //   }
  //   setPage(value);
  // };

  return (
    <>
      <TopNav titulo='Análisis' subtitulo='| Eventos'>
        <Grid container justifyContent='flex-end'>
          <Button
            className={classes.btn}
            type='button'
            onClick={togglePanel}
            size='small'
            startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
          >
            | Filtrar
          </Button>
        </Grid>
      </TopNav>

      <Dialog
        open={filterPanel}
        onClose={handleClosePanel}
        aria-labelledby='form-dialog-title'
        maxWidth='lg'
        fullWidth
        disableScrollLock
      >
        <DialogTitle id='form-dialog-title'>Filtrar</DialogTitle>
        <DialogContent>
          <Filter onSubmit={handleSubmit} filterOptions={filterOptions} setFilterPanel={setFilterPanel} />
        </DialogContent>
      </Dialog>
      <Container>
        {/* Si no tengo ningun dispositivo seleccionado muestro mensaje bienvenida */}
        {filterOptions.dispositivos.length === 0 &&
          <Alert className={classes.alert} severity='info'>
            Bienvenido a Eventos. En esta sección podrá consultar la información de eventos de sus dispositivos. Para
            comenzar haga click en el botón
            <span className={classes.tuneIcon}>
              <TuneIcon />
            </span>
            , ingrese los parámetros y haga click en CONSULTAR.
          </Alert>
        }

        {/* Si tengo un error lo muestro */}
        {errorMessage !== '' && (
          <Box className={classes.alert}>
            <ErrorAlert setErrorMessage={setErrorMessage} message={errorMessage} severity={severity} />
          </Box>
        )}

        {/* Si tengo al menos un dispositivo seleccionado y no tengo ningun error muestro:
        - Dispositivos a graficar
        - Eventos del XX/XX/XXXX al XX/XX/XXXX 
        */}
        {filterOptions.dispositivos.length > 0 &&
          <Box>

            <Box display='flex' justifyContent='flex-start' alignItems='center' flexWrap={'wrap'}>
              <p className={classes.devicesText}>Dispositivos a graficar:</p>
              {filterOptions?.dispositivos?.map(node => (
                <Chip
                  className={classes.chip}
                  key={node.id}
                  icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                  label={node.nombre}
                  clickable
                  color="primary"
                  onClick={() => setFilterPanel(true)}
                />
              ))}
            </Box>
            <p className={classes.devicesText}>Eventos del {format(filterOptions.dateFrom, 'dd/MM/yyyy')} {startOfDay(filterOptions.dateFrom).toISOString() !== startOfDay(filterOptions.dateTo).toISOString() ? `al ${format(filterOptions.dateTo, 'dd/MM/yyyy')}` : ''}</p>
          </Box>

        }
        {/* Si tengo dispositivos seleccionados, no tengo ningun error y no tengo eventos significa que está cargando. */}
        {!eventsData.page ?
          <>
            {/* Si estoy cargando muestro el spinner */}
            {
              loading &&
              <Box height={'300px'} display={'flex'} alignItems={'center'} className={classes.circular}>
                <CircularProgress />
              </Box>
            }
          </>
          :
          <>
            <Chart eventsData={eventsData} filterOptions={filterOptions} />
            <Grid container >
              {/* //Se deshabilita momentaneamente. */}
              {/* <Pagination
                className={classes.pagination}
                count={eventsData.page_total}
                page={page}
                onChange={handlePageChange}
              /> */}
              <EventsCard title={'Alarmas más recientes'} backPage={eventsData.page} pageSizeBack={PAGEsIZEbACK} />
            </Grid>
          </>
        }
      </Container >

    </>
  );
}

export default Events;
