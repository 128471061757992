import { useContext, useState } from 'react';
// @material-ui/core
import { CircularProgress, CssBaseline, makeStyles } from '@material-ui/core';
// rrd
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// components
import Reports from './components/AlertsAndReports/Reports/Reports';
import Auditor from './components/Auditor/Auditor';
import Bill from './components/Bill/Bill';
import SimpleCalculator from './components/SimpleCalculator/SimpleCalculator';
import Conciliation from './components/Conciliation/Conciliation';
import Control from './components/Control/Control';
import Ecological from './components/Dashboards/Ecological/Ecological';
import State from './components/Dashboards/State/State';
import Summary from './components/Dashboards/SumaryDevices/Summary';
import SummaryLocation from './components/Dashboards/SummaryLocation/SummaryLocation';
import DemoUser from './components/DemoUser/DemoUser';
import Devices from './components/Devices/Devices';
import DashboardContainer from './components/DynamicDashboard/DashboardContainer';
import DashboardContainerPublic from './components/DynamicDashboard/DashboardContainerPublic';
import Events from './components/AlertsAndReports/Events/Events';
import Heatmap from './components/Heatmap/Heatmap';
import Historical from './components/Historical/Historical';
import Instant from './components/Instant/Instant';
import Landing from './components/Landing';
import Login from './components/Login/Login';
import Map from './components/Map/Map';
import PageNotFound from './components/PageNotFound';
import Plant from './components/Plant/Plant';
import Quality from './components/Quality/Quality';
import RatesSettings from './components/Rates&Contracts/RatesSettings';
import ClientConfig from './components/Settings/ClientConfig';
import ContractConfig from './components/Settings/ContractConfig';
import QualityConfig from './components/Settings/QualityConfig';
import Settings from './components/Settings/Settings';
import SubaccountsConfig from './components/Settings/SubaccountsConfig';
import StandBy from './components/StandBy/StandBy';
import SubBill from './components/SubBill/SubBill';
import Unauthorized from './components/Unauthorized';
import Drawer from './components/common/Drawer';
import NotificationSettings from './components/NotificationSettings/NotificationSettings';
import WrapperDiagram from './components/DynamicDiagram/Store/Wrapper';
import Alerts from './components/AlertsAndReports/Alerts/Alerts';
import PLC_AUTOMATE_24V from './components/plc_automate_24_v/Plc_automate_24_v';
// helpers
import ProtectedRoute from './helpers/ProtectedRoute';
import { chekUrlPublic } from './components/DynamicDashboard/helpers';
// context
import UserContext from './context/UserContext';
// services
import SummaryGeneral from './components/Dashboards/SummaryGeneral/SummaryGeneral';
import {
  deleteDefaultDeviceFromLocalStorage,
  deleteFilterOptionsFromLocalStorage,
  deleteTagsFromLocalStorage,
  deleteNodesFromLocalStorage,
  deleteNodesListFromLocalStorage,
  deleteTokenFromLocalStorage,
  deleteUserInfoFromLocalStorage,
} from './services/auth';
import { unregisterServiceWorkers } from './service_worker/helpers';
import AlarmsOnDevices from './components/AlertsAndReports/AlarmsOnDevices/AlarmsOnDevices';
import ControlPanelMeters from './components/ControlPanelMeters/ControlPanelMeters';
import { IntegrationsConfig } from './components/Settings/IntegrationsConfig';
import RouteListener from './RouteListener';
import Solar from './components/Dashboards/Solar/Solar';
const useStyles = makeStyles(theme => ({
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
}));


function App(props) {
  const classes = useStyles();
  const { plan, setPlan } = useContext(UserContext);
  const [user, setUser] = useState(false);
  const [isLogingOut, setIsLogingOut] = useState(false);
  //Renderiza nuevamente el Drawer
  const [refresh, setRefresh] = useState(true);

  const handleLogout = e => {
    setIsLogingOut(true);
    setUser(false);
    setPlan({});
    //Elimino service worker.
    if (plan?.info?.email) {
      unregisterServiceWorkers(plan?.info?.email)
    }
    // clean local storage
    deleteTokenFromLocalStorage();
    deleteUserInfoFromLocalStorage();
    deleteDefaultDeviceFromLocalStorage();
    deleteNodesFromLocalStorage();
    deleteNodesListFromLocalStorage();
    deleteTagsFromLocalStorage();
    deleteFilterOptionsFromLocalStorage();
  };

  if (plan === undefined)
    return (
      <>
        <div className={classes.circular}>
          <CircularProgress />
        </div>
      </>
    );

  return (
    <>
      <CssBaseline />
      <Router>
        <RouteListener user={user} plan={plan} />
        {!chekUrlPublic() && <Drawer user={user} handleLogout={handleLogout} refresh={refresh} />}
        <Switch>

          <Route
            path='/dashboard-publico/:params_id'
            render={props => (
              <DashboardContainerPublic />
            )}
          />
          <Route
            path="/demo/:params_id"
            render={props => (
              <DemoUser
                {...props}
                user={user}
                setUser={setUser}
                setIsLogingOut={setIsLogingOut}
                setRefresh={setRefresh}
              />
            )}
          />
          <Route
            path='/login'
            render={props => (
              <Login
                {...props}
                user={user}
                setUser={setUser}
                setIsLogingOut={setIsLogingOut}
                setRefresh={setRefresh}
              />
            )}
          />
          <ProtectedRoute
            path='/configuracion-cuenta'
            hasAccess={plan?.access?.configuracion_usuario_cuenta}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <ClientConfig handleLogout={handleLogout} />}
          />
          <ProtectedRoute
            path='/configuracion-subcuentas'
            hasAccess={plan?.access?.configuracion_medidores_compartidos}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={SubaccountsConfig}
          />
          <ProtectedRoute
            path='/configuracion-notificaciones'
            hasAccess={plan?.access?.configuracion_medidores_compartidos}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={NotificationSettings}
          />
          <ProtectedRoute
            path='/configuracion-integraciones'
            hasAccess={plan?.access?.configuracion_integraciones}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={IntegrationsConfig}
          />
          <ProtectedRoute
            path='/configuracion'
            hasAccess={plan?.access?.configuracion}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Settings}
          />
          <ProtectedRoute
            path='/configuracion-calidad'
            hasAccess={plan?.access?.configuracion_analisis_calidadenergia}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={QualityConfig}
          />
          <ProtectedRoute
            path='/configuracion-facturacionContrato'
            hasAccess={plan?.access?.configuracion_facturacion_contrato_tarifa}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={ContractConfig}
          />
          <ProtectedRoute
            path='/configuracion-subFacturacionContrato'
            hasAccess={plan?.access?.configuracion_subdivision}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={RatesSettings}
          />
          <ProtectedRoute
            path='/configuracion-organizacion'
            hasAccess={plan?.access?.configuracion_organizacion_dispositivos}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Devices}
          />
          <ProtectedRoute
            path='/configuracion-etiquetas'
            hasAccess={plan?.access?.configuracion_organizacion_dispositivos}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Devices}
          />
          <ProtectedRoute
            path='/mapa'
            hasAccess={plan?.access?.dashboards_mapa}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Map}
          />
          <ProtectedRoute
            path='/estado'
            hasAccess={plan?.access?.dashboards_estado}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={State}
          />
          <ProtectedRoute
            path='/planta'
            hasAccess={plan?.access?.dashboards_planta}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Plant}
          />
          <ProtectedRoute
            path='/resumen_localizacion'
            hasAccess={plan?.access?.dashboard_localizacion}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={SummaryLocation}
          />
          <ProtectedRoute
            path='/resumen_general'
            hasAccess={plan?.access?.dashboard_empresa}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={SummaryGeneral}
          />
          <ProtectedRoute
            path='/resumen'
            hasAccess={plan?.access?.dashboards_resumen}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Summary}
          />
          <ProtectedRoute
            path='/ecologico'
            hasAccess={plan?.access?.dashboard_ecologico}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Ecological}
          />
            <ProtectedRoute
            path='/solar'
            hasAccess={plan?.access?.dashboard_solar}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Solar}
          />
          <ProtectedRoute
            path='/crear-personalizado'
            hasAccess={plan?.access?.dashboards_creacion}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <DashboardContainer setRefresh={setRefresh} />}
          />
          <ProtectedRoute
            path='/personalizado/:params_id'
            hasAccess={plan?.access?.dashboards_creacion}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => (
              <DashboardContainer personalized={true} setRefresh={setRefresh} />
            )}
          />
          <ProtectedRoute
            path='/factura'
            hasAccess={plan?.access?.facturacion_factura}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <Bill showFilter={true} />}
          />
          <ProtectedRoute
            path='/show-factura/:id'
            hasAccess={plan?.access?.facturacion_factura}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <Bill showFilter={false} />}
          />
          <ProtectedRoute
            path='/sub_factura/'
            hasAccess={plan?.access?.facturacion_subdivision}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={SubBill}
          />
          <ProtectedRoute
            path='/calculador/'
            hasAccess={plan?.access?.facturacion_factura_free}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={SimpleCalculator}
          />
          <ProtectedRoute
            path='/conciliacion/'
            hasAccess={plan?.access?.facturacion_conciliacion}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Conciliation}
          />
          <ProtectedRoute
            path='/sub_factura/'
            hasAccess={plan?.access?.facturacion_subdivision}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={SubBill}
          />
          <ProtectedRoute
            path='/auditor/'
            hasAccess={plan?.access?.auditor}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Auditor}
          />
          <ProtectedRoute
            path='/instantaneos'
            hasAccess={plan?.access?.analisis_instantaneos}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Instant}
          />
          <ProtectedRoute
            path='/historicos'
            hasAccess={plan?.access?.analisis_historicos}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Historical}
          />
          <ProtectedRoute
            path='/calidad'
            hasAccess={plan?.access?.analisis_calidad}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Quality}
          />
          <ProtectedRoute
            path='/stand-by'
            hasAccess={plan?.access?.analisis_standby}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={StandBy}
          />
          <ProtectedRoute
            path='/eventos'
            hasAccess={plan?.access?.analisis_eventos}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Events}
          />
          <ProtectedRoute
            path='/heatmap'
            hasAccess={plan?.access?.analisis_heatmap}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Heatmap}
          />
          <ProtectedRoute
            path='/informes'
            hasAccess={true}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Reports}
          />
          <ProtectedRoute
            path='/control'
            hasAccess={plan?.access?.control}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Control}
          />
          <ProtectedRoute
            path='/alarmas-en-dispositivos'
            hasAccess={plan?.access?.alertas_y_reportes_alarmas}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={AlarmsOnDevices}
          />
          <ProtectedRoute
            path='/configuracion-dispositivos'
            hasAccess={true}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={ControlPanelMeters}
          />
          <ProtectedRoute
            path='/alerts'
            hasAccess={true}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <Alerts />}
          />
          <ProtectedRoute
            path='/alert/:params_id'
            hasAccess={true}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <WrapperDiagram componentType={'ALERTS_VARIABLES'} />}
          />
          <ProtectedRoute
            path='/create_alert'
            hasAccess={true}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <WrapperDiagram componentType={'ALERTS_VARIABLES'} />}
          />
          <ProtectedRoute
            path='/plc_list'
            hasAccess={true}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <PLC_AUTOMATE_24V />}
          />
          <ProtectedRoute
            path='/plc/:params_id'
            hasAccess={true}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={() => <WrapperDiagram componentType={'Automate-24V-4M'} />}
          />
          <ProtectedRoute
            exact
            path='/'
            hasAccess={true}
            user={user}
            isLogingOut={isLogingOut}
            setIsLogingOut={setIsLogingOut}
            component={Landing}
          />
          <Route path='/unauthorized' component={Unauthorized} />
          <Route path='*' component={PageNotFound} />
        </Switch>
        {/* <Footer /> */}
      </Router>
    </>
  );
}
export default App;
