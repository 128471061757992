import React from 'react';
import { IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

function ErrorAlert({ setErrorMessage, message, severity = 'error', disabledClose }) {
  return <Alert
    severity={severity}
    action={
      <>
        {
          disabledClose ?
            <>
            </>
            :
            <IconButton
              aria-label='close'
              size='small'
              onClick={() => {
                setErrorMessage('')
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
        }
      </>
    }
  >
    {message}
  </Alert>;
}

export default ErrorAlert;
