import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import { mixColors } from '../../helpers/common';
//Helpers
import { formattingSeries } from './helpers';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    // margin: theme.spacing(2),
    '@media (max-width: 700px)': {
      margin: theme.spacing(0),
    },
  },
}));

export default function DualChart({
  chartData,
  chartData2,
  chartOptions,
  zoomBar,
  ky,
  auxHeight,
  granularityDinamicDash,
  tz
}) {
  const classes = useStyles();

  useEffect(() => {
    const { variable, variable2, showSecondary } = chartOptions;
    let chart = am4core.create(`instantaneos${ky ? ky : ''}`, am4charts.XYChart);
    //Linea para q el logo de amchard no aparezca
    if (chart?.logo) {
      chart.logo.disabled = true;
    }

    am4core.useTheme(am4themes_animated);
    chart.fontSize = 11;
    chart.language.locale = am4lang_es_ES;

    // //Configuro el timezone (GMT) de el cliente.
    // chart.dateFormatter.timezone = res?.data?.timezone

    chart.dateFormatter.timezone = tz
    //Deshabilitó la opción de exportar pdf con la propiedad q desactiva el zoom.
    if (!zoomBar) {
      //Configuro la exportación de datos
      chart.exporting.menu = new am4core.ExportMenu();
      //Es requisito tener cargado a chat.data aunque yo luego uso series specific data
      chart.exporting.adapter.add('data', function (data, target) {
        // Assemble data from series
        const myData = [];
        chart.series.each(function (series) {
          for (var i = 0; i < series.data.length; i++) {
            series.data[i].name = series.name;
            myData.push(series.data[i]);
          }
        });
        return { data: myData };
      });

      // Modifico el menu de exportación
      chart.exporting.menu.items = [
        {
          label: '...',
          menu: [
            { type: 'pdf', label: 'PDF' },
            { type: 'csv', label: 'CSV' },
            { type: 'xlsx', label: 'XLSX' },
            { type: 'print', label: 'Imprimir' },
          ],
        },
      ];
    }
    // chart.exporting.menu.align = "left";
    // chart.exporting.menu.verticalAlign = "bottom";


    // chart.paddingRight = 20;
    if (!zoomBar) {
      chart.legend = new am4charts.Legend();
    }

    chart.dateFormatter.inputDateFormat = 'yyyy-MM-ddTHH:mm:ssZ';

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.baseInterval = {
      timeUnit: 'minute',
      count: 1,
    };
    dateAxis.tooltipDateFormat = 'dd/MM/yyyy HH:mm';

    // El datepicker devuelve la fecha pero además contiene la hora. Como quiero asegurarme que sean las 00 y las 23 59 debo configurarlo a mano.
    const d1 = new Date(chartOptions.selectedInitialDate);
    d1.setHours(0, 0, 0, 0);
    const d2 = new Date(chartOptions.selectedEndDate);
    d2.setHours(23, 59, 0, 0);
    dateAxis.min = d1.getTime();
    dateAxis.max = d2.getTime();

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Configuración para admitir gráfico secundario
    valueAxis.tooltip.disabled = true;
    valueAxis.zIndex = 1;
    valueAxis.renderer.baseGrid.disabled = true;

    valueAxis.renderer.inside = false;
    valueAxis.height = showSecondary
      ? am4core.percent(60)
      : am4core.percent(100);
    valueAxis.renderer.labels.template.verticalCenter = 'bottom';
    valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
    valueAxis.renderer.fontSize = '0.8em';
    valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
    dateAxis.renderer.grid.template.strokeWidth = 1;
    valueAxis.renderer.grid.template.strokeWidth = 1;

    // Configuración gráfico secundario
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.tooltip.disabled = true;
    // this makes gap between panels
    valueAxis2.marginTop = 20;
    valueAxis2.renderer.baseGrid.disabled = true;
    valueAxis2.renderer.inside = true;
    valueAxis2.height = am4core.percent(40);
    valueAxis2.zIndex = 3;
    valueAxis2.renderer.labels.template.verticalCenter = 'bottom';
    valueAxis2.renderer.labels.template.padding(2, 2, 2, 2);
    //valueAxis2.renderer.maxLabelPosition = 0.95;
    valueAxis2.renderer.fontSize = '0.8em';

    let interfaceColors = new am4core.InterfaceColorSet();
    if (showSecondary) {
      valueAxis.renderer.gridContainer.background.fill = interfaceColors.getFor('alternativeBackground');
      valueAxis2.renderer.gridContainer.background.fill = interfaceColors.getFor('alternativeBackground');
    }

    valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;
    valueAxis2.renderer.grid.template.strokeWidth = 1;
    //Barra de zoom de movimiento del grafico condicion para q no exista
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    //Si la condicion es true deshabilito la barra de movimiento del grafico
    if (zoomBar) {
      chart.scrollbarX.disabled = true;
    }

    const assignSerieValues = serie => {
      const node = serie?.node;
      const tags = serie?.tags;
      const nodeName = node?.nombre;
      const tagName = tags?.length > 0 ? tags[0]?.nombre : '';
      const nodeColor = node?.color;
      const tagColor = tags?.length > 0 ? tags[0]?.color : '';
      const serieName = tagName ? `${nodeName} + ${tagName}` : nodeName;
      const chartColor = mixColors(nodeColor, tagColor);
      return { serieName, chartColor };
    };

    //Funcion que segun la granularidad q selecciono devuelvo una configuracion para la separacion entre puntos,
    // en este caso es 3 veces mas su valor no unir puntos.
    const gapCalc = () => {
      //(estas opciones solo pueden configurarse granularidad)
      // 'tensionFase','corrienteFase','activaTotal','activaFase','reactivaTotal','reactivaFase'
      let res = 20
      if (granularityDinamicDash) {
        res = Number(granularityDinamicDash) * 4
      }
      return res
    }
    function updateChart(
      variable,
      valueAxis,
      serie,
      serieName,
      chartColor,
      phases,
      type
    ) {
      switch (variable) {

        case 'TENSION_POR_FASE':
          valueAxis.title.text = 'Tensión por fase';
          for (let i = 0; i < phases; i++) {
            const phaseName = i === 0 ? 'R' : i === 1 ? 'S' : 'T';
            const colorOffset = i === 0 ? '' : i === 1 ? '60' : '90';
            addSeries(
              `Fase ${phaseName} (${serieName})`,
              serie?.data?.[`V_${phaseName}`],
              chartColor + colorOffset,
              type,
              '#.##V',
              gapCalc()
            );
          }
          break;

        case 'CORRIENTE_POR_FASE':
          valueAxis.title.text = 'Corriente por fase';
          for (let i = 0; i < phases; i++) {
            const phaseName = i === 0 ? 'R' : i === 1 ? 'S' : 'T';
            const colorOffset = i === 0 ? '' : i === 1 ? '60' : '90';
            addSeries(
              `Fase ${phaseName} (${serieName})`,
              serie?.data?.[`I_${phaseName}`],
              chartColor + colorOffset,
              type,
              `#.##'A`,
              gapCalc()
            );
          }
          break;

        case 'POTENCIA_ACTIVA_TOTAL':
          valueAxis.title.text = 'Potencia activa total';
          addSeries(
            serieName,
            serie?.data?.P,
            chartColor,
            type,
            `#.##aW`,
            gapCalc()
          );
          break;

        case 'POTENCIA_ACTIVA_POR_FASE':
          valueAxis.title.text = 'Potencia activa por fase';
          phases += 1
          let nameLabelTooltipsP = ''
          let colorOffsetP = ''
          for (let i = 0; i < phases; i++) {
            let phaseName = ''
            if (i === 0) {
              phaseName = 'P_R'
              nameLabelTooltipsP = 'Fase R'
              colorOffsetP = '60'
            }
            if (i === 1) {
              phaseName = 'P_S'
              nameLabelTooltipsP = 'Fase S'
              colorOffsetP = '65'
            }
            if (i === 2) {
              phaseName = 'P_T'
              nameLabelTooltipsP = 'Fase T'
              colorOffsetP = '70'
            }
            if (i === 3) {
              phaseName = 'P'
              nameLabelTooltipsP = 'Total'
              colorOffsetP = '90'
            }

            addSeries(
              `${nameLabelTooltipsP} (${serieName})`,
              serie.data?.[`${phaseName}`],
              chartColor + colorOffsetP,
              type,
              `#.##aW`,
              gapCalc()
            );
          }
          break;

        case 'POTENCIA_REACTIVA_TOTAL':
          valueAxis.title.text = 'Potencia reactiva total';
          addSeries(
            serieName,
            serie?.data?.Q,
            chartColor,
            type,
            `#.##aVAr`,
            gapCalc()
          );
          break;

        case 'POTENCIA_REACTIVA_POR_FASE':
          valueAxis.title.text = 'Potencia reactiva por fase';
          //Agrego mas 1 para consumir tambien el total ademas de las 3 fases son 4 elementos (R,S,T,Total)
          phases += 1
          let nameLabelTooltipsQ = ''
          let colorOffsetQ = ''
          for (let i = 0; i < phases; i++) {
            let phaseName = ''
            if (i === 0) {
              phaseName = 'Q_R'
              nameLabelTooltipsQ = 'Fase R'
              colorOffsetQ = '60'
            }
            if (i === 1) {
              phaseName = 'Q_S'
              nameLabelTooltipsQ = 'Fase S'
              colorOffsetQ = '65'
            }
            if (i === 2) {
              phaseName = 'Q_T'
              nameLabelTooltipsQ = 'Fase T'
              colorOffsetQ = '70'
            }
            if (i === 3) {
              phaseName = 'Q'
              nameLabelTooltipsQ = 'Total'
              colorOffsetQ = '90'
            }
            addSeries(
              `${nameLabelTooltipsQ} (${serieName})`,
              serie.data?.[`${phaseName}`],
              chartColor + colorOffsetQ,
              type,
              `#.##aVAr`,
              gapCalc()
            );
          }
          break;

        case 'POTENCIA_ADQUIRIDA_TOTAL':
          valueAxis.title.text = 'Potencia adquirida total';
          addSeries(
            serieName,
            serie?.data?.KW.map(item => ({ ...item, valor: item.valor * 1000 })),
            chartColor,
            type,
            '#.##aW',
            180
          );
          break;

        case 'ENERGIA_TOTAL_POR_HORA':
          valueAxis.title.text = 'Energia total por hora';
          addSeries(
            serieName,
            serie?.data?.KWH.map(item => ({ ...item, valor: item.valor * 1000 })),
            chartColor,
            type,
            '#.##aWh',
            180
          );
          break;

        case 'ENERGIA_ACTIVA_TOTAL':
          valueAxis.title.text = 'Energia activa total';
          addSeries(
            serieName,
            serie?.data?.KWH.map(item => ({ ...item, valor: item.valor * 1000 })),
            chartColor,
            type,
            '#.##aWh',
            180
          );
          break;

        case 'TEMPERATURA_TOTAL':
          valueAxis.title.text = 'Temperatura';
          addSeries(
            serieName,
            serie?.data?.TEMP,
            chartColor,
            type,
            `#.## °C`,
            180
          );
          break;

        case 'PRESION_TOTAL':
          valueAxis.title.text = 'Presión';
          addSeries(
            serieName,
            serie?.data?.PRESSURE,
            chartColor,
            type,
            `# hPa`,
            180
          );
          break;

        case 'HUMEDAD_TOTAL':
          valueAxis.title.text = 'Humedad Total';
          addSeries(
            serieName,
            serie?.data?.HUMIDITY,
            chartColor,
            type,
            `#.## '%`,
            180
          );
          break;

        case 'ENERGIA_ACTIVA_IMPORTADA_TOTAL':
          valueAxis.title.text = 'Energía activa importada Total';
          addSeries(
            serieName,
            formattingSeries(serie?.data?.KWH_I, variable, 'INSTANTANEOS'),
            chartColor,
            type,
            '#.##aWh',
            180
          );
          break;

        case 'ENERGIA_ACTIVA_EXPORTADA_TOTAL':
          valueAxis.title.text = 'Energía activa exportada total';
          addSeries(
            serieName,
            formattingSeries(serie?.data?.KWH_E, variable, 'INSTANTANEOS'),
            chartColor,
            type,
            '#.##aWh',
            180
          );
          break;

        case 'ENERGIA_ACTIVA_GENERADA_TOTAL':
          valueAxis.title.text = 'Energía activa generada total';
          addSeries(
            serieName,
            formattingSeries(serie?.data?.KWH_G, variable, 'INSTANTANEOS'),
            chartColor,
            type,
            '#.##aWh',
            180
          );
          break;

        case 'ENERGIA_ACTIVA_CONSUMIDA_TOTAL':
          valueAxis.title.text = 'Energía activa consumida total';
          addSeries(
            serieName,
            formattingSeries(serie?.data?.KWH_C, variable, 'INSTANTANEOS'),
            chartColor,
            type,
            '#.##aWh',
            180
          );
          break;

        case 'ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL':
          valueAxis.title.text = 'Energía activa autoconsumida total';
          addSeries(
            serieName,
            formattingSeries(serie?.data?.KWH_AC, variable, 'INSTANTANEOS'),
            chartColor,
            type,
            '#.##aWh',
            180
          );
          break;

        case 'POTENCIA_ACTIVA_GENERADA_TOTAL':
          valueAxis.title.text = 'Potencia activa generada total';
          addSeries(
            serieName,
            formattingSeries(serie?.data?.P_G, variable, 'INSTANTANEOS'),
            chartColor,
            type,
            '#.##aW',
            180
          );
          break;

        case 'POTENCIA_ACTIVA_CONSUMIDA_TOTAL':
          valueAxis.title.text = 'Potencia activa consumida total';
          addSeries(
            serieName,
            formattingSeries(serie?.data?.P_C, variable, 'INSTANTANEOS'),
            chartColor,
            type,
            '#.##aWh',
            180
          );
          break;

        default:
          break;
      }
    }

    chartData.forEach(serie => {
      const { chartColor, serieName } = assignSerieValues(serie);
      const phases = serie?.medidores[0]?.count_phases;
      updateChart(
        variable,
        valueAxis,
        serie,
        serieName,
        chartColor,
        phases,
        'primary'
      );
    });

    if (showSecondary) {
      chart.leftAxesContainer.layout = 'vertical';

      chartData2.forEach(serie => {
        const { chartColor, serieName } = assignSerieValues(serie);
        const phases = serie?.medidores[0]?.count_phases;
        updateChart(
          variable2,
          valueAxis2,
          serie,
          serieName,
          chartColor,
          phases,
          'secondary'
        );
      });
    }

    function addSeries(
      serieName,
      serieData,
      serieColor,
      type,
      format,
      gap = 20,
    ) {

      // Si no tengo data disponible no quiero agregar la serie
      if (!serieData)
        return;

      // const seriesId = chart.series.length + 1;
      const series = chart.series.push(new am4charts.LineSeries());

      // Crear un NumberFormatter para la serie (así me garantizo respetar las unidades)
      const numberFormatter = new am4core.NumberFormatter();
      numberFormatter.numberFormat = format;
      series.numberFormatter = numberFormatter;

      if (type === 'secondary') {
        series.yAxis = valueAxis2;
        // Como quiero que cada valueAxis tenga su unidad formateada se lo asigno individualmente
        valueAxis2.numberFormatter = numberFormatter;
      } else {
        series.yAxis = valueAxis; // Associate the series with valueAxis
        // Como quiero que cada valueAxis tenga su unidad formateada se lo asigno individualmente
        valueAxis.numberFormatter = numberFormatter;
      }

      series.connect = false;

      // gap es un parámetro con valor por defecto 15 y que para energíaTotal y adquiridaTotal es equivalente a 3h (180)
      series.autoGapCount = gap;

      series.dataFields.valueY = 'valor';
      series.dataFields.dateX = 'timestamp';

      series.data = serieData;
      series.name = serieName;
      series.stroke = am4core.color(serieColor);

      series.tooltipText = `{valueY} - ${serieName}`;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(serieColor);

      // series.tooltip.autoTextColor = false;

      const circleBullet = series.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.fill = am4core.color('#fff');

      series.minBulletDistance = 15;

      chart.scrollbarX.series.push(series);
    }
    chart.scrollbarX.strokeWidth = 1;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.strokeWidth = 0;
    chart.strokeWidth = 2;

    return () => {
      chart.dispose();
    };
    // eslint-disable-next-line
  }, [chartData, chartData2, chartOptions]);

  return (
    <Box className={classes.root}>
      <div
        id={`instantaneos${ky ? ky : ''}`}
        style={auxHeight ? { height: auxHeight } : { height: chartOptions.showSecondary ? '700px' : '500px' }}
      />
    </Box>
  );
}
