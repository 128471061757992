import { Box, makeStyles } from "@material-ui/core";
import SolarPowerOutlinedIcon from '@mui/icons-material/SolarPowerOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import { useState, useEffect, useRef } from "react";
import { utcConverter } from "../../../helpers/controllers";

const useStyles = makeStyles(theme => ({
    text: {
        fill: 'white', // Color del texto
        dominantBaseline: 'middle',
        textAnchor: 'middle',
    },
    icon: {
        position: 'absolute',
        fontSize: 80,
        color: 'white'
    },
    mainTitle: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.2),
        fontWeight: "600",
        marginLeft: theme.spacing(4),
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    dateFooter: {
        position: 'absolute',
        bottom: 10,
    }
}));

export function EnergyFlowChart({ powerData, title, subtitle, positions, marginTitle }) {
    const classes = useStyles();
    const [networkPower, setNetworkPower] = useState(powerData?.networkPower);
    const [generatorPower, setGeneratorPower] = useState(powerData?.generatorPower);
    const [consumptionPower, setConsumptionPower] = useState(powerData?.consumptionPower);
    const [circleCoords, setCircleCoords] = useState([
        { x: '80%', y: '80%', text: '', icon: HomeOutlinedIcon }, // Coordenadas del primer círculo
        { x: '20%', y: '80%', text: '', icon: ElectricMeterIcon }, // Coordenadas del segundo círculo
        { x: '50%', y: '25%', text: '', icon: SolarPowerOutlinedIcon }, // Coordenadas del tercer círculo
    ]);
    const offset = 5; // Desplazamiento en píxeles
    const [smallerChart, setSmallerChart] = useState(42);

    useEffect(() => {
        if (powerData) {
            setNetworkPower(powerData.networkPower);
            setGeneratorPower(powerData.generatorPower);
            setConsumptionPower(powerData.consumptionPower);
            setDataOnCircles();
        }
        // eslint-disable-next-line
    }, [powerData])

    useEffect(() => {
        if (positions?.w === 3) {
            setSmallerChart(true)
        } else {
            setSmallerChart(false)
        }
        // eslint-disable-next-line
    }, [positions])

    const lines = [
        // Línea horizontal entre los círculos inferiores
        {
            startX: circleCoords[0].x,
            startY: `calc(${circleCoords[0].y} + ${offset + 10}px)`,
            endX: circleCoords[1].x,
            endY: `calc(${circleCoords[1].y} + ${offset + 10}px)`,
            color: '#FFD19F',
            colorFlowLine: '#FF8500'
        },
        // Línea en forma de "L" desde el círculo inferior derecho hacia arriba
        {
            startX: `calc(${circleCoords[0].x} - ${offset}px)`,
            startY: circleCoords[0].y,
            endX: `calc(50% + ${offset + 5}px)`, // Punto medio horizontal con offset a la izquierda
            endY: circleCoords[0].y,
            color: '#95D593',
            colorFlowLine: '#1BD416'
        },
        {
            startX: `calc(50% + ${offset + 5}px)`, // Punto medio horizontal con offset a la izquierda
            startY: circleCoords[0].y,
            endX: `calc(50% + ${offset + 5}px)`, // Punto medio horizontal con offset a la izquierda
            endY: circleCoords[2].y,
            color: '#95D593',
            colorFlowLine: '#1BD416'
        },
        // línea en forma de "L" desde el círculo inferior izquierdo hacia arriba
        {
            startX: `calc(${circleCoords[1].x} + ${offset}px)`,
            startY: circleCoords[1].y,
            endX: `calc(50% - ${offset + 5}px)`, // Punto medio horizontal con offset a la derecha
            endY: circleCoords[1].y,
            color: '#95D593',
            colorFlowLine: '#1BD416'
        },
        {
            startX: `calc(50% - ${offset + 5}px)`, // Punto medio horizontal con offset a la derecha
            startY: circleCoords[1].y,
            endX: `calc(50% - ${offset + 5}px)`, // Punto medio horizontal con offset a la derecha
            endY: circleCoords[2].y,
            color: '#95D593',
            colorFlowLine: '#1BD416'
        }
    ];

    const formatUnitDynamic = (num) => {
        const absNum = Math.abs(num);
        let formattedNumber;

        if (absNum >= 1000000) {
            formattedNumber = (absNum / 1000000).toFixed(1) + ' M';
        } else {
            formattedNumber = (absNum / 1000).toFixed(1) + ' K';
        }

        if (num < 0) {
            formattedNumber = '-' + formattedNumber;
        }

        return formattedNumber;
    }

    const setDataOnCircles = () => {
        setCircleCoords(prevCoords => (
            prevCoords.map((coord, index) => {
                if (index === 0) {
                    return {
                        ...coord,
                        text: `${formatUnitDynamic(consumptionPower)}W`
                    };
                }
                else if (index === 1) {
                    return {
                        ...coord,
                        text: `${formatUnitDynamic(networkPower)}W`
                    };
                }
                else if (index === 2) {
                    return {
                        ...coord,
                        text: `${formatUnitDynamic(generatorPower)}W`
                    };
                }
                return coord;
            })
        ));
    }

    const BaseLine = ({ startX, startY, endX, endY, color }) => (
        <line
            x1={startX}
            y1={startY}
            x2={endX}
            y2={endY}
            stroke={color}
            strokeWidth="5"
        />
    );

    const FlowLine = ({ startX, startY, endX, endY, segmentLength = 20, color, index }) => {
        const lineRef = useRef(null);
        let strokeWidth = 8;

        if (index === 0 && networkPower <= 0) { // Si no hay energia de red no anima ultima linea
            color = '#FFD19F'
            strokeWidth = 0;
        }
        if ((index === 1 || index === 2 || index === 3 || index === 4) && (generatorPower <= 0)) {
            color = '#95D593'
            strokeWidth = 0;
        }
        if((index === 3 || index === 4) && networkPower > 0){
            color = '#95D593'
            strokeWidth = 0;
        }
        useEffect(() => {
            const line = lineRef.current;
            const length = line.getTotalLength();
            line.style.strokeDasharray = `${segmentLength}, ${length}`;
            line.style.strokeDashoffset = length;

            // Función para iniciar la animación
            const startAnimation = () => {
                line.style.transition = 'none';
                line.style.strokeDashoffset = length + 20;
                // Forzar reflow para reiniciar la animación
                line.getBoundingClientRect();
                requestAnimationFrame(() => {
                    line.style.transition = 'stroke-dashoffset 2s linear';
                    line.style.strokeDashoffset = '0';
                });
            };
            // Iniciar la animación
            startAnimation();
            // Añadir listener para reiniciar la animación al terminar
            line.addEventListener('transitionend', startAnimation);
            // Limpiar el listener al desmontar el componente
            return () => {
                line.removeEventListener('transitionend', startAnimation);
            };
        }, [startX, startY, endX, endY, segmentLength]); // Dependencias de useEffect

        return (
            <line
                ref={lineRef}
                x1={endX}
                y1={endY}
                x2={startX}
                y2={startY}
                stroke={color}
                strokeWidth={strokeWidth}
            />
        );
    };

    return (
        <Box style={{ width: '100%', height: '90%', }}>
            <Box mt={marginTitle ? marginTitle : 0} className={classes.mainTitle} style={{ fontSize: "14px" }} >
                {title} <span style={{ fontWeight: '400' }}>{subtitle}</span>
            </Box>

            <svg style={{ width: '100%', height: '90%', overflow: 'visible' }}>
                <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#8ac7ff" /> {/* Azul más claro */}
                        <stop offset="100%" stopColor="#3d85cc" /> {/* Azul más oscuro */}

                    </linearGradient>
                </defs>
                {/* Líneas base */}
                {lines.map((line, index) => (
                    <BaseLine
                        key={index}
                        startX={line.startX}
                        startY={line.startY}
                        endX={line.endX}
                        endY={line.endY}
                        color={line.color}
                    />
                ))}
                {/* Líneas de flujo */}
                {lines.map((line, index) => (
                    <FlowLine
                        key={index}
                        startX={line.startX}
                        startY={line.startY}
                        endX={line.endX}
                        endY={line.endY}
                        color={line.colorFlowLine}
                        segmentLength={20}
                        index={index}
                    />
                ))}
                {/* Círculos */}
                {circleCoords.map((coord, index) => (
                    <g key={index}>
                        <circle
                            cx={coord.x}
                            cy={coord.y}
                            visibility={coord.visibility}
                            r={smallerChart ? 37 : 42}
                            fill="url(#gradient)"
                        />
                        <foreignObject
                            x={`${coord.x}`}
                            y={`${coord.y}`}
                            width="120"
                            height="120"
                            transform="translate(-12, -25)"
                        >
                            <coord.icon className={classes.icon} />
                        </foreignObject>
                        <text
                            x={coord.x}
                            y={coord.y}
                            className={classes.text}
                            transform="translate(0, 13)"
                            style={smallerChart ? { fontSize: 11 } : { fontSize: 14 }}
                        >
                            {coord.text}
                        </text>
                    </g>
                ))}
            </svg>

            <Box display="flex" justifyContent="center">
                <Box display="flex" alignItems="center" className={classes.dateFooter}>Última lectura: {utcConverter(powerData?.timestamp)}</Box>                    
            </Box>
        </Box>
    )

}