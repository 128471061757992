import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
//Componentes
import Container from '../../Elements/Container';
import TopNav from '../../common/TopNav';
//Servicios.
import {
    getDiagramAlert,
    deleteDiagramAlert,
    putUpdateDiagramAlert,
    alertRecords,
} from '../../../services/diagrams';
import AlertComponent from '../../Elements/AlertComponent';
import { Alert } from '@mui/material';
import ConfirmationDialog from '../ConfirmationDialog';
import AlertPlcTable from '../../common/AlertPlcTable';
import { RecordsDialog } from './RecordsDialog';
const useStyles = makeStyles(theme => ({
    table: {
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(0.25),
        },
    },
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const Alerts = () => {
    const classes = useStyles();
    let history = useHistory();
    const [alertList, setAlertList] = useState([]);
    const labelsTableHeader = [
        'Activo',
        'Nombre de la alerta',
        'Fecha de creación',
        'Historial',
        'Editar',
        'Eliminar',
    ];
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })
    const [close, setClose] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [records, setRecords] = useState(null);
    const [loadingRecords, setLoadingRecords] = useState(false);
    const [selectedAlertRecords, setSelectedAlertRecords] = useState(null);
    const [recordsModalOpen, setRecordsModalOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        getAlerts();
    }, []);

    const getAlerts = async () => {
        try {
            setLoading(true);
            let res = await getDiagramAlert();
            setAlertList(res.data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleDirectionDiagram = async alertId => {
        history.push(`/dynamic_diagram/${alertId}`);
    };

    const handleCrateAlert = async () => {
        history.push(`/create_alert`);
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false);
            setLoading(true);
            await deleteDiagramAlert(selectedAlert.id);
            setMsg({
                msg: "Se eliminó su alerta correctamente",
                type: "success"
            })
            setSelectedAlert(null);
            await getAlerts()
        } catch (error) {
            let msg = ''
            if (error.response) {
                msg = `${error.response.data.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(true)
        setLoading(false);
    };

    const setAlertState = async (alertId, state) => {
        try {
            var alertObj = {
                alert: {
                    activate: state
                }
            }
            await putUpdateDiagramAlert(alertId, alertObj);
            setMsg({
                msg: "Se actualizó su alerta correctamente",
                type: "success"
            })
        } catch (error) {
            let msg = `Ocurrió un error inesperado`
            setMsg({
                msg: msg,
                type: "error"
            })
            await getAlerts()
        }
        setClose(true)
    }

    const handleAlertRecord = async (alert) => {
        try {
            await getAlertRecords(alert.id);
            setSelectedAlertRecords(alert)
            setRecordsModalOpen(true)
        } catch (error) {
            let msg = `Ocurrió un error inesperado`
            setMsg({
                msg: msg,
                type: "error"
            })
        }
    }

    const getAlertRecords = async (alertId, page) => {
        try {
            setLoadingRecords(true)
            let records = await alertRecords(alertId, page);
            calcTotalPages(records.data.count)
            setRecords(records.data)
        } catch (error) {
            let msg = `Ocurrió un error inesperado`
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setLoadingRecords(false)
    }

    const calcTotalPages = (count) => {
        const itemsPerPage = 10;
        const numberOfPages = Math.ceil(count / itemsPerPage);
        setTotalPages(numberOfPages);
    }

    return (
        <>
            <TopNav titulo="Alertas">
                <Grid container justifyContent="flex-end" alignItems="center">
                    {/* Nombre Localización */}
                    <Button
                        className={classes.btn}
                        type="button"
                        onClick={handleCrateAlert}
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Nueva alerta
                    </Button>
                </Grid>
            </TopNav>
            {loading && (
                <Box display="flex" justifyContent="center" marginTop={5}>
                    <CircularProgress />
                </Box>
            )}



            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />
            <ConfirmationDialog
                isOpen={isDeleteDialogOpen}
                handleConfirm={handleDelete}
                handleCancel={() => setIsDeleteDialogOpen(false)}
                title={`¿Desea eliminar la alerta '${selectedAlert?.name}'?`}
                content='En caso de confirmar la alerta será eliminada'
            />
            <RecordsDialog
                handleCloseRecordsModal={() => setRecordsModalOpen(false)}
                records={records}
                recordsModalOpen={recordsModalOpen}
                selectedAlertRecords={selectedAlertRecords}
                handleChangePaginator={(event, value) => getAlertRecords(selectedAlertRecords?.id, value)}
                loading={loadingRecords}
                style={{ overflowX: 'hidden' }}
                totalPages={totalPages}
            />
            {!loading &&
                <Container>
                    {alertList.length === 0 ?
                        <Alert severity="warning">
                            {'No posee ninguna alerta puede crear una a continuación haciendo click en el botón Nueva alerta.'}
                        </Alert>
                        :
                        <AlertPlcTable
                            dataList={alertList}
                            labelsTableHeader={labelsTableHeader}
                            plcComponent={false}
                            handleDirectionDiagram={(alertId) => handleDirectionDiagram(alertId)}
                            setIsDeleteDialogOpen={() => setIsDeleteDialogOpen(true)}
                            setSelectedAlert={(alert) => setSelectedAlert(alert)}
                            handleAlertState={(alertId, state) => setAlertState(alertId, state)}
                            handleAlertRecord={(alert) => handleAlertRecord(alert)}
                        />
                    }
                </Container>
            }
        </>
    );
};

export default Alerts;
