import {
	Box,
	Button, Switch, CircularProgress, Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, Grid, IconButton,
	Snackbar,
	TextField, Tooltip, Typography,
	makeStyles,
	InputAdornment
} from '@material-ui/core';
import { Add, Image, Remove } from '@material-ui/icons';
import { ColorPicker } from 'material-ui-color';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { deleteNode, getExtraInfo, getSharedNodes, linkTagToNodeId, putExtraField, putExtraInfo, putNodesById, unlinkTagFromNodeId } from '../../services/hierarchy';
import { getUnassignedMeters } from '../../services/meters';
import PlacesAutocomplete from './PlacesAutocomplete';
import { getDayOrder, translateDayToEnglish, translateDayToSpanish } from './helpers';
//Helpers
import { blueBar } from '../../helpers/common';
//Componentes
import { Alert } from '@mui/material';
import ControlPanel from '../ControlPanel/ControlModal';
import DevicesList from './DevicesList';
import TagsList from './TagsList';
import UsersList from './UsersList';
//plan
// import UserContext from '../../context/UserContext';

import Resizer from "react-image-file-resizer";

const useStyles = makeStyles(theme => ({
	circular: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '15px',
	},
	form: {
		marginLeft: theme.spacing(2),
		'& .MuiInputBase-root': {
			color: '#666666',
		},
	},
	title: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		fontSize: 23,
		fontWeight: 700,
		padding: '10px 15px',
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		marginTop: theme.spacing(2),
	},
	input: {
		'& .MuiInputBase-input': {
			fontSize: 13,
			padding: theme.spacing(2, 0),
		},
	},
	btnImage: {
		marginTop: theme.spacing(2),
	},
	btn: {
		margin: theme.spacing(3, 1),
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	newImage: {
		fontWeight: 400,
		fontSize: '14px'
	},
	imageFilename: {
		color: theme.palette.primary.light,
		textDecoration: 'underline'
	},
	image: {
		maxWidth: '50%',
	},
	imageName: {
		fontSize: '12px',
	},
	scheduleTitle: {
		fontSize: '13px',
		color: theme.palette.grey.main,
		backgroundColor: theme.palette.grey.dark,
		padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
		borderRadius: theme.spacing(1),
		textAlign: 'center',
	},
	rowContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// border: `1px solid ${theme.palette.grey.dark}`,
		borderRadius: theme.spacing(0.5),
		marginBottom: theme.spacing(1),
	},
	dayName: {
		fontSize: 16,
		color: theme.palette.primary.light,
		marginBottom: theme.spacing(1),
	},
	timeSelector: {
		width: '100%',
	},
	buttons: {
		marginBottom: '0.85em',
	},
	containerGenerator:{
	
		"@media (min-width: 700px)": {
			display:'flex',
			alignItems:'end',
		},
	}
}));

export default function NodeInfo({ setMsg, setClose, alertNodo, selectedNode, setSelectedNode, setTreeChanges, nodes }) {
	const { level_nombre, level } = selectedNode;
	// const { plan } = useContext(UserContext);
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [nodeData, setNodeData] = useState(selectedNode)
	const [extraField, setExtraField] = useState({});
	const [sharedNodes, setSharedNodes] = useState([]);
	// Serán pasados como props a DevicesList.
	const [selectedMeter, setSelectedMeter] = useState(0);
	const [selectedMeterName, setSelectedMeterName] = useState("");
	const [unassignedMeters, setUnassignedMeters] = useState([]);
	// Serán pasados como props a TagsList
	const [selectedTags, setSelectedTags] = useState([]);
	const [deletedTags, setDeletedTags] = useState([]);
	const [fatherInfo, setFatherInfo] = useState({});
	const [grandfatherInfo, setGrandfatherInfo] = useState({});
	const [greatGrandfatherInfo, setGreatGrandfatherInfo] = useState({});
	const [greatGreatGrandfatherInfo, setGreatGreatGrandfatherInfo] = useState({});
	const [newImage, setNewImage] = useState({})
	const [newImageName, setNewImageName] = useState("")
	const [newImagePlan, setNewImagePlan] = useState({})
	const [newImagePlanName, setNewImagePlanName] = useState("")
	const [lapses, setLapses] = useState([]);
	//estado de el panel de controles.(ControlModal)
	const [controlPanel, setControlPanel] = useState({})
	//estado para abrir modal de componente ControlPanel
	const [openModal, setOpenModal] = useState(false)
	const blueLine = blueBar()
	/*Al hacer el GET de /nodes/{id}/workinghours obtengo:
{
	"hours": {
		"friday": [],
		"monday": [],
		"sunday": [],
		"tuesday": [],
		"saturday": [],
		"thursday": [],
		"wednesday": []
	}
}
A su vez cada array tiene objetos: {"start":xx, "stop":xx}
}
*/
	const [notification, setNotification] = useState({
		open: false,
		message: '',
		severity: 'error',
	});

	useEffect(() => {
		setNodeData(selectedNode)
	}, [selectedNode])

	const handleCreateLapse = (dayInSpanish, dayOrder) => {
		const auxLapses = [
			...lapses,
			{
				id: uuidv4(),
				day: dayInSpanish,
				dayOrder: dayOrder,
				start: '',
				stop: '',
				startError: false,
				stopError: false,
				isFirstLapseOfDay: false,
			},
		];
		// Ordeno de Lunes a viernes
		auxLapses.sort(function (a, b) {
			return a.dayOrder - b.dayOrder;
		});

		setLapses(auxLapses);
	};

	const handleRemoveLapse = id => {
		const auxLapses = lapses.filter(lapse => {
			return lapse.id !== id;
		});

		setLapses(auxLapses);
	};

	const handleColor = (value) => {
		try {
			if (value.css.backgroundColor.length === 7) {
				setNodeData({ ...nodeData, color: value.css.backgroundColor });
			} else {
				setNodeData({ ...nodeData, color: 'black' });
			}

		} catch (error) {
			console.error(error)
		}
	};

	const getErrors = () => {
		//console.log(lapses, "lapses");
		let errors = false;
		lapses.forEach(lapse => {
			if (lapse.startError || lapse.stopError) {
				errors = true;
			}
		});
		// Es necesario contar con una dirección válida.
		// if (extraField.coordinates === '0,0') {
		// 	errors = true;
		// }
		return errors;
	};

	useEffect(() => {
		const fetchNodeData = async () => {
			try {
				setLoading(true);
				setSelectedTags(selectedNode.tags);
				setFatherInfo({});
				setGrandfatherInfo({});
				setGreatGrandfatherInfo({});
				setGreatGreatGrandfatherInfo({});

				//ACA TENGO LA RESPUESTA DEL BACK CON EL DATO DE EL MODELO.
				const res1 = await getExtraInfo(selectedNode.id)
				//ACA LA INFO PARA EL MODAL DE  CONTROLES
				setControlPanel(res1.data)
				const { address, telephone, image, plant, workhours, coordinates, people, superficie, meter_name, meter_id } = res1.data;
				setExtraField({ address, telephone, image, plant, workhours, coordinates, people, superficie, meter_name, meter_id })

				setSelectedMeter(meter_id);
				setSelectedMeterName(meter_name);

				let parent1 = null;
				let parent2 = null;
				let parent3 = null;
				let parent4 = null;

				// Si no es empresa
				if (selectedNode.level > 1) {
					parent1 = nodes.find(n => n.id === selectedNode.parent)
					setFatherInfo(parent1);
				}
				// Si es una localización, sublocalización o extra
				if (selectedNode.level > 2) {
					parent2 = nodes.find(n => n.id === parent1.parent)
					setGrandfatherInfo(parent2);
				}
				// Solo tengo horas de localización
				if (selectedNode.level === 3) {
					changeFormat(workhours.hours);
				}
				// Si es una sublocalización o extra
				if (selectedNode.level > 3) {
					parent3 = nodes.find(n => n.id === parent2.parent)
					setGreatGrandfatherInfo(parent3);
				}
				// Si es extra
				if (selectedNode.level > 4) {
					parent4 = nodes.find(n => n.id === parent3.parent)
					setGreatGreatGrandfatherInfo(parent4);
				}
				setLoading(false);
			}
			catch (error) {
				console.error(error);
			}
		};
		const changeFormat = hours => {
			const auxLapses = [];
			// {friday: [], monday:[],saturday: [], sunday: [], thursday: [],tuesday: [], wednesday: []}
			for (const day in hours) {
				// friday --> viernes
				const dayInSpanish = translateDayToSpanish(day);
				// monday --> 1, sunday --> 7, friday --> 5
				const dayOrder = getDayOrder(day);
				// Almaceno los horarios de ese día.
				const dayArray = hours[day];
				//Si tengo un lapso cargado
				if (dayArray.length) {
					dayArray.forEach((lapse, index) => {
						auxLapses.push({
							id: uuidv4(),
							day: dayInSpanish,
							dayOrder: dayOrder,
							start: lapse.start,
							stop: lapse.stop,
							startError: false,
							stopError: false,
							isFirstLapseOfDay: false,
						});
						//Solo el primer elemento quiero que tenga un signo + el resto un -
						if (!index) {
							auxLapses[auxLapses.length - 1].isFirstLapseOfDay = true;
						}
					});
				} else {
					auxLapses.push({
						id: uuidv4(),
						day: dayInSpanish,
						dayOrder: dayOrder,
						start: '',
						stop: '',
						startError: false,
						stopError: false,
						isFirstLapseOfDay: true,
					});
				}
			}
			// Ordeno de Lunes a viernes
			auxLapses.sort(function (a, b) {
				return a.dayOrder - b.dayOrder;
			});
			setLapses(auxLapses);
		};
		fetchNodeData();
	}, [selectedNode, nodes]);

	useEffect(() => {
		const fetchMetersData = async () => {
			const res = await getUnassignedMeters();
			setUnassignedMeters(res.data);
		};

		fetchMetersData();
	}, [setUnassignedMeters]);

	useEffect(() => {
		const fetchSharedNodes = async () => {
			try {
				const res = await getSharedNodes(selectedNode.id);
				setSharedNodes(res.data)
			}
			catch (error) {
				console.error(error);
			}
		}
		fetchSharedNodes()
	}, [selectedNode])

	const [confirmOpen, setConfirmOpen] = useState(false);

	const handleChange = e => {
		// console.log('NodeInfo handleChange');
		setNodeData({ ...nodeData, [e.target.name]: e.target.value });
	};
	const handleCancel = e => {
		// console.log('NodeInfo handleCancel');
		setSelectedNode({});
	};
	const handleDelete = e => {
		// console.log('NodeInfo handleDelete');
		setConfirmOpen(true);
	};

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1024,
				768,
				"JPEG",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				'file'
			);
		});


	const handleSubmit = async e => {
		// console.log('NodeInfo handleSubmit');
		e.preventDefault();
		//console.log(nodeData.id);


		// if (extraField.coordinates === '0,0') {
		// 	setNotification({
		// 		isOpen: true,
		// 		msg: 'El campo dirección es obligatorio',
		// 		severity: 'error',
		// 	});

		// 	return;
		// }



		try {
			setLoading(true);
			//Actualizo los valores ingresados
			await putNodesById(selectedNode.id, {
				parent: selectedNode.parent,
				nombre: nodeData.nombre,
				descripcion: nodeData.descripcion,
				medidor_virtual: selectedMeter ? false : true,
				medidor: selectedMeter ? selectedMeter : null,
				color: nodeData.color,
				es_generador: nodeData.es_generador,
				es_bidireccional: nodeData.es_bidireccional,
				potencia_generacion:controlPanel.potencia_generacion,
				potencia_acople:controlPanel.potencia_acople
			});

			const auxObject = {
				hours: {
					friday: [],
					monday: [],
					sunday: [],
					tuesday: [],
					saturday: [],
					thursday: [],
					wednesday: [],
				},
			};
			lapses.forEach(lapse => {
				if (lapse.start || lapse.stop)
					auxObject.hours[translateDayToEnglish(lapse.day)].push({
						start: lapse.start,
						stop: lapse.stop,
					});
			});

			await putExtraInfo(selectedNode.id, {
				address: extraField.address,
				telephone: extraField.telephone,
				coordinates: extraField.coordinates,
				people: extraField.people,
				superficie: extraField.superficie,
				workhours: auxObject
			})

			if (newImageName) {
				// console.log('Actualizo imagen de localización')			
				await putExtraField(selectedNode.id, 'image', newImage);
				setNewImage({});
				setNewImageName('');
			}

			if (newImagePlanName) {
				// console.log('Actualizo plano de planta');
				await putExtraField(selectedNode.id, 'floor_plan', newImagePlan);
				setNewImagePlan({});
				setNewImagePlanName('');
			}

			if (deletedTags.length !== 0) {
				// Elimino de la DB los tags que ya no están seleccionados
				for (const tag of deletedTags) {
					await unlinkTagFromNodeId(selectedNode.id, { tag_id: tag.id });
					//console.log(res);
				}
			}

			setDeletedTags([]);

			// Agrego a la DB los tags que están seleccionados (si ya tenía seleccionado uno y aunque no haya habido cambios vuelvo a seleccionarlo)
			selectedTags.forEach(async tag => {
				await linkTagToNodeId(selectedNode.id, { tag_id: tag.id });
			});
			setLoading(false);
			setClose(true)
			setMsg({
				msg: "Los datos del nodo se modificaron correctamente",
				type: 'success'
			})
			// Quiero volver a renderizar el árbol de dispositivos a raíz de este cambio.
			setTreeChanges(current => !current);
			setSelectedNode({});
		} catch (error) {
			console.error(error);
		}
	};

	const handleConfirmClose = () => {
		// console.log('Modal cerrado');
		setConfirmOpen(false);
	};

	const handleConfirmDelete = async () => {
		// console.log('Confirmación de eliminación');
		setConfirmOpen(false);
		setSelectedNode({});
		alertNodo()

		//Llamo a DELETE Endpoint
		try {
			setLoading(true);
			//Actualizo los valores ingresados
			await deleteNode(selectedNode.id);

			setLoading(false);
			// Quiero volver a renderizar el árbol de dispositivos a raíz de este cambio.
			setTreeChanges(current => !current);
		} catch (error) {
			console.error(error);
		}
	};

	const handleIsGeneratorChange = (e) => {
		setNodeData({
			...nodeData,
			es_generador: e.target.checked
		})
		if(!e.target.checked){
			setControlPanel({
				...controlPanel,
				potencia_generacion:0,
				potencia_acople:0
			})
		}
	};

	const handleIsBidirectionalChange = (e) => {
		setNodeData({
			...nodeData,
			es_bidireccional: e.target.checked
		})
	};	

	const handleChangeForControlPanel = e => {
		// console.log('NodeInfo handleChange');
		setControlPanel({ ...controlPanel, [e.target.name]: e.target.value });
	};


	return (
		<>
			{/* Modal de configuracion de fases */}
			<ControlPanel
				openModal={openModal}
				setOpenModal={setOpenModal}
				data={controlPanel}
			/>

			{loading &&
				<Box className={classes.circular}>
					<CircularProgress />
				</Box>
			}
			{!loading &&
				<>
					<Typography variant='h4' className={classes.title}>
						{nodeData.nombre}
					</Typography>
					<form onSubmit={handleSubmit} className={classes.form}>
						{level_nombre === 'zona' && (
							<>
								<Box component='label' display='block' className={classes.label}>
									Empresa
								</Box>
								{fatherInfo?.nombre}
							</>
						)}
						{level_nombre === 'localización' && (
							<>
								<Box component='label' display='block' className={classes.label}>
									Empresa
								</Box>
								{grandfatherInfo.nombre}
								<Box component='label' display='block' className={classes.label}>
									Zona
								</Box>
								{fatherInfo.nombre}
							</>
						)}
						{level_nombre === 'sublocalización' && (
							<>
								<Box component='label' display='block' className={classes.label}>
									Empresa
								</Box>
								{greatGrandfatherInfo.nombre}
								<Box component='label' display='block' className={classes.label}>
									Zona
								</Box>
								{grandfatherInfo.nombre}
								<Box component='label' display='block' className={classes.label}>
									Localización
								</Box>
								{fatherInfo.nombre}
							</>
						)}
						{level_nombre === 'extra' && (
							<>
								<Box component='label' display='block' className={classes.label}>
									Empresa
								</Box>
								{greatGreatGrandfatherInfo.nombre}
								<Box component='label' display='block' className={classes.label}>
									Zona
								</Box>
								{greatGrandfatherInfo.nombre}
								<Box component='label' display='block' className={classes.label}>
									Localización
								</Box>
								{grandfatherInfo.nombre}
								<Box component='label' display='block' className={classes.label}>
									Sublocalización
								</Box>
								{fatherInfo.nombre}
							</>
						)}

						<Box component='label' display='block' className={classes.label}>
							Nombre *
						</Box>
						<TextField
							fullWidth
							placeholder='Nombre'
							name='nombre'
							value={nodeData.nombre || ''}
							onChange={e => handleChange(e)}
							color='primary'
							className={classes.input}
						/>
						<Box component='label' display='block' className={classes.label}>
							Descripción *
						</Box>
						<TextField
							fullWidth
							placeholder='Descripción'
							name='descripcion'
							value={nodeData.descripcion || ''}
							onChange={e => handleChange(e)}
							color='primary'
							className={classes.input}
						/>

						{(selectedNode.level_nombre === "sublocalización" || selectedNode.level_nombre === "extra") && 
						<Box className={classes.containerGenerator}>
							<Box>
								<Box component='label' display='block' className={classes.label}>
									Es generador *
								</Box>
								<Switch
									checked={nodeData.es_generador}
									color='primary'
									name='is_generator'
									onChange={handleIsGeneratorChange}
								/>
							</Box>
							
							<Box style={{marginBottom:5}}>
								<Tooltip style={{marginLeft:15}} title='Potencia de generacion: Es la potencia total de todos los paneles solares fotovoltaicos sumada.' aria-label='Potencia de generación'>
										<TextField
												placeholder='Potencia de generación'									
												name='potencia_generacion'
												value={controlPanel.potencia_generacion || ''}
												onChange={e => handleChangeForControlPanel(e) }
												disabled={!nodeData.es_generador}
												color='primary'
												className={classes.input}
												InputProps={{
													endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
												}}
										/>	
								</Tooltip>
								<Tooltip style={{marginLeft:15}} title='Potencia de acople: Es la potencia nominal total de todos los inversores solares fotovoltaicos sumados.' aria-label='Potencia de acople'>
										<TextField
												placeholder='Potencia de acople'									
												name='potencia_acople'
												value={controlPanel.potencia_acople || ''}
												onChange={e => handleChangeForControlPanel(e) }
												disabled={!nodeData.es_generador}
												color='primary'
												className={classes.input}
												InputProps={{
													endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
												}}
										/>	
								</Tooltip>
							</Box>
						</Box>
						}

						{selectedNode?.level_nombre === "localización" &&
							<Box className={classes.containerGenerator}>
								<Box>
									<Box component='label' display='block' className={classes.label}>
										Es bidireccional 
									</Box>
									<Box display={'flex'} alignItems={'center'}>
										<Switch
											checked={nodeData?.es_bidireccional}
											color='primary'
											name='is_bidirectional'
											disabled={!nodeData?.es_bidireccional_allow_update}
											onChange={handleIsBidirectionalChange}
										/>
										<Box>
											{nodeData?.es_bidireccional ? 'Si' : 'No'}
										</Box>
									</Box>							
								</Box>
							</Box>
						}

						{/* Si es una localizacion muestra los inputs de dirección/teléfono y si no lo es los oculto. */}
						{selectedNode.level_nombre === "localización" &&
							<Box>
								<Box component='label' display='block' className={classes.label}>
									Dirección *
								</Box>
								<PlacesAutocomplete setExtraField={setExtraField} extraField={extraField} />

								<Box component='label' display='block' className={classes.label}>
									Teléfono *
								</Box>
								<TextField
									fullWidth
									placeholder='Teléfono'
									name='telefono'
									value={extraField.telephone || ''}
									onChange={e =>
										setExtraField({ ...extraField, telephone: e.target.value })
									}
									color='primary'
									className={classes.input}
								/>

								<Box component='label' display='block' className={classes.label}>
									Superficie *
								</Box>
								<TextField
									fullWidth
									placeholder='Superficie'
									name='superficie'
									value={extraField.superficie || ''}
									onChange={e =>
										setExtraField({ ...extraField, superficie: e.target.value })
									}
									color='primary'
									className={classes.input}
								/>
								<Box component='label' display='block' className={classes.label}>
									Cantidad de Empleados *
								</Box>
								<TextField
									fullWidth
									placeholder='Cantidad de Empleados'
									name='telefono'
									value={extraField.people || ''}
									onChange={e =>
										setExtraField({ ...extraField, people: e.target.value })
									}
									color='primary'
									className={classes.input}
								/>

								<Box component='label' display='block' className={classes.label}>
									Imagen
								</Box>
								{
									// Si cargué una nueva imagen
									newImageName &&
									<Box component='label' display='block' className={classes.label}>
										<Box className={classes.newImage}>
											Nueva imagen seleccionada: <span className={classes.imageFilename}>{newImageName}</span>. Es necesario guardar los cambios para confirmarla.
										</Box>
									</Box>
								}

								{
									// Si no cargué una nueva imagen y obtuve una de la API

									!newImageName && extraField.image &&

									<Box className={classes.imageContainer}>

										<img
											src={`${extraField.image}`}
											className={classes.image}
											alt='Imagen'
										/>
									</Box>
								}

								{
									!newImageName &&
									<Button
										variant='outlined'
										color='primary'
										component='label'
										fullWidth
										startIcon={<Image />}
										className={classes.btnImage}
									>
										Seleccionar
										<input
											type='file'
											onChange={async e => {

												try {
													const file = e.target.files[0];
													setNewImageName(file.name);

													const image = await resizeFile(file);

													const formData = new FormData();
													formData.append('imagen', image);
													setNewImage(formData);
												} catch (err) {
													console.error(err);
												}

											}}
											accept="image/png, image/jpeg"
											hidden
										/>
									</Button>
								}
								{/* <Box component='label' display='block' className={classes.label}>
									Plano de Planta

									{
										// Si cargué un nuevo plano de planta
										newImagePlanName &&
										<Box component='label' display='block' className={classes.label}>
											<Box className={classes.newImage}>
												Nueva imagen seleccionada: <span className={classes.imageFilename}>{newImagePlanName}</span>. Es necesario guardar los cambios para confirmarla.
											</Box>
										</Box>
									}
									{
										// Si no cargué una nueva imagen y obtuve una de la API
										!newImagePlanName && extraField.plant &&
										<div className={classes.imageContainer}>
											<img
												src={`${extraField.plant}`}
												className={classes.image}
												alt='Plano'
											/>
										</div>
									}

									<Button
										variant='outlined'
										color='primary'
										component='label'
										fullWidth
										startIcon={<Image />}
										className={classes.btnImage}
									>
										Seleccionar
										<input
											type='file'
											onChange={async e => {
												const file = e.target.files[0];
												setNewImagePlanName(file.name);

												const image = await resizeFile(file);

												const formData = new FormData();
												formData.append('imagen', image);
												setNewImagePlan(formData);

											}}
											accept="image/png, image/jpeg"
											hidden
										/>
									</Button>
								</Box> */}

								<Box component='label' display='block' className={classes.label}>
									Horario Laboral
								</Box>

								{lapses.map((lapse, indx) => (
									<Box key={indx}>
										<Box className={classes.dayName}>{lapse.day}</Box>
										<Grid container spacing={1} className={classes.rowContainer} key={lapse.id}>
											<Grid item xs={5}>
												<TextField
													type='time'
													label='Desde:'
													size='small'
													value={lapse.start}
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														step: 900, // 15 min
													}}
													className={classes.timeSelector}
													onChange={e => {
														const [hoursStart, minutesStart] = e.target.value.split(':');
														const totalMinutesStart = Number(hoursStart * 60 + minutesStart);

														const [hoursStop, minutesStop] = lapse.stop.split(':');
														const totalMinutesStop = Number(hoursStop * 60 + minutesStop);
														//console.log('e.target.value', e.target.value);
														if (totalMinutesStop < totalMinutesStart || !e.target.value) {
															const auxLapses = lapses.map(l => {
																if (l.id === lapse.id) {
																	l.startError = true;
																	l.start = e.target.value;
																}
																return l;
															});
															setLapses(auxLapses);
														} else {
															const auxLapses = lapses.map(l => {
																if (l.id === lapse.id) {
																	l.startError = false;
																	//Elimino también el error de hora final por si existió
																	l.stopError = false;
																	l.start = e.target.value;
																}
																return l;
															});
															setLapses(auxLapses);
														}
													}}
													error={lapse.startError}
													helperText={lapse.startError ? 'Error en hora inicio' : 'Hora inicio'}
													variant='outlined'
												/>
											</Grid>
											<Grid item xs={5}>
												<TextField
													type='time'
													label='Hasta:'
													size='small'
													value={lapse.stop}
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														step: 900, // 15 min
													}}
													className={classes.timeSelector}
													onChange={e => {
														//Si ya completé la hora final y completo la inicial mal
														const [hoursStart, minutesStart] = lapse.start.split(':');
														const totalMinutesStart = Number(hoursStart * 60 + minutesStart);

														const [hoursStop, minutesStop] = e.target.value.split(':');
														const totalMinutesStop = Number(hoursStop * 60 + minutesStop);

														if (totalMinutesStop < totalMinutesStart) {
															const auxLapses = lapses.map(l => {
																if (l.id === lapse.id) {
																	l.stopError = true;
																	l.stop = e.target.value;
																}
																return l;
															});
															setLapses(auxLapses);
														} else {
															const auxLapses = lapses.map(l => {
																if (l.id === lapse.id) {
																	l.stopError = false;

																	//Elimino también el error de hora inicial por si existió
																	l.startError = false;
																	l.stop = e.target.value;
																}
																return l;
															});
															setLapses(auxLapses);
														}
													}}
													error={lapse.stopError}
													helperText={lapse.stopError ? 'Error en hora fin' : 'Hora fin'}
													variant='outlined'
												/>
											</Grid>
											<Grid item xs={2}>
												{lapse.isFirstLapseOfDay ? (
													<Tooltip
														title={`Agregar franja horaria para el día ${lapse.day}`}
														aria-label='agregar franja horaria'
														className={classes.buttons}
													>
														<IconButton
															aria-label='agregar franja horaria'
															onClick={() => handleCreateLapse(lapse.day, lapse.dayOrder)}
														>
															<Add fontSize='inherit' />
														</IconButton>
													</Tooltip>
												) : (
													<Tooltip title={`Eliminar esta franja horaria del ${lapse.day}`} aria-label='Eliminar franja horaria'>
														<IconButton aria-label='eliminar franja horaria' onClick={() => handleRemoveLapse(lapse.id)}>
															<Remove fontSize='inherit' />
														</IconButton>
													</Tooltip>
												)}
											</Grid>
										</Grid>
									</Box>
								))}
							</Box>
						}

						{blueLine}

						{level > 2 && (
							<>
								<Box component='label' display='block' className={classes.label}>
									Dispositivos
								</Box>
								<DevicesList
									// Ambas props tienen el mismo valor pero cuando modifique en DeviceList quiero conservar el original
									savedMeter={extraField.meter_id}
									savedMeterName={extraField.meter_name}
									selectedMeter={selectedMeter}
									selectedMeterName={selectedMeterName}
									unassignedMeters={unassignedMeters}
									setSelectedMeter={setSelectedMeter}
									setSelectedMeterName={setSelectedMeterName}
								/>

								{blueLine}

								{/* Configuracion de fases */}
								{controlPanel?.product &&
									<>
										<Box component='label' display='flex' className={classes.label} >
											<Box >
												Modelo:
											</Box>
											<Box ml={1} style={{ fontWeight: 100 }}>
												{controlPanel?.product}
											</Box>
										</Box>

										{/* se comento fecha de alta por q no tengo ese dato todavia. */}

										{/* <Box component='label' display='flex' className={classes.label} >
	                                     		<Box >
		            				      				Fecha de alta:
	              		                		</Box>
	                  	 						<Box ml={2} style={{ fontWeight: 300 }}>
														dd/mm/aaaa
												</Box>
										</Box> */}

										<Box mt={2} mb={2} >
											<Tooltip title={`Aqui puede modificar el estado de las fases que pertenecen al dispositivo`}>
												<Button
													onClick={() => { setOpenModal(true) }}
													size="small"
													color='primary'
													variant='contained' >
													Configurar
												</Button>
											</Tooltip>
										</Box>

										{blueLine}
									</>
								}


								<Box component='label' display='block' className={classes.label}>
									Etiquetas
									<TagsList
										savedTags={selectedNode.tags}
										setDeletedTags={setDeletedTags}
										selectedTags={selectedTags}
										setSelectedTags={setSelectedTags}
									/>
								</Box>
								<Box component='label' display='block' className={classes.label}>
									Color
									<ColorPicker value={nodeData.color} defaultValue="black" onChange={value => handleColor(value)} />
								</Box>

								{blueLine}
								{/* Usuarios compartidos */}
								<Box component='label' display='block' className={classes.label}>
									Usuarios Compartidos
								</Box>
								<UsersList shared={sharedNodes} />

							</>
						)}


						<Box display='flex' justifyContent='center' position="sticky" bottom={0} bgcolor="white" mt={2} zIndex={99}>
							<Button type='submit' color='primary' variant='contained' className={classes.btn} disabled={getErrors()}>
								Guardar
							</Button>
							<Button variant='contained' onClick={handleCancel} className={classes.btn}>
								Cancelar
							</Button>
							<Button color='secondary' variant='contained' onClick={handleDelete} className={classes.btn}>
								Eliminar
							</Button>
						</Box>
					</form>

					<Dialog open={confirmOpen} onClose={handleConfirmClose} aria-labelledby='form-dialog-title'>
						<DialogTitle id='form-dialog-title'>¿Desea eliminar el nodo?</DialogTitle>
						<DialogContent>En caso de confirmar el nodo {nodeData.nombre} será eliminado</DialogContent>
						<DialogActions>
							<Button color='default' onClick={handleConfirmClose}>
								Cancelar
							</Button>
							<Button type='submit' color='secondary' variant='outlined' onClick={handleConfirmDelete}>
								Aceptar
							</Button>
						</DialogActions>
					</Dialog>

					<Snackbar
						open={notification.isOpen}
						autoHideDuration={2000}
						onClose={() => setNotification({ ...notification, isOpen: false })}
					>
						<Alert
							severity={notification.severity}
							onClose={() => setNotification(false)}
						>
							{notification.msg}
						</Alert>
					</Snackbar>
				</>
			}
		</>
	);
}
