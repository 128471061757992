import React, { useContext, useState } from 'react';

//components
import TopNav from '../common/TopNav';
// context
import UserContext from '../../context/UserContext';
import SubaccountsCreator from './SubaccountsCreator';
import SubaccountsViewer from './SubaccountsViewer';
import AlertComponent from '../Elements/AlertComponent';

function SubaccountsConfig() {
  const { plan } = useContext(UserContext)
  //Estados alert
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    severity: "success"
  })

  return (
    <>
      <TopNav titulo="Configuración" subtitulo='| Sub-Cuentas' />
      {/* ALERTAS */}
      <AlertComponent
        severity={notification.severity}
        msg={notification.msg}
        setClose={setNotification}
        close={notification.isOpen}
      />
      {plan?.access?.configuracion_usuario_subcuentas &&
        <SubaccountsCreator
          setNotification={setNotification}
          notification={notification}
          plan={plan} />
      }

      {plan?.access?.configuracion_usuario_invitaciones &&
        <SubaccountsViewer plan={plan} />}
    </>
  )
}

export default SubaccountsConfig