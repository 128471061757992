// Static Data
import { timezones } from './TimezonesData';
import { getUserInfoFromLocalStorage } from '../services/auth';
import { format, utcToZonedTime } from 'date-fns-tz';

// FUNCIONES PARA FORMATEAR TIMEZONE.

//funcion para aclarar o oscurecer un color
export const changeLigthColor = (color, amount) => {
    var usePound = false;
    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }
    var num = parseInt(color, 16);
    var r = (num >> 16) + amount;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00FF) + amount;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000FF) + amount;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

//Obtiene el timezone de el usuario.
const formatGMTuser = () => {
    let TZ = ''
    let userTimeZone = getUserInfoFromLocalStorage()?.info?.tz
    Object.keys(timezones).forEach(key => {
        if (timezones[key] === userTimeZone) {
            TZ = timezones[key]
        }
    })
    return TZ
}

//Función q transforma el timestamp UTC (2023-12-06T16:00:02Z)  a  (2023-12-06 16:05 GMT-1).
export const utcConverter = (timestampUtc) => {
    if (timestampUtc === '-') {
        return '-'
    }
    if (timestampUtc) {
        let zonaHorariaStr = formatGMTuser()
        // Obtain a Date instance that will render the equivalent (zonaHorariaStr) time for the UTC date
        const date = new Date(timestampUtc)
        const timeZone = zonaHorariaStr
        const zonedDate = utcToZonedTime(date, timeZone)
        // zonedDate could be used to initialize a date picker or display the formatted local date/time
        // Set the output to "1.9.2018 18:01:36.386 GMT+02:00 (CEST)"
        const pattern = 'yyyy-MM-dd HH:mm \'GMT\' XXX '
        const output = format(zonedDate, pattern, { timeZone: zonaHorariaStr })
        return output
    }
    return ''
}

// Funcion para convertir un timestamp en milisegundos  formato (1702328084)  a  (2023-12-11 21:57 GMT +01:00 ). 
export const millisecondsConverter = (timestampParams) => {
    let timestamp = timestampParams * 1000; // El timestamp se proporciona en segundos, así que se multiplica por 1000 para convertirlo a milisegundos
    const timeZone = 'UTC'; // Especifica la zona horaria deseada, por ejemplo, 'America/New_York', 'Europe/London', etc.
    const date = new Date(timestamp);
    const zonedDate = utcToZonedTime(date, timeZone);
    const formattedDate = format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'", { timeZone: 'UTC' }); // Formato deseado: '2023-12-11T20:08:07Z'
    let res = utcConverter(formattedDate)
    return res
}