
import { useEffect, useState, useContext } from 'react';

import {
    Box, IconButton, Stepper, Step, Tooltip, MenuItem,
    makeStyles, StepLabel, Select, FormControl, FormControlLabel, Radio,
    RadioGroup, Tabs, Tab, Chip, TextField, Checkbox, FormGroup
} from '@material-ui/core';
//Style
import clsx from 'clsx';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
//Helpers.
import { blueBar } from '../../../helpers/common'
import { getCatalogo } from '../helpers';
//Componentes.
import ListNodeSelect from '../../Dashboards/SummaryLocation/Filter'
import DynamicSelect from '../../common/DynamicSelect';
//Estado local nodos
import NodesListContext from '../../../context/NodesListContext';
//Hooks
import useDeviceDetect from '../../../hooks/useDeviceDetect';

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    root: {
        ...scrollsSettings,
        height: '600px',
        '@media (max-width: 920px)': {
            height: '70%',
        },
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
        },
        '& .MuiStepper-root': {
            padding: '1px',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: theme.spacing(0.5),
        },
    },
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3.1),
        width: theme.spacing(3.1),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    LabelDisabledGranularityOptions: {
        color: 'black',
        fontSize: '0.75rem',
    },
    addBtn: {
        margin: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    filterChip: {
        margin: theme.spacing(0.5),
        '@media (max-width: 700px)': {
            width: '250px',
        },
    },
    btn: {
        margin: theme.spacing(3, 1),
        '@media (max-width: 700px)': {
            fontSize: '13px',
            padding: '1%',
        },
    },
    tabsTitle: {
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        textAlign: 'center',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    radio: {
        height: '150px',
        marginLeft: theme.spacing(1),
        '& .MuiTypography-body1': {
            fontSize: '0.75rem',
        },
        '& > *': {
            flexBasis: '33%',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },
        '@media (max-width: 700px)': {
            height: 'auto',
        },
    },
    formWeekSelected: {
        display: 'flex'
    }
}));

const VariableFilterOptions = ({ varState, setVarState, setDisabledButtonSave }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    const { isMobile } = useDeviceDetect(990);
    const { nodesList } = useContext(NodesListContext);
    const [activeStep, setActiveStep] = useState(0);
    const [nodeSelect, setNodeSelect] = useState({});
    const [openModalDevice, setIsOpenModalDevice] = useState(false);
    //Estado inicial de la variable
    const [initialVarState, setInitialVarState] = useState({});
    //Periods
    const [granularity, setGranularity] = useState([]);
    const [indexSelectPeriods, setIndexSelectPeriods] = useState(0);
    //Periods.values
    const [Rango, setRango] = useState('');
    //Lista de opciones q pertenecen a tipo de operacion unico.
    const [uniqueValueList, setUniqueValueList] = useState([]);
    //block_op_choices
    const [opChoice, setOpChoice] = useState('');
    //Deshabilita la opcion de el selector tipo de operacion cuando es unico.
    const [disableOperatorSelect, setDisableOperatorSelect] = useState(false);
    //Estado de el filtro.
    const [stateForm, setStateForm] = useState({
        node: '',
        variable: '',
        values: ''
    });
    //Estado Franja horaria
    const [selectedDays, setSelectedDays] = useState([])
    const [selectedHoursTimeSlot, setHoursTimeSlot] = useState({
        time_from: '00:00',
        time_to: '00:00',
        step: 900
    })
    //Steeps options.
    const steps = [
        'Elija un dispositivo',
        'Elija una variable.',
        'Seleccione un tipo de operación.',
        'Opcional franja horaria.',
        'Haga click en Guardar'
    ]
    const [optionSelectOperation, setOptionSelectOperation] = useState([]);
    //Deshabilita la configuracion de granularidad y periodo.
    const [disabledGranularityOptions, setDisabledGranularityOptions] = useState(false);
    //Habilita o deshabilita el check para deshabilitar las opciones de granularidad.
    const [disabledCheckBoxGranularityOptions, setDisabledCheckBoxGranularityOptions] = useState(false);


    //Estado q desaparece la opcion UNICO cuando el valor no puede ser valor unico.
    useEffect(() => {
        let res = getCatalogo('ALERTS_VARIABLES', 'generic_data')?.block_op_choices
        if (disableOperatorSelect) {
            setOptionSelectOperation(res)
        }
        else {
            setOptionSelectOperation(res.filter(e => e.key !== 'UNIQUE'))
        }
    }, [disableOperatorSelect])

    useEffect(() => {
        let res = getCatalogo('ALERTS_VARIABLES', 'generic_data')
        let uniqueValueListAux = res?.unique_value
        setUniqueValueList(uniqueValueListAux)
        //Cargo el estado inicial de la variable
        setInitialVarState(varState)
        //Cargo el nodo al filtro
        if (varState?.nodes?.length > 0) {
            let idNode = varState?.nodes[0]
            let nodeFind = nodesList.find(e => e.id === idNode)
            setNodeSelect(nodeFind)
        }
        //Cargo variable.
        if (varState?.values?.length > 0) {
            let prevValue = varState?.values
            let variable = prevValue.split('-')[1]
            let CATALOGO = getCatalogo('ALERTS_VARIABLES', 'detail_data')
            let filterVar = CATALOGO.find(e => e.key === variable)
            //Deshabilita o habilita el checkbox dedicado a las opciones de granularidad y periodo
            HandledisabledGranularityOptions(filterVar?.endpoint?.key, varState?.values)
            let periods = filterVar?.periods
            setGranularity(periods)
            let granularityKey = varState?.values?.split('-')[2]
            let rango = varState?.values?.split('-')[3]
            //Si no tiene granularidad o rango por q deshabilito la opcion pongo una por defecto para cuando el user vuelva a habilitar
            if (granularityKey === undefined) {
                granularityKey = periods[0].key
            }
            if (rango === undefined) {
                rango = periods[0].values[0].key
            }
            setRango(rango)
            //Cargo granularidad si tiene periodos.
            if (periods?.length > 0) {
                for (let i = 0; i < periods.length; i++) {
                    if (periods[i].key === granularityKey) {
                        setIndexSelectPeriods(i)
                    }
                }
            }
            //Agrego la propiedad variable al estado local.
            setStateForm({
                variable,
            })
            //Cargo tipo de operacion.
            let variable_operation = varState?.variable_operation
            setOpChoice(variable_operation)
            let findOption = uniqueValueListAux.find(e => e?.granularity === granularityKey)
            let res = findOption?.range.includes(rango)
            setDisableOperatorSelect(res)
            formatTimeStols(varState, filterVar?.granularity_default)
            setActiveStep(4)
        }
        // eslint-disable-next-line
    }, [])

    //Formatea el timeSlots
    // lu,ma,mi,ju,vi=12:30a19:30
    const formatTimeStols = (varState, granularityAux) => {
        let element = varState?.timeslots
        if (element?.timeslots) {
            let stringData = element?.timeslots
            let elementos = stringData?.split('=')[0];
            // Separa los elementos por coma
            let listaElementos = elementos.split(',');
            setSelectedDays(listaElementos)
            // Separa la parte después del '='
            let tiempo = stringData.split('=')[1];
            // Separa los tiempos por la 'a'
            let [time_from, time_to] = tiempo.split('a');

            let initialStep = 900
            if (granularityAux === '60_MINUTES') {
                initialStep = 3600
            }
            setHoursTimeSlot({
                time_from,
                time_to,
                step: initialStep
            })
        }
    }

    //Handle Node seleccionado.
    useEffect(() => {
        if (nodeSelect?.id) {
            setVarState({
                ...varState,
                nodes: [nodeSelect?.id]
            })
        }
        else {
            setVarState({
                ...varState,
                nodes: []
            })
        }
        setDisabledButtonSave(false)
        // eslint-disable-next-line
    }, [nodeSelect])

    //Estado franja horaria
    useEffect(() => {
        const daysString = selectedDays.join(',');
        let aux = `${daysString}=${selectedHoursTimeSlot?.time_from}a${selectedHoursTimeSlot?.time_to}`
        if (selectedDays.length > 0) {
            setVarState({
                ...varState,
                timeslots: {
                    timeslots: aux
                }
            })
        }
        else {
            setVarState({
                ...varState,
                timeslots: null
            })
        }
        // eslint-disable-next-line
    }, [selectedDays, selectedHoursTimeSlot])

    // `${SECCION}-${VARIABLE}-${GRANULARIDAD}-${RANGO}`
    const handleChangeVariable = (e) => {
        let name = e.target.name
        let value = e.target.value
        setDisabledButtonSave(false)
        let stateFormAux = {
            ...stateForm,
            [name]: value
        }
        //Coloco el local del componente AlertFilterOptions
        setStateForm(stateFormAux)
        if (name === 'variable') {
            let CATALOGO = getCatalogo('ALERTS_VARIABLES', 'detail_data')
            let filterVar = CATALOGO.find(e => e.key === value)
            //Deshabilita o habilita el checkbox dedicado a las opciones de granularidad y periodo
            HandledisabledGranularityOptions(filterVar?.endpoint?.key)
            // Deshabilita los minutos en el input si la variable solo permite periodos de 60 min.          
            let periods = filterVar?.periods
            let rangoAux = periods[0]?.values[0]?.key
            setGranularity(periods)
            setIndexSelectPeriods(0)
            setRango(rangoAux)
            let SECCION = 'ALERTS_VARIABLES'
            let VARIABLE = stateFormAux.variable
            let valuesAux = `${SECCION}-${VARIABLE}-${periods[0]?.key}-${periods[0]?.values[0]?.key}`
            let varStateObjAux = {
                ...varState,
                values: valuesAux,
            }
            let findOption = uniqueValueList.find(e => e?.granularity === periods[0]?.key)
            let res = findOption?.range.includes(rangoAux)
            //Valida si no posee la opcion unico elimina la propiedad variable_operation
            if (res) {
                setOpChoice("UNIQUE");
                varStateObjAux = {
                    ...varStateObjAux,
                    variable_operation: "UNIQUE"
                };
            }
            else if (opChoice === 'UNIQUE') {
                setOpChoice("");
                varStateObjAux = {
                    ...varStateObjAux,
                    variable_operation: ""
                };
            }

            setDisableOperatorSelect(res)
            //Coloco el estado geenral de la variable
            setVarState(varStateObjAux)
            //Deshabilita los minutos si la variable solo permite valores de 60_MINUTES
            updateHoursTimeSlot(filterVar?.granularity_default)
            setActiveStep(2)
        }
    }

    //Handle granularidad.
    const handleGranularity = (event, tabNumber) => {
        setIndexSelectPeriods(tabNumber)
        let rangoAux = granularity[tabNumber].values[0].key
        setRango(rangoAux)
        let valuesAux = varState.values
        let spliter = valuesAux
        if (typeof spliter === 'string') {
            spliter = spliter?.split('-')
        }
        if (spliter[2]) {
            spliter[2] = granularity[tabNumber]?.key
            // spliter[3] = granularity[tabNumber]?.values[0]?.key
            spliter.join('-')
            valuesAux = spliter
        }
        else {
            valuesAux += `-${granularity[tabNumber]?.key}`
        }
        if (typeof valuesAux !== 'string') {
            valuesAux = valuesAux?.join('-')
        }
        let varStateObjAux = {
            ...varState,
            values: valuesAux,
        }
        let findOption = uniqueValueList.find(e => e?.granularity === granularity[tabNumber].key)
        let res = findOption?.range.includes(rangoAux)
        //Valida si no posee la opcion unico elimina la propiedad variable_operation
        if (res) {
            setOpChoice("UNIQUE");
            varStateObjAux = {
                ...varStateObjAux,
                variable_operation: "UNIQUE"
            };
        }
        else if (opChoice === 'UNIQUE') {
            setOpChoice("");
            varStateObjAux = {
                ...varStateObjAux,
                variable_operation: ""
            };
        }
        setDisableOperatorSelect(res)
        //Coloco el estado geenral de la variable
        setVarState(varStateObjAux)
    }

    //Handle periodo.
    const handlePeriod = (e) => {
        let value = e.target.value
        setRango(value)
        let valuesAux = varState.values
        let spliter = valuesAux
        if (typeof spliter === 'string') {
            spliter = spliter?.split('-')
        }
        if (spliter[3]) {
            spliter[3] = value
            spliter.join('-')
            valuesAux = spliter
        }
        else {
            valuesAux += `-${value}`
        }
        if (typeof valuesAux !== 'string') {
            valuesAux = valuesAux?.join('-')
        }

        let varStateObjAux = {
            ...varState,
            values: valuesAux,
        }

        //valida las ocpciones para verificar si el valor es unico.
        let findOption = uniqueValueList.find(e => e?.granularity === granularity[indexSelectPeriods]?.key)
        let res = findOption?.range.includes(value)
        //Valida si no posee la opcion unico elimina la propiedad variable_operation
        if (res) {
            setOpChoice("UNIQUE");
            varStateObjAux = {
                ...varStateObjAux,
                variable_operation: "UNIQUE"
            };
        }
        else if (opChoice === 'UNIQUE') {
            setOpChoice("");
            varStateObjAux = {
                ...varStateObjAux,
                variable_operation: ""
            };
        }
        setDisableOperatorSelect(res)
        //Coloco el estado geenral de la variable
        setVarState(varStateObjAux)
    }

    const handleOpChoice = (e) => {
        let value = e.target.value
        setOpChoice(value)
        setActiveStep(3)
        setVarState({
            ...varState,
            variable_operation: value
        })
    }

    const handleOpenCloseModalDevices = () => {
        setIsOpenModalDevice(!openModalDevice)
    }

    const handleTimeSlot = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            // Si se selecciona, agregamos el día al array
            setSelectedDays([...selectedDays, value]);
        } else {
            // Si se deselecciona, eliminamos el día del array
            setSelectedDays(selectedDays.filter((day) => day !== value));
        }
        setActiveStep(4)
    };

    const handleHoursTimeSlot = (event) => {
        const { value, name } = event.target
        setHoursTimeSlot({
            ...selectedHoursTimeSlot,
            [name]: value
        })
        setActiveStep(4)
    }

    //Funcion q deshabilita los minutos si la variable tiene granularidad de 60_MINUTES
    const updateHoursTimeSlot = (value) => {
        const isHourlyGranularity = value === '60_MINUTES';
        const updatedHoursTimeSlot = {
            ...selectedHoursTimeSlot,
            step: isHourlyGranularity ? 3600 : 900, // 3600 segundos para 1 hora, 900 segundos para 15 minutos
            ...(isHourlyGranularity && {
                time_from: '00:00',
                time_to: '00:00',
            }),
        };
        setHoursTimeSlot(updatedHoursTimeSlot);
    };


    function verificarPartes(cadena) {
        let partes = cadena.split('-');
        if (partes.length === 2) {
            return true
        } else {
            return false
        }
    }
    //Habilita el checkbox de opciones Granularidad y periodo.
    const HandledisabledGranularityOptions = (value, initialValuesGet) => {
        // setDisabledCheckBoxGranularityOptions(false)
        // setDisabledGranularityOptions(false)
        if (value === 'VALIDATE_INSTANT') {
            setDisabledCheckBoxGranularityOptions(true)
        }
        else {
            setDisabledCheckBoxGranularityOptions(false)
        }
        setDisabledGranularityOptions(false)
        if (initialValuesGet) {
            setInitialVarState(initialValuesGet)
            if (verificarPartes(initialValuesGet)) {
                setDisabledGranularityOptions(true)
            }
        }
    }

    //Manejador de estado del checkbox q habilita o deshabilita las opciones de Granilaridad y periodo.
    const handleCheckBoxGranularityOptions = (e) => {
        const { checked } = e.target;
        setDisabledGranularityOptions(checked)
        let config_keyAux = varState?.values
        setInitialVarState(varState)
        if (checked) {
            // Divide el string en un array de partes
            let splitSring = config_keyAux.split('-');
            // Úne las primeras dos partes
            let newConfigKey = splitSring.slice(0, 2).join('-');
            config_keyAux = newConfigKey
        }
        else {
            let auxValue = initialVarState?.values
            if (!auxValue) {
                config_keyAux = `${varState?.values}-${granularity[0].key}-${granularity[0].values[0].key}`
                setRango(granularity[0].values[0].key)
            }
            else {
                let splitSring = config_keyAux.split('-');
                // Úne las primeras dos partes
                let newConfigKey = splitSring.slice(0, 2).join('-');
                let splitSring2 = auxValue.split('-');
                // Úne las primeras dos partes
                let newConfigKey2 = splitSring2.slice(2, 4).join('-');
                config_keyAux = `${newConfigKey}-${newConfigKey2}`
                setRango(splitSring2.slice(-1)[0])
            }
        }
        setVarState({
            ...varState,
            values: config_keyAux
        })
    }

    return (
        <Box>

            {/* LISTA DE NODOS */}
            <ListNodeSelect
                diagramDynamic={true}
                isOpen={openModalDevice}
                setIsOpen={setIsOpenModalDevice}
                setLocation={setNodeSelect}
                location={nodeSelect}
            />

            {/* TITULO */}
            {/* <Box m={1} >
                <h3 style={{ fontSize: '18px' }}>  Configuración de Variable.</h3>
            </Box > */}

            {/* STEPPERS */}
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{isMobile ? '' : label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {blueLine}

            <Box className={classes.root}>
                {/* STEP 1 */}
                <Box display="flex" alignItems="center" flexWrap="wrap" minHeight={60} >
                    <Tooltip
                        title="Elija el dispositivo que desea añadir, haciendo click en el botón +"
                        placement="right"
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: varState?.nodes?.length > 0 })}  >
                            {varState?.nodes?.length > 0 ? <DoneIcon style={{ padding: '2px' }} /> : 1}
                        </Box>
                    </Tooltip>

                    <Box className={classes.devicesLabel}>
                        Agregar dispositivo
                    </Box>

                    <IconButton
                        aria-label="agregar dispositivos"
                        className={classes.addBtn}
                        onClick={handleOpenCloseModalDevices}
                    >
                        <AddIcon />
                    </IconButton>

                    {nodeSelect?.nombre &&
                        <Chip
                            className={classes.filterChip}
                            icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                            label={nodeSelect.nombre}
                            clickable
                            color="primary"
                            onDelete={() => setNodeSelect({})}
                            onClick={handleOpenCloseModalDevices}
                        />
                    }

                </Box>

                {blueLine}

                {/* STEP 2 */}
                <Box display={'flex'}>
                    <Tooltip
                        title='Elija una variable'
                        arrow
                        placement='right'
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: varState?.values })}  >
                            {varState?.values ? <DoneIcon style={{ padding: '2px' }} /> : 2}
                        </Box>
                    </Tooltip>

                    <Box width={'90%'} >
                        <Box className={classes.devicesLabel}>
                            Seleccione una variable
                        </Box>
                        {/* Selector q agrupa las variables dinamico */}
                        <DynamicSelect
                            handleChangeProp={handleChangeVariable}
                            valueSelect={stateForm.variable || ''}
                            keyName={'variable'}
                            InputLabelProp={'Variable principal a graficar'}
                            section={'ALERTS_VARIABLES'}
                            showUnit={true}
                        />
                    </Box>
                </Box>

                {blueLine}

                <Box display={'flex'}>
                    {/* Opcion de granularidad */}
                    {granularity?.length > 0 &&
                        <Box ml={5} mt={2} width={'90%'} >

                            <Box display='flex' alignItems='center'>
                                <FormControl >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={disabledGranularityOptions}
                                                onChange={handleCheckBoxGranularityOptions}
                                                disabled={!disabledCheckBoxGranularityOptions}
                                                color='primary'
                                                name='report_automatic'
                                            />
                                        }
                                        label={`${disabledGranularityOptions ? 'Habilitar' : 'Deshabilitar'} granularidad y periodo.`}
                                        classes={{ label: classes.LabelDisabledGranularityOptions }} // Asigna la clase al label
                                    />
                                </FormControl>
                            </Box>

                            <Box display='flex' alignItems='center' flexWrap='wrap'>
                                <Box className={classes.devicesLabel} >
                                    Granularidad y periodo
                                </Box>
                            </Box>

                            <Box display='flex' justifyContent='center'>
                                <span className={classes.tabsTitle}>Granularidad</span>
                            </Box>

                            <Tabs
                                value={granularity.length > 0 ? indexSelectPeriods : 0}
                                onChange={handleGranularity}
                                indicatorColor='primary'
                                textColor='primary'
                                centered
                            >
                                {
                                    granularity?.map(period => <Tab key={period?.key} label={period?.label} disabled={disabledGranularityOptions} />)
                                }
                            </Tabs>
                            <Box>
                                <FormControl component='fieldset' fullWidth>
                                    <RadioGroup
                                        aria-label='chosen_range'
                                        name='chosen_range'
                                        value={Rango ? Rango : ''}
                                        onChange={handlePeriod}
                                        className={classes.radio}
                                    >
                                        {
                                            granularity[indexSelectPeriods]?.values?.map(option => (
                                                <FormControlLabel
                                                    control={<Radio color='primary' disabled={disabledGranularityOptions} />} // Deshabilitar Radio
                                                    key={option.key}
                                                    value={option.key}
                                                    label={option.name}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    }
                </Box>

                {blueLine}

                {/* STEP 3 */}
                <Box display={'flex'} >
                    <Tooltip
                        title='Nuevo tooltip'
                        arrow
                        placement='right'
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: varState?.variable_operation })}  >
                            {varState?.variable_operation ? <DoneIcon style={{ padding: '2px' }} /> : 3}
                        </Box>
                    </Tooltip>
                    <Box width={'81%'} >
                        <Box className={classes.devicesLabel}>
                            Tipo de operación
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                name='choice'
                                // value={opChoice}
                                value={optionSelectOperation.some(option => option.key === opChoice) ? opChoice : ''}
                                onChange={handleOpChoice}
                                disabled={disableOperatorSelect}
                            >
                                {optionSelectOperation?.map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem.key}
                                    >
                                        {elem.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {blueLine}




                {/* STEP 4 */}
                <Box mt={2} display={'flex'} width={'100%'}>
                    <Tooltip
                        title='Nuevo tooltip'
                        arrow
                        placement='right'
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: true })}  >
                            {activeStep >= 4 ? <DoneIcon style={{ padding: '2px' }} /> : 4}
                        </Box>
                    </Tooltip>

                    <Box width={'90%'} >
                        <Box className={classes.devicesLabel}>
                            Franja horaria
                        </Box>

                        <Box >
                            <FormControl>
                                <FormGroup row>
                                    {['lu', 'ma', 'mi', 'ju', 'vi', 'sa', 'do'].map((elem) => (
                                        <FormControlLabel
                                            key={elem}
                                            control={
                                                <Checkbox
                                                    value={elem}
                                                    checked={selectedDays.includes(elem)}
                                                    onChange={handleTimeSlot}
                                                    color="primary" // Se aplicará el color azul personalizado
                                                />
                                            }
                                            label={elem}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </Box>

                        <Box mt={1} display={'flex'}>
                            {/* DESDE */}
                            <Box >
                                <TextField
                                    name="time_from"
                                    type='time'
                                    label='Desde:'
                                    size='small'
                                    variant='outlined'
                                    value={selectedHoursTimeSlot?.time_from}
                                    // disabled={disabledInputFunction()}
                                    onChange={e => handleHoursTimeSlot(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: selectedHoursTimeSlot?.step,
                                        pattern: "[0-9]{2}:[0-9]{2}",
                                    }}
                                    onInput={(e) => {
                                        const input = e.target;
                                        let value = input.value;

                                        // Solo bloquear los minutos si el step es 3600 (1 hora)
                                        if (selectedHoursTimeSlot?.step === 3600 && value) {
                                            const [hours] = value.split(":");
                                            input.value = `${hours}:00`; // Fija los minutos a 00
                                            handleHoursTimeSlot(e); // Llama a la función de manejo
                                        } else {
                                            // Si el step no es 3600, permitir la modificación normal
                                            handleHoursTimeSlot(e);
                                        }
                                    }}
                                />
                            </Box>
                            {/* HASTA */}
                            <Box ml={2}>
                                <TextField
                                    name="time_to"
                                    type="time"
                                    label="Hasta:"
                                    size="small"
                                    variant="outlined"
                                    value={selectedHoursTimeSlot?.time_to}
                                    onChange={e => handleHoursTimeSlot(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: selectedHoursTimeSlot?.step,
                                        pattern: "[0-9]{2}:[0-9]{2}",
                                    }}
                                    onInput={(e) => {
                                        const input = e.target;
                                        let value = input.value;

                                        // Solo bloquear los minutos si el step es 3600 (1 hora)
                                        if (selectedHoursTimeSlot?.step === 3600 && value) {
                                            const [hours] = value.split(":");
                                            input.value = `${hours}:00`; // Fija los minutos a 00
                                            handleHoursTimeSlot(e); // Llama a la función de manejo
                                        } else {
                                            // Si el step no es 3600, permitir la modificación normal
                                            handleHoursTimeSlot(e);
                                        }
                                    }}
                                />
                            </Box>
                        </Box>

                        {
                            selectedHoursTimeSlot?.step === 3600 &&
                            < Box mt={1}>
                                La variable seleccionada solo permite rangos de una hora.
                            </Box>
                        }

                    </Box>
                </Box>

                {blueLine}

            </Box>
            {blueLine}
        </Box >
    )
}
export default VariableFilterOptions





