import React, { useState } from 'react';
// material-ui
import { Box, Button, makeStyles, Dialog, IconButton } from '@material-ui/core';
// icons
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import CancelIcon from '@mui/icons-material/Cancel';

// Custom Hooks
import useDeviceDetect from '../../hooks/useDeviceDetect';

const useStyles = makeStyles(theme => ({
  dateContainer: {
    // margin: '10px 0 0px 0px',
    '& .react-datepicker__day': {
      borderRadius: '50%',
      userSelect: 'none'
    },
    '& .react-datepicker__day--selected': {
      background: theme.palette.primary.main,
    },
    '& .react-datepicker__time-list-item': {
      userSelect: 'none'
    },

  },
  btn: {
    margin: theme.spacing(1, 1),
    fontSize: '13px',
    letterSpacing: '2px',
    minHeight: '37px',
  },
  iconDate: {
    marginLeft: '10px',
  },
  buttonReady: {
    zIndex: "2",
    backgroundColor: "#f4f4f5",
    marginBottom: "8px",
    color: '#1b1a1af5'
  },
  labelDate: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const DatepickerSingle = ({ startDate, setDate, disabledDatePicker, date }) => {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect();
  const [isOpen, setIsOpen] = useState(false);
  //Funcion para pasar a español
  registerLocale('es', es);
  //Función que escucha cambios de fecha.
  const handleDateChange = e => {
    setDate(prevState => ({
      ...prevState,
      dateFrom: e,
    }));
  };

  const handleClick = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };


  function formatDate(fecha) {
    const opciones = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(fecha).toLocaleDateString('es-AR', opciones);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        // onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <Box
          style={{
            width: "100%",
            display: 'flex',
            justifyContent: 'flex-end',
          }}>

          <IconButton
            className={classes.buttonClose}
            color='primary'
            onClick={() => setIsOpen(false)}
          >
            <CancelIcon />
          </IconButton>
        </Box>
        <Box className={classes.dateContainer}>
          <DatePicker
            onClickOutside={() => setIsOpen(false)}
            open={isOpen}
            selected={startDate}
            locale="es"
            startDate={startDate}
            onChange={handleDateChange}
            disabledKeyboardNavig
            inline
            dateFormat="Pp"
            showTimeSelect
            timeIntervals={60}
            minDate={new Date()}
            filterTime={filterPassedTime}
            timeCaption="Hora"
          />
        </Box>
        {/* Boton aceptar */}
        <Box
          style={{
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            // backgroundColor: '#f4f4f5',
          }}>
          <Button
            variant='contained'
            className={classes.buttonReady}
            size="small"
            aria-label="upload picture"
            // component="label"
            onClick={() => setIsOpen(false)} >
            Aceptar
          </Button>
        </Box>
      </Dialog>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <label>
          {startDate && (
            <span>{formatDate(startDate)}</span>
          )}
        </label>
        <Button
          disabled={disabledDatePicker ? disabledDatePicker : false}
          type="submit"
          onClick={handleClick}
          color="primary"
          variant="contained"
          className={classes.btn}
        >
          {isMobile ? 'Seleccionar' : 'Seleccione fecha y hora'}
          {!isMobile && (
            <InsertInvitationIcon className={classes.iconDate} />
          )}
        </Button>
      </Box>
    </>
  );
};
export default DatepickerSingle;
