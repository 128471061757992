import instance from './testing.instance';

export const getNotificationsNumber = () => instance.get(`/notifications/pending/`);

export const getNotificationsContent = (page) => instance.get(`/notifications/?page=${page}&page_size=10`);
//Notificacion leida.
export const checkReadNotificationRead = (id) => instance.post(`/notifications/${id}/read/`);
//Notificacion No leida.
export const checkReadNotificationUnread = (id) => instance.post(`/notifications/${id}/unread/`);
//Marcar todo como leido.
export const checkReadNotificationAll = () => instance.post(`/notifications/readall/`);

//Configuracion preferencias (permisos) Notificaciones.
export const getNotificationPreferences = () => instance.get(`/notifications/preferences/`);
//Edita la configuracion basede las notificaciones.
export const editBaseNotificationSettings = (body) => instance.put(`/notifications/preferences/base`, body);
//Edita un modulo de configuracion especifico.
export const editModuleNotificationSettings = (id, body) => instance.put(`/notifications/preferences/module/${id}/`, body);

//Agregar email referente para notificaciones.
export const addReferenceNotificationsEmail = (body) => instance.post(`/notifications/emails/`, body);
//Elimina email referente para notificaciones.
export const deleteReferenceNotificationsEmail = (idEmail, body) => instance.delete(`/notifications/emails/${idEmail}/`, body);
//Edita email referente para notificaciones.
export const editReferenceNotificationsEmail = (idEmail, body) => instance.patch(`/notifications/emails/${idEmail}/`, body);
//Trae la configuracion de las notificaciones segun cada dispositivo.
export const getNotificationPreferencesNodes = () => instance.get(`/notifications/preferences/tree`);
//Edita  la configuracion de las notificaciones segun cada dispositivo.
export const editNotificationNodeSettings = (edit_id, body) => instance.put(`/notifications/preferences/tree/edit/${edit_id}/`, body);


//Nuevo endpoint de objeto completo configuracion notificaciones (Switch).
export const editNotificationNodeSettingsObj = (body) => instance.put(`/notifications/preferences/update`, body);
