
import React, { useEffect, useState } from 'react';
//Material ui.
import {
    Box, makeStyles, FormControlLabel, FormGroup, Switch, IconButton, Tooltip
} from '@material-ui/core';
//icons
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        width: '15%',
        '@media (max-width: 1200px)': {
            width: '48%',
        },
        '@media (max-width: 850px)': {
            width: '100%',
        },
    }
}));

const SwitchComponent = ({ element, general, generalSettings, setGeneralSettings, handleOpenModalNodeNotificationSettings, setModulesData, modulesData }) => {
    const classes = useStyles();
    const [stateData, setData] = useState(element)
    const [disabledOption, setDisabledOptions] = useState(generalSettings)

    useEffect(() => {
        setDisabledOptions(generalSettings)
    }, [generalSettings])

    const handleChangeSwitch = async (e) => {
        let name = e.target.name
        let data = {
            ...stateData,
            [name]: !stateData[name]
        }
        setData(data)
        let referent = data
        let body = {
            app_notification: referent?.app_notification,
            telegram_notification: referent?.telegram_notification,
            email_notification: referent?.email_notification
        }
        if (general) {
            setGeneralSettings({
                ...generalSettings,
                ...body
            })
        }
        else {

            let newArray = [...modulesData].map((elem) => {
                if (elem.id === element.id) {
                    return {
                        ...elem,
                        ...body
                    }
                }
                else {
                    return elem
                }
            })
            setModulesData(newArray)
        }
    }

    //Configuracion para dibujar los selectores dinamicamente.
    let arrySettings = [
        {
            name: 'app_notification',
            label: 'App'
        },
        {
            name: 'email_notification',
            label: 'Mail'
        },
        {
            name: 'telegram_notification',
            label: 'Telegram',
        }
    ]

    //Modifica title para ciertos tipo de alertas.
    const remplaceTitle = (name) => {
        if (name === 'CortesConectividad') {
            return 'Cortes de Conectividad'
        }
        else if (name === 'CortesEnergia') {
            return 'Cortes de Energia'
        }
        return name
    }


    //Deshabilita un switch.
    const checkDisabled = (elem) => {
        if (elem.name === 'email_notification' && disabledOption?.email_notificationDisabled) {
            return true
        }
        if (general) {
            return false
        }
        else {
            return !disabledOption[elem.name]
        }
    }
    //Habilita un switch.
    const checkSwitchState = (state, elem) => {
        if (elem.name === 'email_notification' && disabledOption?.email_notificationDisabled) {
            return false
        }
        return state
    }

    return (

        <Box className={classes.root} style={general ? { paddingBottom: '20px', backgroundColor: '#d7d7d75e', border: '0.5px solid #e0e0e0' } : { marginBottom: '10px', border: '0.5px solid #e0e0e0' }} borderRadius={4} bgcolor="white" >
            <Box p={1} display='flex' >
                < Box
                    display='flex'
                    alignItems='center'
                    ml={1}
                    color="primary.main"
                    style={{ fontWeight: 'bold', fontSize: 15 }}
                >
                    {stateData.modulo?.nombre ? remplaceTitle(stateData.modulo?.nombre) : 'General'}
                </Box >
            </Box>

            {
                arrySettings.map((elem) => {
                    return (
                        <Box key={elem.name} display='flex'>
                            < Box
                                width={"30%"}
                                display='flex'
                                alignItems='center'
                                ml={2}
                                color="primary.main"
                            >
                                {elem.label}
                            </Box >
                            <Box ml={6} >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch name={elem.name}
                                                disabled={checkDisabled(elem)}
                                                checked={checkSwitchState(stateData?.[elem.name], elem)}
                                                onChange={handleChangeSwitch}
                                                color='primary' />
                                        }
                                    />
                                </FormGroup>
                            </Box>
                        </Box>
                    )
                })
            }

            {/* Icon de configuracion de notificaciones por cada dispositivo. */}
            {!general &&
                <Box display='flex'>
                    < Box
                        width={"30%"}
                        display='flex'
                        alignItems='center'
                        ml={2}
                        color="primary.main"
                    >
                        Dispositivos
                    </Box >
                    <Tooltip
                        title={'Configure notificaciones en un dispositivo determinado.'}
                        arrow
                        placement="bottom"
                    >
                        < Box ml={5.2} pb={1}          >
                            <IconButton aria-label="settings"
                                onClick={() => handleOpenModalNodeNotificationSettings(stateData.modulo?.nombre)}
                            >
                                <DeviceHubIcon fontSize="inherit" />
                            </IconButton>
                        </Box >
                    </Tooltip>
                </Box>}

        </Box >
    )
}
export default SwitchComponent