//React
import React, { useState, useEffect } from 'react';
//Material ui
import {
    Box, Button,
    makeStyles,
    InputAdornment,
    TextField, Switch, Grid,
    FormGroup, FormControlLabel
} from '@material-ui/core';
// Componentes
import InputsBox from './InputsBox'
//Servicios
import {
    createRate, editRate, deleteRate,
    createTimeZoneEnergy, createTimeZonePower,
    editTimeZoneEnergy, editTimeZonePower,
    deleteTimeZoneEnergy, deleteTimeZonePower
} from '../../services/customRates';
import {
    postNewContract, patchContract,
    postContractPower, putContractPower,
    deleteContract, deleteContractPower,
} from '../../services/billing';
import {
    editSublocalization, getNodesByIdCommonArea
} from '../../services/hierarchy';
//Styles
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    form: {
        '& .MuiInputBase-root': {
            color: '#666666',
        },
    },
    containerinputs: {
        "@media (max-width: 700px)": {
            marginRight: "5px"
        }
    },
    inputSwitch: {
        width: "200px",
        marginTop: "10px"
    },
    inputCost: {
        width: "150px",
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: theme.spacing(2),
    },
    labelCharge: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: theme.spacing(1),
    },
    btn: {
        margin: theme.spacing(3, 1),
    },
    wordCut: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    },
    inputActive: {
        backgroundColor: "#00800026",
        borderRadius: "5px",

    },
    boxInputClose: {
        display: "flex",

        "@media (max-width: 1200px)": {
            flexDirection: "column",
        }
    },
    labelboxInputClose: {
        color: "red",
        margin: "15px 0px 0px 15px",

        "@media (max-width: 1200px)": {
            marginLeft: "0"
        }
    }
}));

export default function FormSubRatesBilling({
    selectedNode, setClose,
    setMsg, newContract,
    bestRate, handleCancel, stateContract,
    option, optionRates, subHasPrivateContract,
    stateInitialContract, stateBestRate, setBestRate, setloadingBlur
}) {
    const classes = useStyles();
    const initialData = {
        name: "Unica",
        time_from: "00:00:00",
        time_to: "00:00:00",
        hired_demand_cost: 0,
        energy_cost: 0,
        peak_demand_cost: 0,
        hired_demand: 0
    }
    //Estados Inputs
    const [energy, setEnergy] = useState([initialData])
    const [power, setPower] = useState([initialData])
    //Estado Cargo Fijo
    const [fixedChargeState, setFixedChargeState] = useState(0)
    //estado q gurda las franjas horaria a eliminar.
    const [deleteTimeZoneState, setDeleteTimeZoneState] = useState(
        {
            state: false,
            arry: []
        }
    )
    //estado contrato de potencia
    const [powerContract, setPowerContract] = useState({
        name: "",
        hired_demand: 0,
        contract: "",
        time_slot: ""
    })
    //estado q cambia el switch para creacion de factura automartica
    const [switchBill, setSwitchBill] = useState(false)
    const [switchBillValue, setSwitchBillValue] = useState(0)
    const [errorSwitch, setErrorSwitch] = useState(false)
    //estado q maneja el switch del common area
    const [switchCommonArea, setSwitchCommonArea] = useState(false)

    useEffect(() => {
        if (stateContract) {
            mergeDataPower()
            setEnergy(bestRate?.energyrate)
            setFixedChargeState(bestRate?.fixed_charge)
            setPowerContract(stateContract.powercontract)
        }
        else {
            setEnergy([initialData])
            setPower([initialData])
            setFixedChargeState(0)
        }
        // eslint-disable-next-line 
    }, [option])

    useEffect(() => {
        if (stateContract?.automated === true) {
            setSwitchBill(true)
            setSwitchBillValue(stateContract?.autocalcday)
        }
        mergeCommonArea()
        // eslint-disable-next-line 
    }, [])

    const mergeCommonArea = async () => {
        if (selectedNode?.level_nombre === "sublocalización") {
            let res = await getNodesByIdCommonArea(selectedNode?.parent)
            let nodoFilter = res.data.filter((e) => {
                if (e.area_comun === true && e.id === selectedNode?.id) {
                    return e
                } else {
                    return ""
                }

            })
            if (selectedNode?.id === nodoFilter[0]?.id) {
                setSwitchCommonArea(true)
            }
        }
    }

    const mergeDataPower = () => {
        let arry = []
        bestRate?.powerrate.forEach((elem) => {
            let aux = stateContract?.powercontract.find(e => e.time_slot === elem.id)
            arry.push({
                ...elem,
                hired_demand: aux?.hired_demand
            })
        })
        setPower(arry)
    }

    //funcion q crea todas las franjas horarias de energia
    const timeZonesEnergy = async (rate) => {
        try {
            energy?.forEach(async (element) => {
                let energyBody = {
                    name: element?.name,
                    time_from: element?.time_from,
                    time_to: element?.time_to,
                    energy_cost: element?.energy_cost,
                    timezone: -3,
                    rate: rate?.data?.id
                }
                await createTimeZoneEnergy(energyBody)
            });
        }
        catch (error) {
            //alert
            setClose(true)
            setMsg({
                msg: "error en creacion de franja horaria de energia",
                type: "error"
            })
        }
    }

    //funcion q Edita todas las franjas horarias de Potencia
    const edittimeZonesEnergy = async (rate) => {
        try {
            energy?.forEach(async (element) => {
                let energyBody = {
                    name: element?.name,
                    time_from: element?.time_from,
                    time_to: element?.time_to,
                    energy_cost: element?.energy_cost,
                    timezone: -3,
                    rate: rate?.id
                }
                if (element?.id) {
                    await editTimeZoneEnergy(element.id, energyBody)
                }
                else {
                    await createTimeZoneEnergy(energyBody)
                }
            })
        }
        catch (error) {
            //alert
            setClose(true)
            setMsg({
                msg: "error en edicion de franja horaria de energia",
                type: "error"
            })
        }
    }

    //funcion q Edita todas las franjas horarias de Potencia
    const editTimeZonesPower = async (rate) => {
        try {
            power?.forEach(async (element) => {
                let powerBody = {
                    name: element?.name,
                    time_from: element?.time_from,
                    time_to: element?.time_to,
                    hired_demand_cost: element?.hired_demand_cost,
                    peak_demand_cost: element?.peak_demand_cost,
                    timezone: -3,
                    rate: rate?.id
                }
                if (element.id) {
                    await editTimeZonePower(element.id, powerBody)
                }
                else {
                    let res = await createTimeZonePower(powerBody)
                    let contractAux = newContract.id
                    //si no es un contrato nuevo.
                    if (!newContract.id) {
                        contractAux = stateContract.id
                    }
                    let objPow = {
                        contract: contractAux,
                        hired_demand: element.hired_demand,
                        name: element.name,
                        time_slot: res.data.id
                    }
                    await postContractPower(objPow)
                }
            });
        }
        catch (error) {
            //alert
            setClose(true)
            setMsg({
                msg: "error en edicion de franja horaria de potencia",
                type: "error"
            })
        }
    }
    //funcion q Edita todas las franjas horarias de Potencia
    const editTimeZonesPowerHired = async () => {
        try {
            if (powerContract.length === 0) {
                power.map(async (elem, index) => {
                    // let oldAux = power?.find((e) => e?.id === elem?.time_slot)
                    let objPow = {
                        contract: stateContract.id,
                        hired_demand: elem.hired_demand,
                        name: elem.name,
                        time_slot: elem.id
                    }
                    await postContractPower(objPow)
                })
            }
            else {
                powerContract.map(async (elem, index) => {
                    let oldAux = power?.find((e) => e?.id === elem?.time_slot)
                    let objPow = {
                        contract: stateContract.id,
                        hired_demand: oldAux.hired_demand,
                        name: elem.name,
                        time_slot: elem.time_slot
                    }
                    await putContractPower(elem.id, objPow)
                })
            }

        }
        catch (error) {
            //alert
            setClose(true)
            setMsg({
                msg: "error en edicion de franja horaria de potencia",
                type: "error"
            })
        }
    }

    const validatingChangePower = (power, powerrate) => {
        for (let i = 0; i < power.length; i++) {
            let bestRatPowerRate = powerrate[i]
            let powerAux = power[i]

            setBestRate({
                ...bestRate,
                powerrate: power,
                energyrate: energy
            })

            if (bestRatPowerRate?.name !== powerAux.name) return true
            if (bestRatPowerRate?.peak_demand_cost !== powerAux.peak_demand_cost) return true
            if (bestRatPowerRate?.hired_demand_cost !== powerAux.hired_demand_cost) return true
            if (bestRatPowerRate?.time_from !== powerAux.time_from) return true
            if (bestRatPowerRate?.time_to !== powerAux.time_to) return true
        }
        return false
    }

    const validatingChangePowerHired = (power, powerContract) => {
        for (let i = 0; i < power?.length; i++) {
            let powerAux = power[i]
            let oldAux = powerContract?.find((e) => e?.time_slot === powerAux?.id)
            if (oldAux?.hired_demand !== powerAux?.hired_demand) return true
        }
        return false
    }

    const deleteTimeZoneFunction = async (arry, type) => {
        arry.forEach(async (elem) => {
            //si es franja horaria de energia
            if (type === 'energy' && elem?.id) {
                // console.log("elimino energia")
                await deleteTimeZoneEnergy(elem.id)
            }
            //si es franja horaria de potencia
            if (type === 'power' && elem?.id) {
                // let powerContractAux = powerContract?.find((e) => e?.time_slot === elem?.id)
                await deleteTimeZonePower(elem?.id)
                await deleteContractPower(elem.id)
            }
        })
    }
    //funcion para calular las horas entre dos valores.
    function hoursBetween(time1, time2) {
        let minutes1 = parseInt(time1.split(":")[0]) * 60 + parseInt(time1.split(":")[1]);
        let minutes2 = parseInt(time2.split(":")[0]) * 60 + parseInt(time2.split(":")[1]);
        let minutesDiff = minutes2 - minutes1;

        if (minutesDiff < 0) {
            minutesDiff = 1440 + minutesDiff;
        }
        return minutesDiff / 60;
    }
    //funcion q valida q sean ocupadas las 24 h del dia entre las franjas horarias.
    const validateDates = () => {
        let powerAux = 0
        let energyAux = 0
        let text = ""
        let check = {
            pow: false,
            ener: false
        }
        power.forEach((elem) => {
            let from = elem.time_from
            let to = elem.time_to
            let hours = hoursBetween(from, to)
            powerAux += hours
        })
        energy.forEach((elem) => {

            let from = elem.time_from
            let to = elem.time_to
            let hours = hoursBetween(from, to)
            energyAux += hours
        })

        if (powerAux >= 23.90 && powerAux <= 24) {
            check.pow = true
        }
        else {
            text += " de potencia"
        }
        if (energyAux >= 23.90 && energyAux <= 24) {
            check.ener = true
        }
        else {
            text += " de energia"
        }
        if (check.ener && check.pow) {
            return true
        }
        else {
            setClose(true)
            setMsg({
                msg: `Debe completar las 24 hs del dia en las franjas horarias ${text}. ${"  "}  Ejemplo :  00:00 ; 23:59 `,
                type: "error"
            })
            return false
        }
    }
    const validateForm = () => {
        let errorForm = false
        let mensaje = ""
        if (fixedChargeState < 1) {
            errorForm = true
            mensaje += ` El cargo fijo debe ser mayor a "0", `
        }
        if (errorForm) {
            setClose(true)
            setMsg({
                msg: mensaje,
                type: "error"
            })
            return false
        }
        else {
            return true
        }
    }


    //Agrega otro inputBox Component Power
    const addInputBoxPower = () => {
        if (power?.length < 3) {
            setPower([
                ...power,
                initialData
            ])
        }
    }
    //Agrega otro inputBox Component energy
    const addInputBoxEnergy = () => {
        if (energy?.length < 3) {
            setEnergy([
                ...energy,
                initialData
            ])
        }
    }
    //Evento Cancelar
    const cancel = () => {
        handleCancel()
    }
    //Evento Eliminar Tarifa
    const deleteRateFunction = async () => {
        try {
            await deleteContract(stateContract.id);
            await deleteRate(bestRate.id)
            setClose(true)
            setMsg({
                msg: "Se elimino su Contrato personalizado correctamente",
                type: "success"
            })
        }
        catch (error) {
            // console.log("error en deleteRateFunction")
            setClose(true)
            setMsg({
                msg: error.response.data.msg,
                type: "error"
            })
        }
        handleCancel()
    }

    //funcion q elimina el componente.
    const deleteElement = async (idx, potencia) => {
        try {
            let type = "energy"
            let setData = setEnergy
            let data = energy
            if (potencia) {
                setData = setPower
                data = power
                type = "power"
            }

            if (data.length > 1) {
                setData(
                    data.filter((e, i) => i !== idx)
                )
                let elementDelete = data.filter((e, i) => i === idx)
                setDeleteTimeZoneState({
                    ...deleteTimeZoneState,
                    state: true,
                    type: type,
                    arry: [
                        ...deleteTimeZoneState.arry,
                        ...elementDelete
                    ]
                })
            }
            else {
                setClose(true)
                setMsg({
                    msg: "No puede tener menos de una franja horaria",
                    type: "error"
                })
            }
        }
        catch (error) {

            setClose(true)
            setMsg({
                msg: "error en eliminacion de contrato y franja de potencia",
                type: "error"
            })
        }
    }
    //retorna html linea azul q divide bloques de franjas.
    const blueBar = () => {
        return (
            <div style={{
                backgroundColor: "#2586bc",
                height: "1px ",
                width: " 100 %",
                marginTop: "10px"
            }}></div>
        )
    }
    const disabledInputFunction = () => {
        return option === "Default" && selectedNode?.level_nombre === "sublocalización"
    }
    //function q cambia el background color a verde cuando el input esta activo
    const backgroundGreen = (disabled) => {
        if (disabled === false) {
            return classes.inputActive
        }
        else {
            return ""
        }
    }
    //function que selecciona el contrato default
    const selectDefaultContract = async () => {
        try {
            await deleteContract(stateInitialContract.id);
            await deleteRate(stateBestRate.id)
            setClose(true)
            setMsg({
                msg: "Se elimino su Contrato personalizado correctamente",
                type: "success"
            })
        }
        catch (error) {
        }
    }
    //handle input cargo fijo
    const handleInputFixedCharge = (e) => {
        setFixedChargeState(e.target.value)
    }

    //Handle switch creacion de factura automatica
    const handleSwitch = () => {
        setSwitchBill(!switchBill)
    }
    //hanle switch para cambiar a area comun
    const handleSwitchCommonArea = async () => {
        //Cambio a area comun
        if (selectedNode?.level_nombre === "sublocalización") {
            let bodyCommonArea = { "area_comun": !switchCommonArea }
            await editSublocalization(selectedNode?.id, bodyCommonArea)
            setClose(true)
            setMsg({
                msg: "Se modifico la opcion de  areas comunes",
                type: "success"
            })
        }
        setSwitchCommonArea(!switchCommonArea)
    }
    //maneja el estado del input de cierre de factura automatico
    const handleInputCloseBill = (e) => {
        if (e.target.value > 28) {
            setErrorSwitch(true)
        }
        else {
            setSwitchBillValue(e.target.value)
            setErrorSwitch(false)
        }
    }
    //Evento Guardar
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloadingBlur(true)
        //hago todas las validaciones!
        if (validateForm() && validateDates()) {
            if (stateContract === null) {
                // console.log("Creacion de contrato Nuevo ")
                let deletCatch = ""
                try {
                    let typeRate = ""
                    let defaultAux = true
                    if (option === "Default") {
                        typeRate = optionRates?.DefaultSubTarifacion?.id
                    }
                    if (option === "Personalizada") {
                        defaultAux = false
                        typeRate = optionRates?.SubRate?.id
                    }
                    //Body de la tarifa
                    let body =
                    {
                        date_from: "2022-12-05",
                        date_to: "2022-12-05",
                        fixed_charge: fixedChargeState,
                        public: false,
                        default: defaultAux,
                        t_type: typeRate,
                        jerarquia: selectedNode?.id
                    }
                    //CREO tarifa
                    let rate = await createRate(body)
                    deletCatch = rate.data.id
                    //CREO las franjas horarias de ENERGIA  
                    await timeZonesEnergy(rate)
                    //NECESITO CREAR LA TARIFA PRIMERO PARA CREAR EL CONTRATO
                    // body de el contrato 
                    let newContractRes = {
                        name: "Contrato Default",
                        date_from: "2022-12-01",
                        date_to: "2022-12-05",
                        location: selectedNode?.id,
                        rate: rate.data.id,
                        default: defaultAux,
                        automated: false,
                        autocalcday: 1
                    }
                    if (selectedNode?.level_nombre === "localización") {
                        newContractRes.automated = switchBill
                        newContractRes.autocalcday = switchBillValue
                    }
                    //CREO CONTRATO.
                    let aux = await postNewContract(newContractRes)
                    // SI EL CONTRATO ES NUEVO SE COLOCA ACA SE AGREGA LA PRIPIEDAD  time_slot:
                    // time_slot:es el id de las franjas de porencia q ya cree.
                    //CREO las franjas horarias de POTENCIA junto con los COSTOS de POTENCIA
                    power?.map(async (element) => {
                        let powerBody = {
                            name: element.name,
                            time_from: element.time_from,
                            time_to: element.time_to,
                            hired_demand_cost: element.hired_demand_cost,
                            peak_demand_cost: element.peak_demand_cost,
                            timezone: -3,
                            rate: rate?.data?.id
                        }
                        //creo carga de potencia
                        let res = await createTimeZonePower(powerBody)
                        //creo cargo de franja fijo de potencia
                        let objPow = {
                            contract: aux.data.id,
                            hired_demand: element?.hired_demand,
                            name: element.name,
                            time_slot: res.data.id
                        }

                        await postContractPower(objPow)
                    });
                    setClose(true)
                    setMsg({
                        msg: "Se creo correctamente su contrato y su tarifa personalizada",
                        type: "success"
                    })
                }
                catch (error) {
                    await deleteRate(deletCatch)
                    // console.log("error en creacion de contrato y tarifa personalizada")
                    setClose(true)
                    setMsg({
                        msg: error.response.data.msg,
                        type: "error"
                    })
                }
            }
            else {
                // console.log("Modificar contrato Existente")
                try {
                    //   si cambio el switch.
                    if (selectedNode?.level_nombre === "localización") {
                        let body = {
                            automated: switchBill,
                            autocalcday: switchBillValue
                        }
                        await patchContract(stateContract?.id, body)
                    }
                    //si cambio el cargo fijo hacemos un patch solom modificando ese dato en la tarifa correspondiente.
                    if (fixedChargeState !== bestRate.fixed_charge) {
                        // console.log("cambio cargo fijo")
                        //Body de la tarifa
                        let body =
                        {
                            fixed_charge: fixedChargeState,
                        }
                        await editRate(bestRate.id, body)
                    }
                    //si cambio algun valor de la FRANJA DE ENERGIA modifico ese dato correspondiente.
                    if (energy !== bestRate.energyrate) {
                        // console.log("entro en franja de energia")
                        await edittimeZonesEnergy(bestRate)
                    }
                    //Si cambio algun valor de FRANJA DE POTENCIA modifico ese dato correspondiente.
                    if (validatingChangePower(power, bestRate.powerrate)) {
                        // console.log("entro en franja de potencia")
                        await editTimeZonesPower(bestRate)

                    }
                    //Si cambio algun valor de FRANJA DE POTENCIA CONTRATADA modifico ese dato correspondiente.
                    if (validatingChangePowerHired(power, powerContract)) {
                        // console.log("entro franja de potencia contratada")
                        await editTimeZonesPowerHired()
                    }
                    setClose(true)
                    setMsg({
                        msg: "Se modifico contrato correctamente",
                        type: "success"
                    })
                }
                catch (error) {
                    // console.log("error en creacion de contrato y tarifa personalizada")
                    setClose(true)
                    setMsg({
                        msg: error.response.data.msg,
                        type: "error"
                    })
                    return
                }
            }
            //si alguna franja de potencia fue eliminada las elimino si paso la validacion de fechas!
            if (deleteTimeZoneState.state) {
                // console.log("borrar")
                await deleteTimeZoneFunction(deleteTimeZoneState.arry, deleteTimeZoneState?.type)
                setDeleteTimeZoneState(
                    {
                        state: false,
                        arry: []
                    }
                )
            }
        }
        setTimeout(() => {
            setloadingBlur(false)
        }, 800);
    }

    return (
        <>
            <form onSubmit={e => handleSubmit(e)} className={classes.form}>

                {/* CARGO FIJO INPUT */}
                <Box style={{ display: "flex", marginBottom: "20px" }}>
                    <Box mr={6} component='label' display='block' className={classes.labelCharge}>
                        Cargo Fijo
                    </Box>
                    <TextField
                        className={clsx(classes.inputCost, classes.input, backgroundGreen(disabledInputFunction()))}
                        autoFocus
                        type="number"
                        placeholder='$'
                        name='name'
                        value={fixedChargeState}
                        disabled={disabledInputFunction()}
                        onChange={(e) => { handleInputFixedCharge(e) }}
                        color='primary'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>$</InputAdornment>,
                        }}
                    />
                </Box>

                {/* SWITCH DE CIERRE DE FACTURA AUTOMATICO */}
                {option !== "Personalizada" &&
                    <Grid >
                        <div className={classes.filterLabel}>Creacion de facturas automatico</div>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch name='showPhase'
                                        disabled={disabledInputFunction()}
                                        checked={switchBill}
                                        onChange={handleSwitch}
                                        color='primary' />
                                }
                            />
                        </FormGroup>
                        {switchBill &&
                            <Box
                                className={classes.boxInputClose}
                            >
                                <TextField
                                    type="number"
                                    size='small'
                                    variant='outlined'
                                    label="Dia de cierre"
                                    color='primary'
                                    value={switchBillValue}
                                    disabled={disabledInputFunction()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: "0",
                                        max: "28"
                                    }}
                                    className={clsx(classes.inputSwitch, backgroundGreen(disabledInputFunction()))}
                                    onChange={handleInputCloseBill}
                                />
                                {
                                    errorSwitch &&
                                    <label
                                        className={classes.labelboxInputClose}
                                    >
                                        No puede seleccionar un dia de cierre mayor a 28
                                    </label>
                                }
                            </Box>
                        }
                    </Grid>
                }
                {/* SWITCH DE AREA COMUN */}
                {
                    selectedNode?.level_nombre === "sublocalización" &&
                    <>
                        <div className={classes.filterLabel}>Area comun</div>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch name='showPhase'
                                        // disabled={disabledInputFunction()}
                                        checked={switchCommonArea}
                                        onChange={handleSwitchCommonArea}
                                        color='primary' />
                                }
                            />
                        </FormGroup>
                    </>

                }
                <Box className={classes.containerinputs}>

                    {/* Linea azul q separa bloques */}
                    {blueBar()}

                    {/*   Franjas Horarias de Energia  */}
                    <Box mb={2} component='label' display='block' className={classes.label}>
                        Franjas Horarias de Energia
                    </Box>

                    {/*   INPUTS ENERGIA  */}
                    {energy?.map((element, idx) => {
                        return (
                            <InputsBox
                                option={option}
                                deleteElement={deleteElement}
                                key={idx}
                                setData={setEnergy}
                                deleteTimeZone={deleteTimeZoneEnergy}
                                data={energy}
                                element={element}
                                idx={idx}
                                selectedNode={selectedNode}
                            />
                        )
                    })}
                    {
                        disabledInputFunction() ||
                        <Button color='primary' variant='contained' onClick={addInputBoxEnergy} >
                            Añadir
                        </Button>
                    }

                    {/* Linea azul q separa bloques */}
                    {blueBar()}

                    {/*   Franjas Horarias de Potencia  */}
                    <Box mb={2} component='label' display='block' className={classes.label}>
                        Franjas Horarias de Potencia
                    </Box>

                    {/*   INPUTS POTENCIA */}
                    {power?.map((element, idx) => {
                        return (
                            <InputsBox
                                option={option}
                                deleteElement={deleteElement}
                                key={idx}
                                deleteTimeZone={deleteTimeZonePower}
                                potencia={true}
                                setData={setPower}
                                data={power}
                                element={element}
                                idx={idx}
                                selectedNode={selectedNode}
                            />
                        )
                    })}
                    {
                        disabledInputFunction() ||
                        <Button color='primary' variant='contained' onClick={addInputBoxPower}  >
                            Añadir
                        </Button>
                    }

                    {/* Linea azul q separa bloques */}
                    {blueBar()}

                    {/*   BUTTONS  */}
                    {
                        option === "Default" && selectedNode?.level_nombre === "sublocalización" && subHasPrivateContract &&
                        <Box display='flex' justifyContent='center'>
                            <Button color='primary' onClick={selectDefaultContract} variant='contained' className={classes.btn} >
                                Guardar
                            </Button>
                        </Box>
                    }
                    {
                        disabledInputFunction() ||
                        <Box display='flex' justifyContent='center'>
                            <Button type='submit' color='primary' variant='contained' className={classes.btn} >
                                Guardar
                            </Button>
                            <Button variant='contained' onClick={cancel} className={classes.btn}>
                                Cancelar
                            </Button>
                            {
                                selectedNode?.level_nombre === "sublocalización" && option === "Personalizada" && stateContract?.id &&

                                < Button variant='contained' onClick={deleteRateFunction} color='secondary' className={classes.btn}>
                                    Eliminar
                                </Button>
                            }
                        </Box>
                    }
                </Box>
            </form>
        </>
    );
}




