import axios from 'axios';
import { getTokenFromLocalStorage, deleteTokenFromLocalStorage, deleteUserInfoFromLocalStorage, deleteDefaultDeviceFromLocalStorage, deleteNodesFromLocalStorage, deleteNodesListFromLocalStorage, deleteTagsFromLocalStorage, deleteFilterOptionsFromLocalStorage } from './auth';
// helpers
import { chekUrlPublic } from '../components/DynamicDashboard/helpers';
const { REACT_APP_API_URL } = process.env;
const baseURL = REACT_APP_API_URL;
const instance = axios.create({
	baseURL,
});

// Establezco el token para los requests
instance.interceptors.request.use(function (config) {
	const token = getTokenFromLocalStorage();
	config.headers.Authorization = token ? `Token ${token}` : '';
	return config;
});

instance.interceptors.response.use(
	response => response,
	error => {
		// Si obtuvimos un status 401 (unauthorized) con el token utilizado, elimino el localStorage (con lo cual se considera al usuario deslogueado)
		if (!chekUrlPublic()) {
			if (error.response.status === 401) {
				deleteTokenFromLocalStorage();
				deleteUserInfoFromLocalStorage();
				deleteDefaultDeviceFromLocalStorage();
				deleteNodesFromLocalStorage();
				deleteNodesListFromLocalStorage();
				deleteTagsFromLocalStorage();
				deleteFilterOptionsFromLocalStorage();

				console.error(error);
				return window.location.href = '/login'
			} else {			
				return Promise.reject(error);
			}
		}
	}
);

export default instance;
