// RouteListener.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//Servicios
import { statisticsPath } from './services/statistics';

const RouteListener = ({ plan }) => {
    const location = useLocation();
    useEffect(() => {
        const statisticsPathAux = () => {
            try {
                let body = {
                    section: location.pathname
                }
                if (plan?.info?.client_id) {
                    statisticsPath(body)
                }
            }
            catch (error) {
            }
        }
        statisticsPathAux()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return null;
};

export default RouteListener;