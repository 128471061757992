import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import { mixColors } from '../../helpers/common';
//Helpers
import { formattingSeries } from '../Instant/helpers';

export default function ChartV2({
  chartData,
  chartOptions,
  height,
  ky,
  dashboard,
  showLegend = false,
  color = "default"
}) {
  useEffect(() => {
    const { variable } = chartOptions;

    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(`historicos${ky}`, am4charts.XYChart);
    //Linea para q el logo de amchard no aparezca
    if (chart.logo) {
      chart.logo.disabled = true;
    }
    chart.fontSize = 11;
    chart.language.locale = am4lang_es_ES;
    // chart.paddingRight = 20;

    if (!dashboard) {
      chart.exporting.menu = new am4core.ExportMenu();
      //Es requisito tener cargado a chat.data aunque yo luego uso series specific data
      chart.exporting.adapter.add('data', function (data, target) {
        // Assemble data from series
        const myData = [];
        chart.series.each(function (series) {
          for (var i = 0; i < series.data.length; i++) {
            series.data[i].name = series.name;
            myData.push(series.data[i]);
          }
        });
        return { data: myData };
      });
      // Modifico el menu de exportación
      chart.exporting.menu.items = [
        {
          label: '...',
          menu: [
            { type: 'pdf', label: 'PDF' },
            { type: 'csv', label: 'CSV' },
            { type: 'xlsx', label: 'XLSX' },
            { type: 'print', label: 'Imprimir' },
          ],
        },
      ];
    }
    //Muestra las leyendas.
    if (showLegend) {
      chart.legend = new am4charts.Legend();
    }

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.dataFields.category = 'timestamp';
    // chart.dateFormatter.inputDateFormat =
    //   granularity === 'anio' ? 'yyyy' : 'yyyy-MM-dd';
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.max = 30;
    // valueAxis.min = -20;
    // valueAxis.strictMinMax = true;
    //Para que el grafico pueda calcular automaticamente los totales
    valueAxis.extraMax = 0.1;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    chartData.forEach((serie, index) => {
      const nodeName = serie.node?.nombre;
      const tagName = serie.tags?.length > 0 ? serie.tags[0].nombre : '';
      const nodeColor = color === 'default' ? serie.node?.color : color;
      const tagColor = serie.tags?.length > 0 ? serie.tags[0].color : '';
      const chartColor = mixColors(nodeColor, tagColor);
      const serieName = tagName ? nodeName + ' + ' + tagName : nodeName;
      
      switch (variable) {
        case 'POTENCIA_ADQUIRIDA_MAXIMA':
          valueAxis.title.text = 'Potencia adquirida maxima';
          chart.numberFormatter.numberFormat = '#.##aw';
          addSeries(
            serieName,
            serie.data.kw_max?.map(item => ({
              ...item,
              valor: item.valor * 1000,
            })),
            chartColor
          );
          break;

        case 'ENERGIA_ACTIVA_POR_FASE':
          valueAxis.title.text = 'Energía Activa';
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            'Fase R (' + serieName + ')',
            serie?.data?.kwh_r?.map(item => ({
              ...item,
              valor: item.valor * 1000,
            })),
            chartColor
          );
          if (serie.medidores[0] && serie.medidores[0]?.count_phases > 1) {
            addSeries(
              'Fase S (' + serieName + ')',
              serie.data.kwh_s?.map(item => ({
                ...item,
                valor: item.valor * 1000,
              })),
              chartColor + '60'
            );
          }
          if (serie.medidores[0] && serie.medidores[0]?.count_phases > 2) {
            addSeries(
              'Fase T (' + serieName + ')',
              serie.data.kwh_t?.map(item => ({
                ...item,
                valor: item.valor * 1000,
              })),
              chartColor + '90'
            );
          }
          break;

        case 'ENERGIA_ACTIVA_TOTAL':          
          valueAxis.title.text = 'Energía Activa';
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            serieName,
            serie.data.kwh?.map(item => ({
              ...item,
              valor: item.valor * 1000,
            })),
            chartColor
          );
          break;

        case 'ENERGIA_REACTIVA_POR_FASE':
          valueAxis.title.text = 'Energía Reactiva';
          chart.numberFormatter.numberFormat = '#.##aVar';
          addSeries(
            'Fase R (' + serieName + ')',
            serie.data.kvarh_r?.map(item => ({
              ...item,
              valor: item.valor * 1000,
            })),
            chartColor
          );
          addSeries(
            'Fase S (' + serieName + ')',
            serie.data.kvarh_s?.map(item => ({
              ...item,
              valor: item.valor * 1000,
            })),
            chartColor + '60'
          );
          addSeries(
            'Fase T (' + serieName + ')',
            serie.data.kvarh_t?.map(item => ({
              ...item,
              valor: item.valor * 1000,
            })),
            chartColor + '90'
          );
          break;

        case 'ENERGIA_REACTIVA_TOTAL':
          valueAxis.title.text = 'Energía Reactiva';
          chart.numberFormatter.numberFormat = '#.##aVar';
          addSeries(
            serieName,
            serie.data.kvarh?.map(item => ({
              ...item,
              valor: item.valor * 1000,
            })),
            chartColor
          );
          break;

        case 'COS_(FI)_POR_FASE':
          valueAxis.title.text = 'cos_fi';
          chart.numberFormatter.numberFormat = '#.##';
          addSeries(
            'Fase R (' + serieName + ')',
            serie.data.cos_fi_r,
            chartColor
          );
          addSeries(
            'Fase S (' + serieName + ')',
            serie.data.cos_fi_s,
            chartColor + '60'
          );
          addSeries(
            'Fase T (' + serieName + ')',
            serie.data.cos_fi_t,
            chartColor + '90'
          );
          break;

        case 'COS_(FI)_TOTAL':
          valueAxis.title.text = 'cos_fi';
          chart.numberFormatter.numberFormat = '#.##';
          addSeries(serieName, serie.data.cos_fi, chartColor);
          break;

        case 'TAN_(FI)_POR_FASE':
          valueAxis.title.text = 'tan_fi';
          chart.numberFormatter.numberFormat = '#.##';
          addSeries(
            'Fase R (' + serieName + ')',
            serie.data.tan_fi_r,
            chartColor
          );
          addSeries(
            'Fase S (' + serieName + ')',
            serie.data.tan_fi_s,
            chartColor + '60'
          );
          addSeries(
            'Fase T (' + serieName + ')',
            serie.data.tan_fi_t,
            chartColor + '90'
          );
          break;

        case 'TAN_(FI)_TOTAL':
          valueAxis.title.text = 'tan_fi';
          chart.numberFormatter.numberFormat = '#.##';
          addSeries(serieName, serie.data.tan_fi, chartColor);
          break;

        case 'ENERGIA_ACTIVA_IMPORTADA_TOTAL':
          valueAxis.title.text = 'Energía activa importada total';
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            serieName,
            formattingSeries(serie.data.kwh_i, variable, 'HISTORICOS'),
            chartColor
          );
          break;

        case 'ENERGIA_ACTIVA_EXPORTADA_TOTAL':
          valueAxis.title.text = 'Energía activa exportada total';
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            serieName,
            formattingSeries(serie.data.kwh_e, variable, 'HISTORICOS'),
            chartColor
          );
          break;

        case 'ENERGIA_ACTIVA_GENERADA_TOTAL':
          valueAxis.title.text = 'Energía activa generada total';
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            serieName,
            formattingSeries(serie.data.kwh_g, variable, 'HISTORICOS'),
            chartColor
          );
          break;

        case 'ENERGIA_ACTIVA_CONSUMIDA_TOTAL':
          valueAxis.title.text = 'Energía activa consumida total';
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            serieName,
            formattingSeries(serie.data.kwh_c, variable, 'HISTORICOS'),
            chartColor
          );
          break;

        case 'ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL':
          valueAxis.title.text = 'Energía activa autoconsumida total';
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            serieName,
            formattingSeries(serie.data.kwh_ac, variable, 'HISTORICOS'),
            chartColor
          );
          break;

        case 'ENERGIA_ACTIVA_AUTOCONSUMIDA_IMPORTADA_TOTAL':
          valueAxis.title.text = 'Energía consumida/importada';
          valueAxis.min = 0;
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            'Energía autoconsumida',
            formattingSeries(serie.data.kwh_ac, variable, 'HISTORICOS'),
            '#B4DAFF'
          );
          addSeries(
            'Importada',
            formattingSeries(serie.data.kwh_i, variable, 'HISTORICOS'),
            '#FFAEAE'
          );
          break;

          case 'ENERGIA_ACTIVA_TOTAL_ECOLOGICO':                
          valueAxis.title.text = 'Energía Activa';
          chart.numberFormatter.numberFormat = '#.##aWh';
          addSeries(
            'Energía activa total',
            formattingSeries(serie.data.kwh, variable, 'HISTORICOS'),
            '#FFAEAE'
          );
          break;
        default:
          break;
      }
    });
    function addSeries(serieName, serieData, serieColor) {      
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(80);
      series.dataFields.valueY = 'valor';
      series.dataFields.dateX = 'timestamp';
      series.data = serieData;
      series.name = serieName;
      //Apila las lineas.
      series.stacked = true;
      // Configuro orientación flecha tooltip
      series.stroke = am4core.color(serieColor);
      series.columns.template.fill = am4core.color(serieColor);
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltipText = `{valueY.value} - ${serieName}`;
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = series.columns.template.fill.alternative;
      chart.seriesContainer.zIndex = 10;
      //Valor en Legend
      series.legendSettings.labelText = serieName;
      series.legendSettings.valueText = '{valueY}';
      // if (chart.series.length === 1 && !dashboard) {
      // 	// Quiero mostrar el total en la parte superior.
      // 	const bullet = series.bullets.push(new am4charts.LabelBullet());
      // 	bullet.label.text = '{valueY.sum}!!!';
      // 	bullet.adapter.add('y', function (y) {
      // 		return -20;
      // 	});
      // 	bullet.label.fontSize = 10;
      // 	//Sin esta línea no veo los valores
      // 	chart.maskBullets = false;
      // 	//Para que no se vean recortados
      // 	chart.paddingTop = 25;
      // }
    }
    chart.cursor = new am4charts.XYCursor();

    //Oculto la línea del cursor
    chart.cursor.strokeWidth = 0;
    // chart.cursor.maxTooltipDistance = 200;
    //chart.cursor.snapToSeries = [series, series2, series3];
    // chart.cursor.maxTooltipDistance = 1500;
    chart.strokeWidth = 1;
    return () => {
      // console.log('Dispose Chart');
      chart.dispose();
    };
    // eslint-disable-next-line
  }, [chartData, chartOptions]);

  const marginT = dashboard => {
    if (!dashboard) {
      return '14px';
    }
  };
  return (
    <Box style={{ height: height }}>
      <div
        id={`historicos${ky}`}
        style={{ height: height, marginTop: marginT(dashboard), width: '96%' }}
      />
    </Box>
  );
}
