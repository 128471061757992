import { useEffect, useState } from 'react';
import {
    Box, Button, CircularProgress,
    Grid, makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import TopNav from '../../common/TopNav';
import Container from '../../Elements/Container';
import IndictorCard from '../ComponentsDashboards/IndicatorCard';
import Filter from '../SummaryLocation/Filter'
import AlertComponent from '../../Elements/AlertComponent';
import clsx from 'clsx';
import { getFormattedNumber, getUnit } from '../../../helpers/common';
import ContainerCards from '../ComponentsDashboards/ContainerCards';
import ContainerDiferenceBar from '../ComponentsDashboards/ContainerDiferenceBar';
import { getHistoryResponseV2, getHistoryV2 } from '../../../services/meters';
import ChartV2 from '../../Historical/ChartV2';
import BoltIcon from '@mui/icons-material/Bolt';
import CardNodeData from '../ComponentsDashboards/CardNodeData';
import { getExtraInfo } from '../../../services/hierarchy';

const useStyles = makeStyles(theme => ({
    contanerMain: {
        height: "90vh",
        padding: theme.spacing(0.5),
        '@media (max-width: 1200px)': {
            height: "100%",
        },
        '@media (max-height: 900px)': {
            height: "100%",
        }

    },
    containerRow: {
        height: "33%",
        display: "flex",
        '@media (max-width: 1200px)': {
            flexWrap: 'wrap'
        }
    },
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    boxes: {
        border: "1px solid #cbc1c157",
        boxShadow: '3px 3px 3px #cbc1c157',
        margin: theme.spacing(0.4),
    },
    smallBoxes: {
        width: "25%",
        '@media (max-width: 1200px)': {
            width: "49%",
        },
        '@media (max-width: 700px)': {
            width: "100%",
        },
        overflow: 'hidden'
    },
    longBoxes: {
        width: "50%",
        '@media (max-width: 1200px)': {
            width: "100%",
            height: '300px'
        }
    },
    consumedEnergyLabel: {
        color: '#4672ac',
        display: 'flex',
        width: '60%',
        alignItems: 'center'
    },
    title: {
        fontSize: theme.spacing(2.2),
        margin: theme.spacing(0, 0, 1, 3),
        fontWeight: '600',
        paddingTop: theme.spacing(1.5),
        color: theme.palette.grey.hairline,
    },
}));

export default function Ecological() {
    const classes = useStyles();
    //estado para abrir el panel del filtro
    const [filterPanel, setFilterPanel] = useState(true);
    const [location, setLocation] = useState({});
    //estado q muestra page principal o alert de bienvenida
    const [showMain, setShowMain] = useState(false);
    //Loading principal
    const [loadingMain, setLoadingMain] = useState(false);
    //estado general de data.
    // const [stateData, setStateData] = useState({});
    //Estados alert
    const [close, setClose] = useState(false)
    const [msg] = useState({
        msg: "",
        type: "error"
    })
    const [msgBarGraph] = useState({
        msg: "No se dispone de datos suficientes para mostrar el gráfico",
        type: "error"
    })
    const [solarDataDaily, setSolarDataDaily] = useState(null);
    // let optionsForBarGraphic = {
    //     variable: 'ENERGIA_ACTIVA_AUTOCONSUMIDA_IMPORTADA_TOTAL'
    // }

    const [generatedEnergy, setGeneratedEnergy] = useState(null);
    const [consumedEnergy, setConsumedEnergy] = useState(null);
    const [consumedEnergyLastMonth, setConsumedEnergyLastMonth] = useState(null);
    const [firstDayOfPreviousMonth, setFirstDayOfPreviousMonth] = useState(null);
    const [lastDayOfPreviousMonth, setLastDayOfPreviousMonth] = useState(null);
    const [summaryLocationData, setSummaryLocationData] = useState(false);
    const [optionsForBarGraphic, setOptionsForBarGraphic] = useState({ variable: 'ENERGIA_ACTIVA_AUTOCONSUMIDA_IMPORTADA_TOTAL' });

    useEffect(() => {
        const dataEcological = async () => {
            setShowMain(true)
            setLoadingMain(false)
            setGeneratedEnergy(null);
            setConsumedEnergy(null);
            setConsumedEnergyLastMonth(null);
            setSummaryLocationData(false);
            setSolarDataDaily(null);
            setOptionsForBarGraphic(null)
            //trae datos del mes actual
            await getCurrentMonthData(location?.id);
            //trae datos del mes poasado
            await getDataLastMonth(location?.id);
            //trae datos de este mes dia por dia, para alimentar grafico de barras
            await getCurrentMonthDataByDay(location?.id);
            //obtiene la fecha del primer y ultimo dia del mes anterior
            getDatesOfPreviousMonth();
            setLoadingMain(true);
        }
        //Si existe un id hago la busqueda de datos
        if (location?.id) {
            dataEcological()
        }
        // eslint-disable-next-line
    }, [location])

    const togglePanel = () => {
        setFilterPanel(!filterPanel);
    };

    const getDataLastMonth = async (id) => {
        try {
            let resLastMonth = await getHistoryResponseV2(id, 'mes_pasado', 'monthly');
            setConsumedEnergyLastMonth(resLastMonth?.data[0]?.data.kwh_c[0].valor)
        } catch (error) {
            console.error(error);
        }
    }

    const getCurrentMonthData = async (id) => {
        try {
            let resMonthly = await getHistoryResponseV2(id, 'este_mes', 'monthly');
            setConsumedEnergy(resMonthly?.data[0]?.data?.kwh_c[0].valor);
            setGeneratedEnergy(resMonthly?.data[0]?.data?.kwh_g[0].valor);
            //trae datos de la localización
            await getDataSummaryLocation(location?.id, resMonthly?.data[0]?.data?.kwh_c[0].valor)
        } catch (error) {
            console.error(error);
        }
    }

    const getCurrentMonthDataByDay = async (id) => {
        try {
            let resDaily = await getHistoryResponseV2(id, 'este_mes', 'daily');
            setOptionsForBarGraphic({
                variable: 'ENERGIA_ACTIVA_AUTOCONSUMIDA_IMPORTADA_TOTAL'
            })
            setSolarDataDaily(resDaily?.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getDatesOfPreviousMonth = () => {
        const today = new Date();
        // Retroceder un mes
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1);
        // Obtener el primer día del mes pasado
        const firstDayLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
        // Obtener el último día del mes pasado
        const lastDayLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
        // Formatear las fechas como dd/mm/yyyy
        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        setFirstDayOfPreviousMonth(formatDate(firstDayLastMonth));
        setLastDayOfPreviousMonth(formatDate(lastDayLastMonth));
    }

    const getDataSummaryLocation = async (id, consume) => {
        let res = await getExtraInfo(id);
        let data = res.data;
        data = {
            ...data,
            power_month: consume
        }
        setSummaryLocationData(data);
    }

    const calculatePercentageChange = () => {
        if (consumedEnergyLastMonth != null && consumedEnergy != null) {
            if (consumedEnergyLastMonth === 0) {
                return consumedEnergy === 0 ? 0 : 100;
            }
            const difference = consumedEnergy - consumedEnergyLastMonth;
            return (difference / consumedEnergyLastMonth) * 100;
        }
    }

    return (
        <>
            <TopNav titulo='Dashboards' subtitulo={`| Ecológico `}>
                <Grid container justifyContent='flex-end' alignItems='center'>
                    {/* Nombre Localización */}
                    <Button
                        className={classes.btn}
                        type="button"
                        onClick={togglePanel}
                        size="small"
                        startIcon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                    >
                        {location?.nombre || `Seleccionar Localización`}
                    </Button>
                </Grid>
            </TopNav>

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />
            { /* CONTENEDOR PRINCIPAL */}
            < Container >

                {/* Alert bienvenido */}
                {!showMain &&
                    <Box >
                        <Alert className={classes.alert} severity='info'>
                            Bienvenido al Dashboard Ecológico. En esta sección podrá consultar la información de sus Localizaciones para seleccionar una, haga click en
                            el botón
                            <span className={classes.alertIcon}>
                                <LabelImportantIcon style={{ fontSize: '18px' }} />
                            </span>
                            , elija la localización y haga click en Aceptar.
                        </Alert>
                    </Box>
                }

                {/* Filtro */}
                <Filter
                    isOpen={filterPanel}
                    setIsOpen={setFilterPanel}
                    setLocation={setLocation}
                    location={location}
                />

                {/* CONTAINER PAGE */}
                {showMain &&
                    <Box className={classes.contanerMain}>
                        {loadingMain ?
                            <>
                                {/* TOP */}
                                <Box className={classes.containerRow}>
                                    <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                        <ContainerCards
                                            config_key="DASHBOARDS_INDICATOR-EMISION_DE_CO2_SUB-COMPARISON-ESTE_MES-MONTHLY"
                                            id={location.id}
                                            title="Emisiones de Co2 "
                                            size={false}
                                            marginTitle={1}
                                        />
                                    </Box>
                                    <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                        <ContainerCards
                                            config_key="DASHBOARDS_INDICATOR-TONELADA_EQUIVALENTE_DE_PETROLEO_SUB-COMPARISON-ESTE_MES-MONTHLY"
                                            id={location.id}
                                            title="Tonelada de petroleo"
                                            size={false}
                                            marginTitle={1}
                                        />
                                    </Box>
                                    <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                        <IndictorCard
                                            title="Planta de |"
                                            subTitle="generacion"
                                            value={getFormattedNumber(generatedEnergy)}
                                            unity={`${getUnit(generatedEnergy)}W`}
                                            state={true}
                                            size={false}
                                            marginTitle={1}
                                            valueForGenerator={generatedEnergy}
                                        />
                                    </Box>
                                    <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                        {/* Carta datos localizacion*/}
                                        <CardNodeData
                                            stateData={summaryLocationData}
                                            location={location}
                                            showYearlyConsume={false}
                                        />
                                    </Box>
                                </Box>
                                {/* MID */}
                                <Box className={classes.containerRow}>
                                    <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                        <ContainerCards
                                            config_key="DASHBOARDS_INDICATOR-CONSUMO_POR_METRO_CUADRADO_SUB-COMPARISON-ESTE_MES-MONTHLY"
                                            id={location.id}
                                            title="Consumo por cantidad de m2"
                                            size={false}
                                            marginTitle={1}
                                        />
                                    </Box>
                                    <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                        <ContainerCards
                                            config_key="DASHBOARDS_INDICATOR-CONSUMO_POR_CANTIDAD_DE_EMPLEADOS_SUB-COMPARISON-ESTE_MES-MONTHLY"
                                            id={location.id}
                                            title="Consumo por cantidad de empleados"
                                            size={false}
                                            marginTitle={1}
                                        />
                                    </Box>
                                    {/* Grafico Historico este mes */}
                                    <Box className={clsx(classes.boxes, classes.longBoxes)}>
                                        {solarDataDaily ? <>
                                            <Box className={classes.title}>
                                                Consumos generales | <span style={{ fontWeight: '400' }}>Este mes</span>
                                            </Box>
                                            <ChartV2
                                                ky={"1"}
                                                title={"Energia"}
                                                subTitle={"de este mes"}
                                                date={"day"}
                                                id={location?.id}
                                                chartData={solarDataDaily}
                                                chartOptions={optionsForBarGraphic}
                                                height={'90%'}
                                            />
                                        </> :
                                            <Alert severity={msgBarGraph.type}>{msgBarGraph.msg}</Alert>}
                                    </Box>
                                </Box>
                                {/* BOTTOM */}
                                <Box className={classes.containerRow}>
                                    <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                        <ContainerDiferenceBar
                                            config_key={'DASHBOARDS_COMPARATOR-ENERGIA_ACTIVA_EXPORTADA_TOTAL-ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL-MONTHLY'}
                                            title={"Generación"}
                                            id={location?.id}
                                            color={'#b4ffbb-#b4daff'}
                                        />
                                    </Box>
                                    <Box className={clsx(classes.boxes, classes.smallBoxes)}>
                                        <ContainerDiferenceBar
                                            config_key={'DASHBOARDS_COMPARATOR-ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL-ENERGIA_ACTIVA_IMPORTADA_TOTAL-MONTHLY'}
                                            title={"Consumo"}
                                            id={location?.id}
                                            color={'#b4daff-#ffaeae'}
                                        />
                                    </Box>
                                    <Box className={clsx(classes.boxes, classes.longBoxes)}>
                                        <Box m={2}>
                                            <Box style={{ fontWeight: 'bold' }}>Consumo acumulado mensual</Box>
                                            <Box>del {firstDayOfPreviousMonth} al {lastDayOfPreviousMonth}</Box>
                                            {consumedEnergyLastMonth && consumedEnergy ? <Box display={'flex'} alignItems={'center'} marginTop={6}>
                                                <Box className={classes.consumedEnergyLabel}>
                                                    <Box display={'flex'} alignItems={'center'} fontSize={40} marginRight={1}>
                                                        <BoltIcon style={{ fontSize: 30 }} />
                                                        <Box>{getFormattedNumber(consumedEnergy)} {getUnit(consumedEnergy)}Wh</Box>
                                                    </Box >
                                                </Box >
                                                <Box style={{ color: calculatePercentageChange() > 0 ? '#ef4b6b' : '#008000' }}>
                                                    <Box fontSize={20}>{calculatePercentageChange().toFixed(0)} %</Box>
                                                    {calculatePercentageChange() <= 0 && <Box>Estas consumiendo menos comparado con el período anterior</Box>}
                                                    {calculatePercentageChange() > 0 && <Box>Estas consumiendo más comparado con el período anterior</Box>}
                                                </Box>
                                            </Box > :
                                                <Box marginTop={3} marginBottom={3}>No hay registros de consumo</Box>
                                            }
                                        </Box >
                                        <Box>
                                        </Box>
                                    </Box >

                                </Box >
                            </>
                            :
                            <Box display="flex" justifyContent="center" mt={2}>
                                <CircularProgress />
                            </Box>
                        }
                    </Box >}

            </Container >
        </>
    );
}

