import { useEffect, useState } from 'react';
//Material ui
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@mui/material';
//Componentes
import IndictorCard from './IndicatorCard';
//Helpers
import { getFormattedNumber, getUnit, toFormattedNumber } from '../../../helpers/common';
import { helperComponent } from '../../DynamicDashboard/helpers';
import { chekUrlPublic } from '../../DynamicDashboard/helpers';
//Servicios
import { getHistoryV2, getIndicatorforecast, getIndicatorComparison } from '../../../services/meters';

const useStyles = makeStyles(() => ({
    containerDisabled: {
        pointerEvents: 'none',
        opacity: 0.5,
        filter: 'grayscale(100%) blur(5px)',
    },
}));

export default function ContainerCards({ id, config_key, title, variable, content, size, refresh, marginTitle, subtitle }) {
    const classes = useStyles();
    //estado q muestra page principal o alert de bienvenida.
    const [showMain, setShowMain] = useState(false);
    //Loading principal.
    const [loading, setLoading] = useState(false);
    //estado general de data.
    const [stateData, setStateData] = useState({});
    const [subTitle, setSubtitle] = useState('')
    //Estados alert.
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: '',
        type: 'error'
    })

    //Key de la variable seleccionada
    let KEY_VAR_SELECTED = config_key?.split('-')[1]
    //variables
    let ENERGIA_PROYECTADA = 'ENERGIA_ACTIVA_PROYECTADA_TOTAL'
    let COS_FI = 'COS_(FI)_TOTAL'
    let ENERGIA_CONSUMIDA = 'ENERGIA_ACTIVA_TOTAL'
    let POTENCIA_ADQUIRIDA_MAX = 'POTENCIA_ADQUIRIDA_MAXIMA'
    let EMISION_CO2 = 'EMISION_DE_CO2_SUB'
    let TONELADA_PE = 'TONELADA_EQUIVALENTE_DE_PETROLEO_SUB'
    let CONSUMO_M2 = 'CONSUMO_POR_METRO_CUADRADO_SUB'
    let CONSUMO_CANT_EMPLEADOS = 'CONSUMO_POR_CANTIDAD_DE_EMPLEADOS_SUB'
    //solar
    let ENERGIA_ACTIVA_GENERADA_TOTAL = 'ENERGIA_ACTIVA_GENERADA_TOTAL'
    let ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL = "ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL"
    let ENERGIA_ACTIVA_CONSUMIDA_TOTAL = "ENERGIA_ACTIVA_CONSUMIDA_TOTAL"


    useEffect(() => {
        let optionSelectCatalog = helperComponent('DASHBOARDS_INDICATOR')?.find(e => e.key === KEY_VAR_SELECTED)
        let range = config_key?.split('-')[3]
        let period = config_key?.split('-')[4]
        let fields = optionSelectCatalog?.fields
        Object.entries(helperComponent('DASHBOARDS_INDICATOR')).forEach(([clave, valor]) => {
            let titleKeyGranularity = config_key.split('-')[4]
            let titleKeyPeriod = config_key.split('-')[3]
            let values = valor.periods.find(e => e.key === titleKeyGranularity)?.values
            let name = values.find(e => e.key === titleKeyPeriod)?.name
            if (name) {
                setSubtitle(name)
                return
            }
        })

        const getData = async () => {
            try {
                setClose(false)
                setShowMain(false)
                setLoading(true)

                //Energia Consumida.
                if (KEY_VAR_SELECTED === ENERGIA_CONSUMIDA) {
                    let url = createUrl(range, fields, period, optionSelectCatalog?.phase)
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { valor } = content?.data?.kwh[0]
                            valueAux = valor
                        }
                    }
                    else {
                        let res = await getHistoryV2(url)
                        let content = res.data[0]
                        const { valor } = content.data?.kwh[0]
                        valueAux = valor
                    }
                    setStateData({
                        energy_month: valueAux
                    })
                }
                //Energia Proyectada.
                else if (KEY_VAR_SELECTED === ENERGIA_PROYECTADA) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { kwh_forecast, kwh_previous } = content.data
                            valueAux = { kwh_forecast, kwh_previous }
                        }
                    }
                    else {
                        let res = await getIndicatorforecast(id, fields, period)
                        let content = res.data[0]
                        const { kwh_forecast, kwh_previous } = content.data
                        valueAux = { kwh_forecast, kwh_previous }
                    }
                    setStateData({
                        energy_proyection: valueAux.kwh_forecast,
                        energy_previous_month: valueAux.kwh_previous
                    })
                }
                //Energia Generada.
                else if (KEY_VAR_SELECTED === ENERGIA_ACTIVA_GENERADA_TOTAL) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { KWH_G_A, KWH_G_B } = content.data
                            valueAux = { KWH_G_A, KWH_G_B }
                        }
                    }
                    else {
                        let rangeB = returnRangeDinamic(range)
                        let res = await getIndicatorComparison(id, id, fields, fields, period, range, rangeB)
                        let content = res.data
                        const { KWH_G_A, KWH_G_B } = content.data
                        valueAux = { KWH_G_A, KWH_G_B }
                    }
                    setStateData({
                        power_month: valueAux.KWH_G_A,
                        power_previous_month: valueAux.KWH_G_B
                    })
                }

                //Energia activa autoconsumida total.
                else if (KEY_VAR_SELECTED === ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { KWH_AC_A, KWH_AC_B } = content.data
                            valueAux = { KWH_AC_A, KWH_AC_B }
                        }
                    }
                    else {
                        let rangeB = returnRangeDinamic(range)
                        let res = await getIndicatorComparison(id, id, fields, fields, period, range, rangeB)
                        let content = res.data
                        const { KWH_AC_A, KWH_AC_B } = content.data
                        valueAux = { KWH_AC_A, KWH_AC_B }
                    }
                    setStateData({
                        power_month: valueAux.KWH_AC_A,
                        power_previous_month: valueAux.KWH_AC_B
                    })
                }

                //Energia activa consumida total.
                else if (KEY_VAR_SELECTED === ENERGIA_ACTIVA_CONSUMIDA_TOTAL) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { KWH_C_A, KWH_C_B } = content.data
                            valueAux = { KWH_C_A, KWH_C_B }
                        }
                    }
                    else {
                        let rangeB = returnRangeDinamic(range)
                        let res = await getIndicatorComparison(id, id, fields, fields, period, range, rangeB)
                        let content = res.data
                        const { KWH_C_A, KWH_C_B } = content.data
                        valueAux = { KWH_C_A, KWH_C_B }
                    }
                    setStateData({
                        power_month: valueAux.KWH_C_A,
                        power_previous_month: valueAux.KWH_C_B
                    })
                }
                //Factor de Potencia.
                else if (KEY_VAR_SELECTED === COS_FI) {
                    let url = createUrl(range, fields, period, optionSelectCatalog?.phase)
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { valor } = content?.data?.cos_fi[0]
                            valueAux = valor
                        }
                    }
                    else {
                        let res = await getHistoryV2(url)
                        let content = res.data[0]
                        const { valor } = content.data?.cos_fi[0]
                        valueAux = valor
                    }
                    setStateData({
                        cosfi: valueAux
                    })
                }
                //Potencia maxima
                else if (KEY_VAR_SELECTED === POTENCIA_ADQUIRIDA_MAX) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let range = config_key.split('-')[3]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { KW_MAX_A, KW_MAX_B } = content.data
                            valueAux = { KW_MAX_A, KW_MAX_B }

                        }
                    }
                    else {
                        let rangeB = returnRangeDinamic(range)
                        let res = await getIndicatorComparison(id, id, fields, fields, period, range, rangeB)
                        let content = res.data
                        const { KW_MAX_A, KW_MAX_B } = content.data
                        valueAux = { KW_MAX_A, KW_MAX_B }

                    }
                    setStateData({
                        power_month: valueAux.KW_MAX_A,
                        power_previous_month: valueAux.KW_MAX_B
                    })
                }
                //Emisión de CO²
                else if (KEY_VAR_SELECTED === EMISION_CO2) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let range = config_key.split('-')[3]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { EMI_CO2_A, EMI_CO2_B } = content.data
                            valueAux = { EMI_CO2_A, EMI_CO2_B }
                        }
                    }
                    else {
                        let rangeB = returnRangeDinamic(range)
                        let res = await getIndicatorComparison(id, id, fields, fields, period, range, rangeB)
                        let content = res.data
                        const { EMI_CO2_A, EMI_CO2_B } = content.data
                        valueAux = { EMI_CO2_A, EMI_CO2_B }
                    }
                    setStateData({
                        power_month: valueAux.EMI_CO2_A,
                        power_previous_month: valueAux.EMI_CO2_B
                    })
                }
                //Tonelada petróleo
                else if (KEY_VAR_SELECTED === TONELADA_PE) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let range = config_key.split('-')[3]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { TON_PET_A, TON_PET_B } = content.data
                            valueAux = { TON_PET_A, TON_PET_B }
                        }
                    }
                    else {
                        let rangeB = returnRangeDinamic(range)
                        let res = await getIndicatorComparison(id, id, fields, fields, period, range, rangeB)
                        let content = res.data
                        const { TON_PET_A, TON_PET_B } = content.data
                        valueAux = { TON_PET_A, TON_PET_B }
                    }
                    setStateData({
                        power_month: valueAux.TON_PET_A,
                        power_previous_month: valueAux.TON_PET_B
                    })
                }
                // Consumo x m²
                else if (KEY_VAR_SELECTED === CONSUMO_M2) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let range = config_key.split('-')[3]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { CON_M2_A, CON_M2_B } = content.data
                            valueAux = { CON_M2_A, CON_M2_B }
                        }
                    }
                    else {
                        let rangeB = returnRangeDinamic(range)
                        let res = await getIndicatorComparison(id, id, fields, fields, period, range, rangeB)
                        let content = res.data
                        const { CON_M2_A, CON_M2_B } = content.data
                        valueAux = { CON_M2_A, CON_M2_B }
                    }
                    setStateData({
                        power_month: valueAux.CON_M2_A,
                        power_previous_month: valueAux.CON_M2_B
                    })
                }
                // Consumo x cantidad de empleados
                else if (KEY_VAR_SELECTED === CONSUMO_CANT_EMPLEADOS) {
                    let fields = optionSelectCatalog.fields
                    let period = config_key.split('-')[4]
                    let range = config_key.split('-')[3]
                    let valueAux = {}
                    if (content && chekUrlPublic()) {
                        const propiedades = Object.keys(content);
                        if (propiedades.length > 0) {
                            const { CON_CE_A, CON_CE_B } = content.data
                            valueAux = { CON_CE_A, CON_CE_B }
                        }
                    }
                    else {
                        let rangeB = returnRangeDinamic(range)
                        let res = await getIndicatorComparison(id, id, fields, fields, period, range, rangeB)
                        let content = res.data
                        const { CON_CE_A, CON_CE_B } = content.data
                        valueAux = { CON_CE_A, CON_CE_B }
                    }
                    setStateData({
                        power_month: valueAux.CON_CE_A,
                        power_previous_month: valueAux.CON_CE_B
                    })
                }

                else {
                }
                setShowMain(true)
            }
            catch (error) {
                setClose(true)
                let msg = 'Ocurrio un error inesperado.'
                if (error?.response?.data?.msg) {
                    msg = error?.response?.data?.msg
                }
                setMsg({
                    msg: msg,
                    type: 'error'
                })
                return
            }
            finally {
                if (content && chekUrlPublic()) {
                    const numeroRandom = Math.floor(Math.random() * 4) + 1;
                    setTimeout(() => {
                        setLoading(false)
                    }, numeroRandom * 1000)
                }
                else {
                    setLoading(false)
                }
            }
        }
        getData()
        // eslint-disable-next-line
    }, [id, config_key, variable, refresh])


    //Funcion q manda la key de el valor a comparar range.
    const returnRangeDinamic = (range) => {
        let res = ''
        if (range === 'ESTE_MES') {
            res = 'MES_PASADO'
        }
        if (range === 'HOY') {
            res = 'ayer'
        }
        if (range === 'ESTE_ANO') {
            res = 'ano_pasado'
        }

        return res
    }

    //Funcion q crea la url.
    const createUrl = (range, fields, period, phase) => {
        let url = `filter=${id}&response_format=v2&range=${range}&fields=${fields}&period=${period}`
        if (phase) {
            url += `&showPhase=true`;
        }
        return url
    }

    return (
        <Box style={{ height: '100%' }}>
            {/* Alert error */}
            {close &&
                <>
                    <Alert severity={msg.type}>{msg.msg}</Alert>
                    <Box className={classes.containerDisabled}>
                        <IndictorCard
                            title={'Energía'}
                            value={462}
                            unity={`KWh`}
                            titleFooter={'Energía'}
                            size={size}
                            marginTitle={marginTitle}
                        />
                    </Box>
                </>
            }
            {/* Loading */}
            {loading ?
                <Box display='flex' justifyContent='center' mt={2}>
                    <CircularProgress />
                </Box>
                :
                <>
                    {showMain &&
                        <>
                            {KEY_VAR_SELECTED === ENERGIA_CONSUMIDA &&
                                < IndictorCard
                                    title={title}
                                    descriptcion={true}
                                    subTitle={subtitle ? subtitle : `| ${variable} ${subTitle}`}
                                    value={getFormattedNumber(stateData?.energy_month)}
                                    unity={`${getUnit(stateData?.energy_month)}Wh`}
                                    titleFooter={'Energía'}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === ENERGIA_ACTIVA_GENERADA_TOTAL &&
                                <IndictorCard
                                    title={title}
                                    subTitle={subtitle ? subtitle : `| ${variable} ${subTitle}`}
                                    value={getFormattedNumber(stateData?.power_month)}
                                    unity={`${getUnit(stateData?.power_month)}Wh`}
                                    arrow={true}
                                    compareValue={toFormattedNumber(stateData?.power_previous_month)}
                                    nameCompareValue={subTitle}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL &&
                                <IndictorCard
                                    title={title}
                                    subTitle={subtitle ? subtitle : `| ${variable} ${subTitle}`}
                                    value={getFormattedNumber(stateData?.power_month)}
                                    unity={`${getUnit(stateData?.power_month)}Wh`}
                                    arrow={true}
                                    compareValue={toFormattedNumber(stateData?.power_previous_month)}
                                    nameCompareValue={subTitle}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === ENERGIA_ACTIVA_CONSUMIDA_TOTAL &&
                                <IndictorCard
                                    title={title}
                                    subTitle={subtitle ? subtitle : `| ${variable} ${subTitle}`}
                                    value={getFormattedNumber(stateData?.power_month)}
                                    unity={`${getUnit(stateData?.power_month)}Wh`}
                                    arrow={true}
                                    compareValue={toFormattedNumber(stateData?.power_previous_month)}
                                    nameCompareValue={subTitle}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }

                            {KEY_VAR_SELECTED === COS_FI &&
                                < IndictorCard
                                    title={title}
                                    subTitle={subtitle ? subtitle : `| ${variable} ${subTitle}`}
                                    value={toFormattedNumber(stateData?.cosfi)}
                                    unity={''}
                                    titleFooter={'Factor de potencia'}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === ENERGIA_PROYECTADA &&
                                < IndictorCard
                                    title={title}
                                    subTitle={subtitle ? subtitle : `| ${variable} ${subTitle}`}
                                    value={getFormattedNumber(stateData?.energy_proyection)}
                                    unity={`${getUnit(stateData?.energy_proyection)}Wh`}
                                    arrow={true}
                                    compareValue={getFormattedNumber(stateData?.energy_previous_month)}
                                    nameCompareValue={subTitle}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === POTENCIA_ADQUIRIDA_MAX &&
                                <IndictorCard
                                    title={title}
                                    subTitle={subtitle ? subtitle : `| ${variable} ${subTitle}`}
                                    value={getFormattedNumber(stateData?.power_month)}
                                    unity={`${getUnit(stateData?.power_month)}W`}
                                    arrow={true}
                                    compareValue={toFormattedNumber(stateData?.power_previous_month)}
                                    nameCompareValue={subTitle}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === EMISION_CO2 &&
                                <IndictorCard
                                    title={title}
                                    subTitle={`| ${variable || ''} ${subTitle}`}
                                    value={getFormattedNumber(stateData?.power_month)}
                                    unity={`${getUnit(stateData?.power_month)}g/Co2eq`}
                                    arrow={true}
                                    compareValue={toFormattedNumber(stateData?.power_previous_month)}
                                    nameCompareValue={subTitle}
                                    img={'emisionesCo2'}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === TONELADA_PE &&
                                <IndictorCard
                                    title={title}
                                    subTitle={`| ${variable || ''} ${subTitle}`}
                                    value={toFormattedNumber(stateData?.power_month)}
                                    unity={'Tep'}
                                    arrow={true}
                                    compareValue={toFormattedNumber(stateData?.power_previous_month)}
                                    nameCompareValue={subTitle}
                                    img={'tonelada-petroleo'}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === CONSUMO_M2 &&
                                <IndictorCard
                                    title={title}
                                    subTitle={`| ${variable || ''} ${subTitle}`}
                                    value={toFormattedNumber(stateData?.power_month)}
                                    unity={'Kwh/m2'}
                                    arrow={true}
                                    compareValue={toFormattedNumber(stateData?.power_previous_month)}
                                    nameCompareValue={subTitle}
                                    img={"consumo-ecologic"}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                            {KEY_VAR_SELECTED === CONSUMO_CANT_EMPLEADOS &&
                                <IndictorCard
                                    title={title}
                                    subTitle={`| ${variable || ''} ${subTitle}`}
                                    value={toFormattedNumber(stateData?.power_month)}
                                    unity={'Kwh/empleado'}
                                    arrow={true}
                                    compareValue={toFormattedNumber(stateData?.power_previous_month)}
                                    nameCompareValue={subTitle}
                                    img={"consumo-cantidad-empleados"}
                                    size={size}
                                    marginTitle={marginTitle}
                                />
                            }
                        </>
                    }
                </>
            }
        </Box>
    )
}